import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Drawer,
  Divider,
  styled,
  Popover,
  Modal,
  Fade
} from "@material-ui/core";
import {
  logoImg,
  notificationImg,
  downArrowImg,
  profileImg,
  logoutImg,
  meetingsImg,
  logoutLargeImg,
  backArrowImg,
  drawerImg,
  closeImg,
  healthImg,
  logoutMobileImg,
  profileMobileImg,
  contactUsImg,
  invoicesImg,
  aboutUsImg,
  termsConditionsImg,
  logoMobileImg,
  whiteDrawerImg,
  whiteNotificationImg
} from "./assets";
import MessageEnum from "../../../framework/src/Messages/MessageEnum";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getTab = (name: string, path: string, messageEnum: MessageEnum) => (
    <Box
      data-test-id={name}
      className={
        this.props.screenName?.toLowerCase() === path.substring(1).toLowerCase() ? "active-tab" : "tab"
      }
      onClick={() => this.handleNavigation(messageEnum)}
    >
      {name}
    </Box>
  )

  getTabWithImage = (img: string, name: string, messageEnum: MessageEnum) => (
    <React.Fragment>
      <Divider className="divider" />
      <Box data-test-id={`mobile-${name}`} onClick={() => this.handleNavigation(messageEnum)} className="drawer-menu-container">
        <img src={img} alt={name} />
        <Box className="drawer-menu-text">{name}</Box>
      </Box>
    </React.Fragment>
  )

  renderModal() {
    return (
      <ModalWrapper open={this.state.isModalOpen} closeAfterTransition>
        <Fade in={this.state.isModalOpen}>
          <Box className="modal-section-wrapper">
            <img src={logoutLargeImg} />
            <Box className="modal-title">{configJSON.logoutTitleText}</Box>
            <Box className="modal-description-text">{configJSON.logoutConfirmationText}</Box>
            <Box className="button-container">
              <Button className="cancel-button" data-test-id="cancel-button" onClick={this.modalClose}>{configJSON.cancelText}</Button>
              <Button className="confirm-button" data-test-id="confirm-button" onClick={this.goToLogin}>{configJSON.confirmText}</Button>
            </Box>
          </Box>
        </Fade>
      </ModalWrapper>
    );
  }
  
  renderProfile() {
    return (
      this.state.fullWidthNavigationMenu ? (
        <Box className="profile-wrapper" aria-describedby={Boolean(this.state.anchorEl) ? "popover" : undefined}>
          {this.state.profileUrl ? (
            <img className="user-profile" src={this.state.profileUrl} alt={`${this.state.userName}-profile-picturee`} />
          ) : (
            <AccountCircleIcon style={{ fill: "#4474e0" }} className="user-profile" />
          )}
          <Box className="profile-name-wrapper">
            <Box>{configJSON.helloText}</Box>
            <Box className="bold-text">{this.state.userName}</Box>
          </Box>
          <img
            className="down-arrow"
            data-test-id="profile-seciton-dropdown"
            height={20}
            width={20}
            src={downArrowImg}
            alt="down-arrow"
            onClick={this.handlePopoverClick}
          />
        </Box>
      ) : (
        <Box className="button-wrapper">
          <Button data-test-id="signup-button" onClick={this.handleSignUp}>{configJSON.signupText}</Button>
        </Box>
      )
    );
  }
  
  renderDesktopNavigation() {
    return (
      <Box className={`${this.state.fullWidthNavigationMenu ? 'full-width-navigation' : 'navigation'} desktop`}>
        {this.renderModal()}
        <img src={logoImg} className="header-logo" alt="logo-image" />
        <Box style={{ gap: this.state.fullWidthNavigationMenu ? "20px" : "50px" }} className="navigationRightSection">
          <Box data-test-id="Tasks" className="navigationRightMenuSection">
            {this.state.role_type === "User" && (this.state.fullWidthNavigationMenu && this.getTab(configJSON.tasksText, "/Tasks", MessageEnum.NavigationTaskDetailMessage))}
            {this.getTab(configJSON.aboutUsText, "/Aboutus", MessageEnum.NavigationAboutUsMessage)}
            {this.state.role_type === "User" && (this.state.fullWidthNavigationMenu && this.getTab(configJSON.invoicesText, "/InvoiceBilling", MessageEnum.NavigationInvoiceMessage))}
            {this.getTab(configJSON.contactUsText, "/Contactus", MessageEnum.NavigationContactUsMessage)}
            {!this.state.fullWidthNavigationMenu ? (
              this.getTab(configJSON.loginText, "/EmailAccountLoginBlock", MessageEnum.NavigationEmailLogInMessage)
            ) : (
              <Box className="notificationWrapperSection" data-test-id="notification-tab" onClick={() => this.handleNavigation(MessageEnum.NavigationNotificationsWebMessage)}>
                <img src={notificationImg} alt="notification-image" />
                {this.state.notificationCount !== 0 && (
                  <Box className={`notificationText ${this.getNotifyClass()}`}>
                    {this.state.notificationCount > 99 ? '99+' : this.state.notificationCount}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {this.renderProfile()}
          <MenuSection
            open={Boolean(this.state.anchorEl)}
            id={Boolean(this.state.anchorEl) ? "popover" : undefined}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <Box className="pointer" onClick={() => this.handleNavigation(MessageEnum.NavigationProfileWebMessage)} data-test-id="profile-tab">
              <img src={profileImg} />
              {configJSON.myProfileText}
            </Box>
           {this.state.role_type==='User' && <Box className="pointer" onClick={() => this.handleNavigation(MessageEnum.NavigationMeetingWebMessage)} data-test-id="meeting-tab">
              <img src={meetingsImg} />
              {configJSON.myMeetingsText}
            </Box>}
            <Box data-test-id="logout-button" className="logout-color" onClick={this.handleLogOut}>
              <img src={logoutImg} />
              {configJSON.logOutText}
            </Box>
          </MenuSection>
        </Box>
      </Box>
    );
  }
  
  renderMobileHeader() {
    return (
      <Box className="mobile-header" style={{ background: this.getMobileHeaderBackgroundColor() }}>
        <DrawerSection anchor="left" open={this.state.mobileDrawer} onClose={this.handleDrawerClose}>
          <Box className="mobile-drawer">
            <Box className="mobile-profile-wrapper">
              <Box>
                <img className="user-profile" src={this.state.profileUrl} alt={`${this.state.userName}-profile-picturee`} />
                <Box className="mobile-profile-name-wrapper">
                  <Box>{configJSON.helloTxt}</Box>
                  <Box className="name-text">{this.state.userName}</Box>
                </Box>
              </Box>
              <img className="close-button" data-test-id="mobile-drawer-close-button" src={closeImg} alt="close-icon" onClick={this.handleDrawerClose} />
            </Box>
            {this.getTabWithImage(healthImg, configJSON.dashboardText, MessageEnum.NavigationDashboardMessage)}
            {this.getTabWithImage(profileMobileImg, configJSON.myProfileText, MessageEnum.NavigationProfileWebMessage)}
            {this.getTabWithImage(invoicesImg, configJSON.invoicesText, MessageEnum.NavigationInvoiceMessage)}
            {this.getTabWithImage(contactUsImg, configJSON.contactUsText, MessageEnum.NavigationContactUsMessage)}
            {this.getTabWithImage(aboutUsImg, configJSON.aboutUsText, MessageEnum.NavigationAboutUsMessage)}
            {this.getTabWithImage(termsConditionsImg, configJSON.termsConditionsText, MessageEnum.NavigationTermAndConditionWeb)}
            <Divider className="divider" />
            <Box onClick={this.handleLogOut} className="drawer-menu-container">
              <img src={logoutMobileImg} alt={configJSON.logoutTitleText} />
              <Box className="drawer-menu-text">{configJSON.logoutTitleText}</Box>
            </Box>
          </Box>
        </DrawerSection>
        <Box className="mobile-header-navigatioions">
          <Box className="mobile-navigations">
            {this.props.isBackButton && <img className="pointer visible-first" data-test-id="mobile-back-button" onClick={this.handleBack} src={backArrowImg} alt="back" />}
            {this.props.isDrawer && <img className="pointer visible-first" data-test-id="mobile-drawer" onClick={this.handleDrawerClick} src={this.props.isDashboard ? whiteDrawerImg : drawerImg} alt="drawer" />}
            {this.props.isLogo && <img className="pointer visible-first" src={logoMobileImg} alt="credit-lift-logo" />}
            {this.props.screenTitle && <Box className={`visible-second ${this.props.isScreenTitleCenter ? "screen-title-center" : "screen-title"}`}>{this.props.screenTitle}</Box>}
          </Box>
          {this.getMobileNotification()}
        </Box>
      </Box>
    );
  }

  getMobileNotification = () => this.props.isNotification && <img className="pointer" src={this.props.isDashboard ? whiteNotificationImg : notificationImg} alt="credit-lift-logo"  />;
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <NavigationSection>
        {this.renderDesktopNavigation()}
        {this.renderMobileHeader()}
      </NavigationSection>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DrawerSection = styled(Drawer)({
  "& .mobile-drawer": {
    padding: "20px",
    minWidth: "265px",
    boxSizing: "border-box"
  },
  "& .mobile-profile-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "flex-start",
    "&>div": {
      display: "flex",
      gap: "8px",
      "&>img": {
        height: "42px",
        width: "42px",
        borderRadius: "42px",
        background: "lightgray"
      }
    },
  },
  "& .mobile-profile-name-wrapper": {
    color: "#363A3F",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 700,
    "& .name-text": {
      fontWeight: 500,
      opacity: 0.5,
      marginTop: "4px"
    }
  },
  "& .close-button": {
    marginTop: "4px",
    cursor: "pointer"
  },
  "& .divider": {
    background: "rgba(1, 159, 72, 0.20)",
    height: "1px",
    margin: "15.5px 0"
  },
  "& .drawer-menu-text": {
    color: "#363A3F",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 500
  },
  "& .drawer-menu-container": {
    display: "flex",
    gap: "12px",
    margin: "0 5px",
    alignItems: "center",
    boxSizing: "border-box",
    cursor: "pointer"
  }
})
const NavigationSection = styled(Box)({
  position: "relative",
  zIndex: 111111,
  background: "transparent",
  '& .visible-first': {
    zIndex: 11111
  },
  '& .visible-second': {
    zIndex: 1111
  },
  "& .desktop": {
    height: "100px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    color: "#333",
    fontFamily: "Urbanist",
    fontStyle: "normal",
  },
  "& .header-logo": {
    width: "auto",
    "@media only screen and (max-width: 1000px)" : {
      width: "100px"
    },
    "@media only screen and (max-width: 900px)" : {
      width: "80px"
    }
  },
  "& .navigation": {
    margin: "15px 50px",
    padding: "25px",
    borderRadius: "24px",
    boxShadow: "0px 4px 24px 0px rgba(10, 66, 35, 0.10)",
    "@media only screen and (max-width: 767px)" : {
      display: "none"
    }
  },
  "& .full-width-navigation": {
    padding: "0 50px",
    boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.06)",
    "@media only screen and (max-width: 767px)" : {
      display: "none"
    }
  },
  "& .navigationRightSection": {
    display: "flex",
    height: "100%",
  },
  "& .navigationRightMenuSection": {
    display: "flex",
    gap: "50px",
    alignItems: "center",
    "@media only screen and (max-width: 800px)" : {
      gap: "20px"
    },
    "&>div": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      fontSize: "18px",
      cursor: "pointer",
      "@media only screen and (max-width: 1000px)" : {
        fontSize: "14px",
      },
      "@media only screen and (max-width: 900px)" : {
        fontSize: "12px",
      }
    },
    "& .tab": {
      fontWeight: 400,
      color: "#333"
    },
    "& .active-tab": {
      borderBottom: "3px solid #019F48",
      fontWeight: 600,
      color: "#019F48",
      boxSizing: "border-box",
      paddingTop: "3px"
    }
  },
  "& .notificationWrapperSection": {
    position: "relative"
  },
  "& .notificationText": {
    position: "absolute",
    top: "27px",
    left: "12px",
    width: "15px",
    height: "15px",
    background: "green",
    borderRadius: "60px",
    border: "2px solid white",
    fontSize: "12px",
    color: "white",
    textAlign: "center",
    fontWeight: 700,
  },
  '& .notify-2': {
    width: '18px',
    height: '18px',
    lineHeight: '18px',
    top: '25px'
  },
  '& .notify-3': {
    width: '23px',
    height: '23px',
    lineHeight: '23px',
    fontSize: '11px',
    top: '21px'
  },
  "& .profile-wrapper": {
    display: "flex",
    alignItems: "center",
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    "@media only screen and (max-width: 1000px)" : {
      fontSize: "12px",
    },
    "@media only screen and (max-width: 900px)" : {
      fontSize: "10px",
    }
  },
  "& .profile-name-wrapper": {
    padding: "0 8px 0 10px",
    display: "flex",
    flexDirection: "column",
    gap: "4px"
  },
  "& .user-profile": {
    height: "50px",
    width: "50px",
    borderRadius: "50px",
    "@media only screen and (max-width: 900px)" : {
      width: "35px",
      height: "35px"
    }
  },
  "& .bold-text": {
    fontWeight: "700"
  },
  "& .down-arrow": {
    cursor: "pointer"
  },
  "& .button-wrapper": {
    display: "flex",
    alignItems: "center",
    "&>button": {
      width: "164px",
      height: "48px",
      borderRadius: "12px",
      background: "#019F48",
      textTransform: "capitalize",
      "@media screen and (max-width: 1000px)" : {
        height: "46px"
      },
      "&>span": {
        color: "#FFF",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "30px",
        "@media screen and (max-width: 1000px)" : {
          fontSize: "14px"
        },
      }
    }
  },
  "& .mobile-header": {
    display: "none",
    "@media screen and (max-width: 767px)" : {
      padding: "24px",
      display: "block"
    }
  },
  "& .pointer": {
    cursor: "pointer"
  },
  "& .mobile-navigations": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "&>div": {
      color: "#292D32",
      textAlign: "center",
      fontFamily: "Urbanist",
      fontSize: "20px",
      fontWeight: 500
    }
  },
  "& .mobile-header-navigatioions": {
    display: "flex",
    justifyContent: "space-between",
    "&>img": {
      width: "22px"
    }
  },
  "& .screen-title-center": {
    width: "calc(100% - 48px)",
    position: "absolute",
    boxSizing: "border-box"
  }
});

const MenuSection = styled(Popover)({
  "&>.MuiPopover-paper": {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "20px",
    border: "1px solid rgba(1, 159, 72, 0.18)",
    background: "#FFF",
    boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
    gap: "20px",
    width: "200px",
    boxSizing: "border-box",
    marginTop: "25px",
    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      fontFamily: "Urbanist",
      fontWeight: 500,
      fontSize: "16px",
      color: "#333",
      cursor: "pointer",
      "&>img": {
        height: "24px",
        width: "24px"
      }
    },
    "& .logout-color": {
      color: "#F00"
    }
  }
});

const ModalWrapper = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .modal-section-wrapper": {
    maxWidth: "620px",
    width: "calc(100% - 50px)",
    boxSizing: "border-box",
    borderRadius: "20px",
    background: "#FFF",
    padding: "50px",
    fontFamily: "Urbanist",
    fontStyle: "normal",
    color: "#333",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "68px",
      width: "68px",
      "@media screen and (max-width: 600px)" : {
        height: "40px",
        width: "40px",
      }
    },
    "@media screen and (max-width: 600px)" : {
      gap: "15px",
      padding: "25px",
    }
  },
  "& .modal-title": {
    fontWeight: 700,
    lineHeight: "35px",
    fontSize: "38px",
    "@media screen and (max-width: 600px)" : {
      fontWeight: 600,
      lineHeight: "18px",
      fontSize: "16px",
    }
  },
  "& .modal-description-text": {
    fontWeight: 400,
    lineHeight: "33px",
    fontSize: "24px",
    marginTop: "10px",
    "@media screen and (max-width: 600px)" : {
      fontWeight: 600,
      lineHeight: "19px",
      fontSize: "12px",
      opacity: "0.5"
    }
  },
  "& .button-container": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    "@media screen and (max-width: 600px)" : {
      gap: "14px",
      width: "100%"
    },
    "&>button": {
      borderRadius: "12px",
      width: "200px",
      height: "56px",
      textTransform: "none",
      fontFamily: "Urbanist",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "30px",
      "@media screen and (max-width: 600px)": {
        fontSize: "16px",
        lineHeight: "18px",
        width: "100%",
        height: "52px"
      }
    },
  },
  "& .cancel-button": {
    background: "#FFF",
    color: "#333",
    border: "1px solid #9E9E9E"
  },
  "& .confirm-button": {
    background: "#019F48",
    color: "#FFF"
  }
})
// Customizable Area End
