import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputLabel, TextField, Stepper, Step, StepLabel, StepContent, StylesProvider
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import "../../info-page/src/styles.css"

// Customizable Area End

import QuestionBankController, {
  Props,
  configJSON
} from "./QuestionBankController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#019F48",
      contrastText: "#fff",
    },
  },
});

export default class QuestionBankBlock extends QuestionBankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderQuestion = (question: {
    id: string,
    answer: string,
    question: string
    }) => (
    <Box id={question.id} sx={{ padding: "10px 20px" }}>
    <InputLabelWrapper>
      <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle">{question.question}</InputLabel>
    </InputLabelWrapper>
      <TextField
        fullWidth
        className={question.id === this.state.errorId ? "input-error" : ""}
        error={question.id === this.state.errorId}
        variant="outlined"
        data-test-id="txtInputDiffCreditScore"
        onChange={(event) => this.handleQuestionAnswer(event, question.id)}
        placeholder={configJSON.enterYourAnswerText}
        InputProps={{
          style: {
            marginTop: 8,
            backgroundColor: '#F4FAF7',
            borderRadius :"12px",
            border : "1px solid #CEE1D6"
          }
        }}
      />
      {
        question.id === this.state.errorId && <TypographyWrapper ><Typography data-test-id="error-text" className="errorMessage">{configJSON.answerErrorText}</Typography></TypographyWrapper>
      }
    </Box>
  )
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container style={{padding:'0px',maxWidth:'100%'}} className="topScroll">
        <QuestionBankWrapper>
            <Box
               className="mainView" width={'100%'} height={'100%'}
            >
              <Box 
                key="Page Layout"
                className="leftView"
                bgcolor={'#EEFFF8'}
              > <img  {...this.imgCreditLiftProps} alt="CreditLift" style={{ width: 185, height: 53,marginBottom:20, }} />
                 <img  {...this.txtLableStartedProps} alt="Lets get started" style={{ width: 400, height: 45,marginBottom:20, }}className="txtLableStarted"/>
                <TypographyWrapper>
                  <Typography className="letsGet">Let’s get you started</Typography>
                  <Typography className="keepInfo">to keep your information</Typography>
                  <Typography  className="txtInfo">To keep connected with us please<br/>enter your information.</Typography>
                  </TypographyWrapper>
                <Box className="start"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "30px 0px 0px 0px",
                    top: "100px",
                    marginTop:'20px'
                  }}
                >
                  <Stepper activeStep={1} orientation="vertical"  className="muiStepper" style={{ paddingBottom: 0,}}>
                    {this.state.stepperValue.map((label, index) => (
                      <Step key={label} color="success" style={{ height: '25px' }} >
                        <StepLabel color="#019F48">
                          <Typography>{label}</Typography>
                        </StepLabel>
                        <StepContent />
                      </Step>
                    ))}
                  </Stepper>

                </Box>
              </Box>
              <Box key="questions"
               className="rightView"
              >
                <TypographyWrapper>
                  <Typography className="question">Questions</Typography>
                  <Typography className="answer">Answer the following Questions given below</Typography>
                </TypographyWrapper>

                {this.state.allQuestionsList.length > 0 ? this.state.allQuestionsList.map(this.renderQuestion) : null
                }

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box sx={{ padding: "20px 20px" }}>
                    <Button variant="outlined" onClick={this.onBack} className="backbtnStyle">Back</Button>
                  </Box>
                  <Box sx={{ padding: "20px 20px" }}>
                    <Button variant="contained" color="secondary" style={{ background: '#019F48' }}
                      data-test-id="next-button"
                      className="btnStyle"
                      onClick={() => {
                        this.onNext()
                      }}>Next</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            </QuestionBankWrapper>
          </Container>
        </ThemeProvider>
      </StylesProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const QuestionBankWrapper = styled(Box)({
  '& .mainView': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "@media only screen and (max-width: 900px)" : {
      flexDirection: "column",
      display: "flex",
    }
  },
  '& .leftView': {
    paddingTop: '56px',
    paddingRight: '30px',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: '30px',  
    backgroundColor: '#EEFFF8',
    paddingBottom: '0px',
    width:'35%',
    height:'100%',
    top: '0px',
    left: '0px',
    zIndex: 1,
"@media only screen and (max-width: 900px)" : {
  paddingLeft: '30px',
  backgroundColor: '#FFFF !important',
  alignSelf:'center',
  paddingTop: '20px',
  paddingRight: '30px',
  paddingBottom: '0px',
}
  },
  '& .infoView':{
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop:'35px',
    position:'relative',
    display: "flex",
    "@media only screen and (max-width: 900px)" : {
      backgroundColor: '#FFFF !important',
    }
  },
  '& .rightView':{
      display: "flex",
      paddingBottom: '0px',
      paddingLeft: '40px',
      fontFamily:'Urbanist',
      flexDirection: "column",
      paddingTop: '56px',
      paddingRight: '112px',
      width:'70%',
      "@media only screen and (max-width: 900px)" : {
        paddingLeft: '30px',
        backgroundColor: '#FFFF',  
        width:'85%',
        paddingTop: '0px',
        paddingRight: '30px',
        paddingBottom: '0px',
      }
  },
  '& .loginBtnView':{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    alignSelf: 'center',
    padding: "10px 0px",
    fontFamily:'Urbanist',
    "@media only screen and (max-width: 900px)" : {
      fontSize: "12px",
    }
  },
  '& .reserved':{fontSize:'12px',
  textAlign:'center',
  fontFamily:'Urbanist'
},
"& .Mui-focused": {
  borderColor: "#019F48 !important"
},
'& .backbtnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  textTransform:'none',
  fontWeight:'bold',
  "@media only screen and (max-width: 900px)" : {
    fontSize:'12px',
    fontFamily:'Urbanist',
}
},
'& .btnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  textTransform:'none',
    color:'#FFFFF',
    fontWeight:'bold',
    "@media only screen and (max-width: 900px)" : {
      fontSize:'12px',
      fontFamily:'Urbanist',
  }
}
});
const TypographyWrapper = styled(Typography)({

  '& .txtInfo':{
    fontFamily:'Urbanist',
    fontSize:'24px',
    fontWeight:400,
    "@media only screen and (max-width: 900px)" : {
      display:'none !important'
    }
  },
  '& .letsGet':{
    display:'none !important',
    fontFamily:'Urbanist',fontSize:'38px',
    "@media only screen and (max-width: 900px)" : {
      
      color:'#292D32',
      display:'block !important',
      fontSize:'38px',
      fontFamily:'Urbanist',
      fontWeight:700,
      textAlign:'center'
    }
  },
  '& .keepInfo':{
    display:'none !important',
    fontFamily:'Urbanist',
    "@media only screen and (max-width: 900px)" : {
      fontSize:'12px',
      color:'#292D32',
      display:'block !important',
      fontFamily:'Urbanist',
      fontWeight:500,
      alignSelf:'center',
      textAlign:'center'
    }
  },
  '& .question':{ paddingTop: 0, fontSize: 38, color: '#333', 
  paddingLeft: 20, fontFamily:'Urbanist',fontWeight:700,
  "@media only screen and (max-width: 900px)" : {
    fontSize:'22px',
    color:'#292D32',
    display:'block !important',
    fontFamily:'Urbanist',
    fontWeight:500,
  }
 },
 '& .answer':{ 
  paddingTop: '5px', paddingBottom:'20px',fontSize: 26, color: '#333',
   paddingLeft: 20, fontFamily:'Urbanist',fontWeight:400,
 "@media only screen and (max-width: 900px)" : {
  fontSize:'12px',
  color:'#333333',
  display:'block !important',
  fontFamily:'Urbanist',
  fontWeight:500,
},
 },
 '& .errorMessage':{
  fontSize:'18px',
  color:'#FF0000',
  fontFamily:'Urbanist',
  paddingTop:'7px'
}
});
const InputLabelWrapper = styled(InputLabel)({
  '& .inputeLabelStyle':{
    fontSize:'18px',
    color:'#333',
    fontFamily:'Urbanist',
    "@media only screen and (max-width: 900px)" : {
      fontSize:'12px',
      fontFamily:'Urbanist',
  }
}
})


// Customizable Area End
