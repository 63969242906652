import React from "react";
// Customizable Area Start
import { Box, 
    Button, 
    styled, 
    TextField, 
    Modal,
    Typography, 
    Container, 
    InputLabel,
    IconButton
} from "@material-ui/core";
import { imgCreditLift, imgGraph, imgUploadDoc, imgWatch, mailImage, view_Vertical_line } from "./assets";
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON
} from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
    
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <Container style={{ padding:'0px',maxWidth:'100%'}}>
          <ForgotMainView>
        <Box
           className="mainView"
          >
             <Box
            className="leftView"
          >
            <TypograyView>
            <Box display="flex">
                <IconButton className="mobile-back-arrow" onClick={() => this.goToLogin()} style={{ paddingLeft: "0px"}}>
                    <KeyboardBackspaceRoundedIcon className="back-arrow" />
                </IconButton>
                <Typography variant="h4" component="h2" className="labelTextStyle">
                    {configJSON.resetYourPasswordText}
                </Typography>
            </Box>
                    
                  <Typography variant="subtitle1" component="div" className="pwdLabelStyle">
                    {'Reset Password'}
                  </Typography>
             <Typography className="forgotText">&#128273; {configJSON.forgotPasswordText}</Typography>
            <Typography  className="createAccount">{configJSON.createNewPasswordText} {configJSON.emailAddressText}</Typography>
           </TypograyView>
           <img src={view_Vertical_line} alt="verticalline" className="verticaliLine"
                   />
            <Box className="infoView" >
              <img src={imgGraph}  className="graphLoginImage" alt="graphimage" style={{ width: '309px', height: '309px', marginLeft: '-34px', }} />
              <img src={imgWatch} alt="graphimage" className="watchImage" />
              <img src={imgUploadDoc} alt="uploaddocimage" className="imageLoginUpload" style={{ width: '384px', height: '309px', marginLeft: '-34px', }}/>
           </Box>            
            </Box>
            <Box
           className="rightView"
          >
            <Box className="copyright-content">{configJSON.copyRightText}</Box>
              <img src={imgCreditLift} alt="creditliftlogo" className="creditLiftLogo"/> 
              <TypograyView>   
            <Box className="icon-button-section">
                <IconButton onClick={() => this.goToLogin()} style={{ paddingLeft: "0px"}}>
                    <KeyboardBackspaceRoundedIcon className="back-arrow" />
                </IconButton>
                <Typography variant="subtitle1" component="div"  className="labelBlock">
                    {configJSON.resetYourPasswordText}
                </Typography>
            </Box>
            
            <Typography  component="div"  className="labelEmailBlock">
             {configJSON.resetPasswordDescriptionText}
            </Typography>
            </TypograyView>   
            <Box sx={{ width: "100%", paddingTop: "20px" }}>
              <Box sx={{ padding: "20px 0px 35px" }}>
              <InputLabel htmlFor="outlined-adornment-amount" className="inputLabel">{configJSON.emailPlaceholder}</InputLabel>
                <TextField 
                    fullWidth
                    className={this.state.emailError || this.state.emailNotExistError ? "input-error" : ""}
                    error={this.state.emailError || this.state.emailNotExistError}
                    variant="outlined"
                    data-test-id="txtInputEmail"
                    value={this.state.email}
                    onChange={this.setEmail}
                    placeholder={configJSON.emailPlaceholder}
                    InputProps={{
                    style: {
                        marginTop: 8,
                        backgroundColor:'#F4FAF7'
                    }
                }}
              />
              {(this.state.emailError || this.state.emailNotExistError) && <Box>
                <Typography className="errorMsg">{this.getErrorText()}</Typography>
              </Box>}
              </Box>
              <Box className="mobile-submit">
                <ButtonWrapper>
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    className="submitButton"
                    fullWidth
                    onClick={this.btnSubmit}
                  >
                    {configJSON.submitButtonText}
                  </Button>
                </ButtonWrapper>
              </Box>
              </Box>
          </Box>
            </Box>
            <ModalWrapper
            open={this.state.open}
            onClose={() => this.goToLogin()}
          >
            <Box className="modal-main-section">
                    <img  src={mailImage} alt="mailimage" style={{ width: 190 }} />
                    <Box className="modal-body-content">
                        <Typography id="simple-modal-description" className="modalHeadingText">
                        {configJSON.checkEmail}
                        </Typography>
                        <Typography className="modalDescriptionText">
                            {configJSON.recoverEmailText}
                        </Typography>
                        <Button variant="outlined" onClick={() => this.closeModal()} className="okay-button">{configJSON.okayButtonText}</Button>
                    </Box>
            </Box>
          </ModalWrapper>
          </ForgotMainView>
        </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalWrapper = styled(Modal)({
    '& .modal-main-section': {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        background: "#FFF",
        width: "600px",
        textAlign: "center",
        borderRadius: "20px",
        padding: "50px",
        "@media only screen and (max-width: 1000px)" : {
            width: "460px",
            padding: "32px"
        }
    },
    '& .modal-body-content': {
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: '25px',
        alignItems: "center"
    },
    '& .okay-button': {
        display: "flex",
        width: "190px",
        height: "56px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "12px",
        border: "1px solid #9E9E9E",
        background: "#FFF",
        color: "#333",
        fontFamily: "Urbanist",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "30px",
        textTransform: "capitalize"
    },
    '& .modalHeadingText': {
        color: "#333",
        fontFamily: "Urbanist",
        fontSize: "35px",
        fontWeight: 700
    },
    '& .modalDescriptionText': {
        color: "#333",
        textAlign: "center",
        fontFamily: "Urbanist",
        fontSize: "22px",
        fontWeight: 400,
        lineHeight: "30px"
    }
})

const ForgotMainView = styled(Box)({
    '& .mainView': {
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      display: "flex",
      "@media only screen and (max-width: 767px)" : {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "74px"
      }
    },
    '& .leftView': {
      top: "100px",
      paddingTop: '56px',
      paddingRight: '72px',
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: '0px',
      paddingLeft: '72px',
      backgroundColor: '#EEFFF8',
      width:'650px',
      "@media only screen and (max-width: 767px)" : {
        width: 'auto',
        paddingLeft: '30px',
        backgroundColor: '#FFFF',
        alignSelf:'center',
        paddingTop: '20px',
        paddingRight: '30px',
        paddingBottom: '0px',
    }
  },
    '& .infoView':{
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop:'35px',
      position:'relative',
      display: "flex",
      width: "100%",
      "@media only screen and (max-width: 767px)" : {
        marginTop: 0,
      }
    },
    '& .copyright-content': {
        color: "#333",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        bottom: "0px",
        position: "absolute",
        left: "50%",
        opacity: 0.8,
        msTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        "@media only screen and (max-width: 767px)" : {
            display: "none"
        }
    },
    '& .rightView':{
        display: "flex",
        flexDirection: "column",
        paddingTop: '56px',
        paddingRight: '112px',
        paddingBottom: '0px',
        paddingLeft: '72px',
        fontFamily:'Urbanist',
        width:'60%',
        position: "relative",
        "@media only screen and (max-width: 1000px)" : {
          paddingLeft: "40px",
          paddingRight: "40px",
        },
        "@media only screen and (max-width: 767px)" : {
          backgroundColor: '#FFFF',
          padding: "0 24px",
          width:'auto'
        }
    },
    '& .rememberMeView':{
      fontWeight: "400",
      marginTop: "5px",
      fontSize: "16px",
      color: "#333333",
      fontFamily:'Urbanist',
      "@media only screen and (max-width: 767px)" : {
        fontSize: "12px"
      }
    },
    '& .forgotPasswordText': {
      fontWeight: "bold",
      marginTop: "14px",
      fontSize: "16px",
      color: "#333",
      paddingLeft: "20px",
      fontFamily:'Urbanist',
      border:'0px !important',
      "@media only screen and (max-width: 767px)" : {
        fontSize: "12px",
      }
    },
    '& .loginBtnView': {
      alignItems: "center",
      justifyContent: 'center',
      display: "flex",
      flexDirection: "column",
      alignSelf: 'center',
      fontFamily:'Urbanist',
      padding: "10px 0px",
      "@media only screen and (max-width: 767px)" : {
        fontSize: "12px",
      }
    },
    '& .reserved':{
      fontSize:'12px',
      fontFamily:'Urbanist',
      textAlign:'center',
  },
  '& .graphLoginImage':{
    width: '250px',
    height: '250px',
    zIndex: 1,
    marginLeft: '0px',
    "@media only screen and (max-width: 767px)" : {
      width: '150px',
      height: '150px',
      zIndex: 1,
      marginLeft: "-55px",
      objectFit: "contain"
    }
  },
  '& .watchImage':{
    width: '250px',
    height: '25px',
    marginLeft: '0px',
    position:'absolute',
    right:'0px',
    top:'140px',
    left:'150px',
    "@media only screen and (max-width: 767px)" : {
        height: "150px !important",
        position: "absolute",
        objectFit: "contain",
        alignSelf: "center",
        top: "100px !important",
        left: "50px !important",
        right: "0px !important",
        zIndex: 999
    }
  },
  '& .imageLoginUpload':{
    width: '250px',
    height: '250px',
    zIndex: 1,
    marginLeft: '45px',
    marginTop:'44px',
    "@media only screen and (max-width: 767px)" : {
        width: "150px",
        marginLeft: "-10px",
        zIndex: 999,
        height: "150px",
        right: "0px",
        marginTop: "0px",
        OObjectFit: "contain",
        objectFit: "contain"
    }
  },
  '& .verticaliLine': { 
    position: 'absolute',
    top: '-20px', left: '0px',
    transform: 'rotate(0deg)',
     width: '700',height:'900px'
   },
   '& .inputLabel':{
    fontSize:'18px',
    fontFamily: 'Urbanist',
    color: '#333',
    fontWeight: 500,
    marginBottom: "8px",
    "@media only screen and (max-width: 767px)" : {
        fontSize: "16px"
    }
   },
   '& .errorMsg':{
    paddingTop:'5px',
    fontFamily: 'Urbanist',
    color: '#FF0000'
   },
   '& .creditLiftLogo':{
    width:'185px',
    height:'53px'
   },
   '& .mobile-back-arrow': {
        display: "none",
        "@media only screen and (max-width: 767px)" : {
            position: "relative",
            display: "block",
            zIndex: 1111
        }
   },
   '& .mobile-submit': {
        "@media only screen and (max-width: 767px)" : {
            width:'100%',
            position:'fixed',
            bottom: 0,
            left: 0,
            boxSizing: "border-box",
            alignSelf:'center',
            padding:'24px',
            background: "#FFF",
            zIndex: 10000
        },
   }
  
  });
  const TypograyView = styled(Typography)({
    '& .forgotText': {
      fontSize: '40px',
      fontFamily: 'Urbanist',
      color: '#333',
      fontWeight: 700,
      "@media only screen and (max-width: 767px)" : {
        display:'none !important'
      }
    },
    '& .createAccount':{
      paddingTop:15,fontSize:'19px',
      fontFamily:'Urbanist',
      "@media only screen and (max-width: 767px)" : {
        display:'none !important'
      }
    },
    '& .personalInfo':{ 
      paddingTop: 30,
      "@media only screen and (max-width: 767px)" : {
        display:'none !important'
      }
     },
     '& .icon-button-section': {
        paddingTop: "20px",
        display: "flex",
        '& .back-arrow': {
            color: "#333", 
            width: "3rem", 
            height: "3rem",
            "@media only screen and (max-width: 1350px)": {
                height: "30px",
                width: "30px"
            }
        },
        "@media only screen and (max-width: 767px)": {
          display: "none"
        }
     },
     '& .labelBlock':{
      fontSize: '38px',
      fontFamily:'Urbanist',
      fontWeight: 700,
      "@media only screen and (max-width: 1350px)": {
        fontSize: "30px"
    }
     },
     '& .labelEmailBlock':{
        paddingTop: "10px",
        fontSize: '26px',
        fontFamily:'Urbanist',
        fontWeight:'400',
        "@media only screen and (max-width: 767px)": {
          display: "none"
        }
     },
     '& .labelTextStyle':{
        display:'none !important',
        "@media only screen and (max-width: 767px)" : {
          display: 'block !important',
          alignSelf:'center',
          textAlign:'center',
          fontSize: '20px',
          fontFamily:'Urbanist',
          fontWeight: 500,
          marginBottom: "4px"
        }
      },
      '& .pwdLabelStyle':{
        display:'none !important',
        "@media only screen and (max-width: 767px)" : {
          display: 'block !important',
          alignSelf:'center',
          textAlign:'center',
          fontSize: '12px',
          fontFamily:'Urbanist',
          color:'#292D32',
          fontWeight: 500,
          opacity: 0.5
        }
      },
  });
  const ButtonWrapper = styled(Button)({
    padding: "0",
    width:'100%',
    '& .submitButton':{
        display: "flex",
        height: "56px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        background: "#019F48",
        color: "#FFF",
        fontFamily: "Urbanist",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "30px",
        textTransform: "capitalize",
        "@media only screen and (max-width: 767px)": {
          fontSize: "16px",
          lineHeight: "normal"
        }
    }
  })

// Customizable Area End
