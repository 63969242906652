import React from "react";
// Customizable Area Start
import { Box, Divider, Link, styled } from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";

export interface SocialMediaLinkData {
  linkedin_link: string,
  fb_link: string,
  twitter_link: string,
  instagram_link: string
}

export interface ContactUsData {
  email: string;
  phone: string;
  location: string;
}
// Customizable Area End

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  socialMediaLinkData: SocialMediaLinkData;
  contactUsData: ContactUsData;
  // Customizable Area End
}

// Customizable Area Start
interface S {}

interface SS {}

class Footer extends BlockComponent<Props, S, SS> {
  static instance: Footer;

  constructor(props: Props) {
    super(props);
    Footer.instance = this;
  }

  goToTermsConditions = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationTermAndConditionWeb))
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    msg.addData(getName(MessageEnum.UrlPathParamMessage), 'web')
    this.send(msg)
  }
  goToPrivacyPolicy = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PrivacyPage');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    msg.addData(getName(MessageEnum.NavigationScreenNameMessage),'web')
     this.send(msg);
  }

  getHeadText = (text: string) => <Box className="title-text">{text}</Box>;

  getBottomText = (text: string) => <Box className="bottom-text">{text}</Box>;

  linkText = (text: string, path: MessageEnum) => (
    <Box className="link-text" onClick={() => this.handleNavigation(path)}>
      {text}
    </Box>
  );

  handleNavigation = (messageEnum: MessageEnum) => {
    const navigateToTab: Message = new Message(
      getName(messageEnum)
    );
    navigateToTab.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(navigateToTab);
  }

  getIconLink = (img: string, text: string, label?: string) => (
    <Box className="icon-link">
      <img src={img} />
      <Box style={{ textDecoration: label ? "underline" : "none", cursor: label ? "pointer": "none"}}>{text}</Box>
    </Box>
  );

  getEmailLink = (img: string, text: string, isPhone?: boolean) => (
    <Box className="icon-link">
      <img height={26} src={img} />
      <a style={{ color: "#333", textUnderlineOffset: "5px" }} href={`${isPhone ? "tel" : "mailto"}:${text}`}><Box mb="8px">{text}</Box></a>
    </Box>
  )

  render() {
    return (
      <FooterWrapper>
        <Box>
          <Box>
            <Box className="division-wrapper">
              <Box>
                <img
                  className="credit-lift-logo"
                  src={require("./ic_creditlift_logo.svg")}
                />
                {this.getHeadText("Follow Us on")}
                <Box className="social-platform-wrapper">
                  <Link target="_blank" href={this.props.socialMediaLinkData.linkedin_link} ><img src={require("./ic_linkedin_logo.svg")} alt="linkedin" /></Link>
                  <Link target="_blank" href={this.props.socialMediaLinkData.fb_link} ><img src={require("./ic_facebook_logo.svg")} alt="facebook" /></Link>
                  <Link target="_blank" href={this.props.socialMediaLinkData.instagram_link} ><img src={require("./ic_insta_logo.svg")} alt="instagram" /></Link>
                  <Link target="_blank" href={this.props.socialMediaLinkData.twitter_link} ><img src={require("./ic_twitter_logo.svg")} alt="twitter" /></Link>
                </Box>
              </Box>
              <Box className="quick-link-section">
                {this.getHeadText("Quick Links")}
                {this.linkText("Home", MessageEnum.NavigationDashboardMessage)}
                {this.linkText("About us", MessageEnum.NavigationAboutUsMessage)}
                {this.linkText("Contact us", MessageEnum.NavigationContactUsMessage)}
              </Box>
              <Box className="contact-us-section">
                <Box className="contact-us-text">Contact Us</Box>
                {this.getIconLink(
                  require("./ic_location_img.svg"),
                  this.props.contactUsData.location
                )}
                {this.getEmailLink(
                  require("./ic_email_logo.svg"),
                  this.props.contactUsData.email
                )}
                {this.getEmailLink(
                  require("./ic_phone_logo.svg"),
                  this.props.contactUsData.phone, true
                )}
              </Box>
            </Box>
          </Box>
          <Divider className="divider" />
          <Box className="bottom-wrapper">
            {this.getBottomText("© Credit Lift. All Rights Reserved")}
            <Box display="flex">
              <Box className="bottom-text" style={{ cursor: "pointer"}} onClick={this.goToTermsConditions}>Terms and Conditions</Box>&nbsp;
              <span> | </span>&nbsp;
              <Box className="bottom-text" style={{ cursor: "pointer"}} onClick={this.goToPrivacyPolicy}>Privacy Policy</Box>
            </Box>
          </Box>
        </Box>
      </FooterWrapper>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const FooterWrapper = styled(Box)({
  background:
    "linear-gradient(0deg, #E1FFF1 0%, rgba(251, 253, 255, 0.47) 115.1%)",
  width: "100%",
  paddingBottom: "1px",
  "&>div": {
    margin: "50px 150px 0",
    color: "#333",
    fontFamily: "Urbanist",
    fontStyle: "normal",
    "@media only screen and (max-width: 1000px)" : {
      margin: "40px 50px 0",
    },
  },
  "& .title-text": {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "34px"
  },
  "& .division-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "55px",
    "@media only screen and (max-width:750px)" : {
      flexDirection: "column",
      gap: "40px"
    },
  },
  "& .bottom-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    margin: "24px 0 26px ",
  },
  "& .bottom-text": {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px"
  },
  "& .divider": {
    background: "#DCDCDC",
    height: "1px"
  },
  "& .credit-lift-logo": {
    margin: "0 0 15px -9px"
  },
  "& .social-platform-wrapper": {
    display: "flex",
    gap: "20px",
    marginTop: "25px",
    "& a, img": {
      borderRadius: "50%"
    }
  },
  "& .icon-link": {
    display: "flex",
    gap: "25px",
    lineHeight: "26px",
    letterSpacing: "0.9px",
    fontSize: "18px",
    fontWeight: 400
  },
  "& .contact-us-text": {
    lineHeight: "34px",
    letterSpacing: "1px",
    fontSize: "20px",
    fontWeight: 600
  },
  "& .contact-us-section": {
    display: "flex",
    gap: "20px",
    flexDirection: "column"
  },
  "& .quick-link-section": {
    display: "flex",
    gap: "24px",
    flexDirection: "column"
  },
  "& .link-text": {
    fontSize: "18px",
    fontWeight: 400,
    cursor: "pointer"
  }
});
// Customizable Area End

export default Footer;
