import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { equifaxImg, experianImg, transUnionImg } from "./assets";

interface ReportDetailsData {
    open_accounts: number;
    self_reported_accounts: number;
    accounts_ever_late: number;
    closed_accounts: number;
    collections: number;
    credit_and_debt: number;
    total_credit: number;
    credit_card_and_credit_line_debt: number;
    self_reported: number;
    loan_debt: number;
    collection_debt: number;
    total_debt: number;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  updatedOn: string;
  chartData: {
    name: string,
    score: number
  }[];
  reportDetails: ReportDetailsData;
  nextUpdate: string;
  score: number;
  percentage: number;
  type: string;
  cycleValue: string;
  reportDurationData: { label: string; value: string; }[]
  // Customizable Area End
}
interface SS {}

export default class ReportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      updatedOn: "31 July, 2023",
      nextUpdate: "20 August, 2023",
      score: 750,
      cycleValue: "1",
      chartData: [
        {
          name: "JAN",
          score: 450
        },
        {
          name: "FEB",
          score: 460
        },
        {
          name: "MAR",
          score: 490
        },
        {
          name: "APR",
          score: 480
        },
        {
          name: "MAY",
          score: 550
        },
        {
          name: "JUN",
          score: 770
        },
        {
          name: "JUL",
          score: 560
        },
        {
          name: "AUG",
          score: 430
        },
        {
          name: "SEP",
          score: 420
        },
        {
          name: "OCT",
          score: 580
        },
        {
          name: "NOV",
          score: 650
        },
        {
          name: "DEC",
          score: 470
        }
      ],
      percentage: 35,
      reportDetails: {
        open_accounts: 10,
        self_reported_accounts: 0,
        accounts_ever_late: 2,
        closed_accounts: 3,
        collections: 0,
         credit_and_debt: 840,
        total_credit: 12000,
        credit_card_and_credit_line_debt: 840,
        self_reported: 0,
        loan_debt: 32700,
        collection_debt: 0,
        total_debt: 33540,
      },
      type: "Experian",
      reportDurationData: [
        {
          label: "18 July 2022 - 18 July 2023",
          value: "1"
        },{
          label: "18 July 2023 - 18 July 2024",
          value: "2"
        }
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getCreditLogo = () => {
    switch(this.state.type){
      case "Experian":
        return experianImg;
      case "TransUnion":
        return transUnionImg;
      case "Equifax":
        return equifaxImg;
      default:
        return ""
    }
  }

  goToDashboard = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationDashboardMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ cycleValue: event.target.value as string})
  }

  downloadReport = () => {
      const pdfUrl = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.download = "document.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
  // Customizable Area End
}
