Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "tasks/tasks";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.getTaskList = "bx_block_tasks/tasks";
exports.getTaskById = "bx_block_tasks/tasks/:id";
exports.updateTask = "bx_block_tasks/task_activities/:id"

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";
exports.textTotalTasks = "Total Tasks";
exports.textTasksPending = "Tasks Pending";
exports.textTasksInprogress = "Tasks Inprogress";
exports.textTasksCompleted = "Tasks Completed";
exports.textTaskNotStarted = "Tasks Not Started"
exports.textMyTasks = "My Tasks";
exports.textAllTasks = "All Tasks";
exports.textPending = "Pending";
exports.textInprogress = "Inprogress";
exports.textCompleted = "Completed";
exports.textNotStarted = "Not Started";
exports.textSearchTask = "Search Task";
exports.textLastUpdated = "Last updated: ";
exports.textSortBy = "Sort By";
exports.textForNoTaskTitle = "You don’t have any new tasks";
exports.textForNoTaskDescription = "You’ll see the tasks here if any added";
exports.taskCompleted = "Task Completed";
exports.taskCompletedDescription = "Your task has been completed successfully.";
exports.textOkay = "Okay";
exports.textStartDate = "Start Date:";
exports.textFinishDate = "Finish Date:";
exports.textTaskActivity = "Task Activity";
exports.monthText = "Month";
exports.weekText = "Week";
exports.yearText = "Year";
exports.monthlyText = "monthly";
exports.weeklyText = "weekly";
exports.yearlyText = "yearly";
exports.allTasksText = 'AllTasks';
exports.pendingText = "pending";
exports.inprogressText = "in_progress";
exports.completeText = "completed";
exports.notStartedText = "not_started";
exports.taskListText = "Task List";
// Customizable Area End
