import React from "react";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../chat/src/LiveChat.web";
import { Box, styled, LinearProgress, CircularProgress, Modal, Button, Stepper, Step, StepLabel } from "@material-ui/core";
import { backIcon, tasksCompletedIcon, trueIcon } from "./assets";
// Customizable Area End

import TaskDetailViewController, { Props, configJSON } from "./TaskDetailViewController.web";

export default class TaskDetailView extends TaskDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getDateLabel = (label: string, date: string) => <Box>{label}<span>{date}</span></Box>

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          screenName="Tasks"
          screenTitle="Task Details"
          isScreenTitleCenter={true}
          isBackButton={true}
          fullWidthNavigation={true}
        />
        <ModalWrapper
            open={this.state.isModalOpen}
            onClose={this.handleClose}
        >  
            <Box className="modal-content">
                <img className="modal-image" src={tasksCompletedIcon} alt="task-completed" />
                <Box className="title">{configJSON.taskCompleted}</Box>
                <Box className="description">{configJSON.taskCompletedDescription}</Box>
                <Button data-test-id="okay-button" className="okay-button" onClick={this.handleClose}>{configJSON.textOkay}</Button>
            </Box>
        </ModalWrapper>
        <TaskDetailWrapper>
            <Box className="detail-view-top-container">
                <Box className="detail-view-top-head">  
                    <img data-test-id="back-button" onClick={this.handleBack} className="back-icon" src={backIcon} alt="back" />
                    <Box className="detail-view-top-label">{this.state.taskDetail.label}</Box>
                </Box>
                <Box className="detail-view-date-content">
                    {this.getDateLabel(configJSON.textStartDate, this.state.taskDetail.startDate)}
                    {this.getDateLabel(configJSON.textFinishDate, this.state.taskDetail.finishDate)}
                    {this.getDateLabel(configJSON.textLastUpdated, this.state.taskDetail.lastUpdate)}
                </Box>
                <Box className="detail-view-status-wrap" style={{ borderLeftColor: this.getColor(this.state.taskDetail.status), background: this.getBackgroundColor(this.state.taskDetail.status) }}>
                    <Box className="detail-view-status-content">
                        <Box data-test-id="status-label" className="bottom-content-status">{this.state.taskDetail.status}</Box>
                        <Box className="bottom-content-percentage">{this.state.taskDetail.percentage}%</Box>
                        <Box className="bottom-content-description">{this.state.taskDetail.description}</Box>
                    </Box>
                    <LinearProgress className={`linear-progress status-${this.state.taskDetail.status.toLowerCase().split(' ').join('-')}`} variant="determinate" value={this.state.taskDetail.percentage} />
                    <div className="circular-progress-wrapper">
                        <CircularProgress
                            size={30}
                            className="inner-progress-bar"
                            variant="determinate"
                            thickness={4}
                            value={100}
                            style={{ color: this.getBackColor(this.state.taskDetail.status) }}   
                        />
                        <CircularProgress
                            variant="determinate"
                            className="outer-progress-bar"
                            thickness={4}
                            value={this.state.taskDetail.percentage}
                            style={{ color: this.getColor(this.state.taskDetail.status) }}
                            size={30}
                        />
                        <Box className="circular-progress-label">{this.state.taskDetail.percentage}%</Box>
                    </div>
                </Box>
            </Box>
            <Box className="detail-view-bottom-container">
                <Box className="detail-view-title">{configJSON.textTaskActivity}</Box>
                <Box>
                    <Stepper orientation="vertical">
                        {this.state.taskDetail.taskActivity.map((task, index) => (
                            <Step key={task.title}>
                                <StepLabel data-test-id="step-button" onClick={() => this.handleActivityClick(task)} icon={task.status ? <img className="step-done-icon" src={trueIcon} /> : <Box className="task-not-done-icon" />}>
                                    <Box className={`step-content-text ${this.state.taskDetail.taskActivity.length-1 !== index ? "step-content-box" : ""}`}>
                                        <Box className="step-title">{task.title}</Box>
                                        <Box className="step-description">{task.description}</Box>
                                    </Box>
                                </StepLabel>
                            </Step> 
                        ))}
                    </Stepper>
                </Box>
            </Box>
        </TaskDetailWrapper>
        <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
         <LiveChat />
        </Box>
      </React.Fragment>
    ); 
    // Customizable Area End
  }
}

// Customizable Area Start

const TaskDetailWrapper = styled(Box)({
    padding: "30px 50px",
    boxSizing: "border-box",
    minHeight: "calc(100vh - 100px)",
    background: "#F3FAF7",
    fontFamily: "Urbanist",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#333",
    "@media screen and (max-width: 767px)" : {
        color: "#282D32",
        padding: "24px",
        minHeight: "calc(100vh - 70px)",
    },
    "& .top-section": {
        gap: "30px",
        overflow: "auto",
        display: "flex",
        justifyContent: "space-between",
        "@media screen and (max-width: 767px)" : {
            gap: "12px"
        },
        "& .top-title-text": {
            lineHeight: "30px",
            fontSize: "20px",
            color: "#9E9E9E",
            fontWeight: 600,
            "@media screen and (max-width: 767px)" : {
                lineHeight: "18px",
                fontSize: "12px",
                color: "#292D32",
                fontWeight: 400,
            }
        },
        "& .score-text": {
            fontWeight: 700,
            fontSize: "38px",
            lineHeight: "normal",
            "@media screen and (max-width: 767px)" : {
                lineHeight: "16px",
                fontSize: "18px",
                fontWeight: 600,
            }
        },
        "& .top-section-icon": {
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            height: "60px",
            width: "60px",
            display: "flex",
            "@media screen and (max-width: 767px)" : {
                height: "34px",
                width: "34px",
                "&>img": {
                    width: "22.7px",
                    height: "22.7px",
                }
            },
        },
        "& .top-section-container": {
            display: "flex",
            gap: "30px",
            borderRadius: "12px",
            border: "1px solid rgba(1, 159, 72, 0.18)",
            padding: "20px",
            alignItems: "center",
            background: "#FFF",
            minWidth: "315px",
            boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
            "@media screen and (max-width: 767px)" : {
                flexDirection: "column",
                alignItems: "start",
                padding: "12px",
                minWidth: "115px", 
                gap: "8px",
            }
        }
    },
    "& .back-icon": {
        cursor: "pointer"
    },
    "& .MuiStepConnector-lineVertical": {
        minHeight: "30px",
        "@media screen and (max-width: 767px)" : {
            minHeight: "17px"
        }
    },
    "& .bottom-content": {
        background: "#FFF",
        boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
        padding: "30px",
        marginTop: "30px",
        borderRadius: "20px",
        border: "1px solid rgba(1, 159, 72, 0.18)",
        "@media screen and (max-width: 767px)" : {
            padding: "12px"
        }
    },
    "& .bottom-content-content": {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px",
    },
    "& .task-title": {
        lineHeight: "30px",
        fontSize: "22px",
        fontWeight: 700,
        "@media screen and (max-width: 767px)" : {
            lineHeight: "18px",
            fontSize: "16px",
            fontWeight: 600,
        }
    },
    "& .task-status-label": {
        fontWeight: 500,
        color: "#9E9E9E",
        fontSize: "18px",
    },
    "& .acitve-task-status-label": {
        fontWeight: 600,
        borderBottom: "3px solid #019F48",
        color: "#019F48",
        fontSize: "20px",
    },
    "& .bottom-right-content": {
        display: "flex",
        gap: "15px",
        "@media screen and (max-width: 767px)" : {
            gap: "8px"
        },
    },
    "& .MuiOutlinedInput-adornedStart": {
        "@media screen and (max-width: 767px)" : {
            paddingLeft: "6px"
        },
    },
    "& .step-done-icon": {
        "@media screen and (max-width: 767px)" : {
            height: "22px",
            width: "22px"
        },
    },
    "& .search-input": {
        borderRadius: "4px",
        border: "1px solid #CEE1D6",
        background: "#FFF",
        height: "34px",
        width: "215px",
        "@media screen and (max-width: 767px)" : {
            width: "125px"
        },
        "& .MuiInputBase-input": {
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            padding: 0,
            height: "34px",
            color: "#333",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "Urbanist",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
    },
    "& .select-sort": {
        borderRadius: "4px",
        border: "1px solid #CEE1D6",
        background: "#F4FAF7",
        display: "inline-flex",
        gap: "8px",
        flexShrink: 0,
        width: "170px",
        height: "36px",
        padding: "12px 14px",
        alignItems: "center",
        "@media screen and (max-width: 767px)" : {
            width: "150px",
            padding: "12px 6px"
        },
        "& .MuiSelect-select": {
            fontSize: "15px",
            fontWeight: 400,
            fontFamily: "Urbanist",
            background: "transparent",
            padding: 0,
            color: "#333",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
    },
    "& .status-tab-container": {
        display: "flex",
        gap: "20px",
        "@media screen and (max-width: 767px)" : {
            display: "none"
        },
        "&>div": {
            padding: "0 24px 5px",
            cursor: "pointer"
        },
    },
    "& .bottom-content-card": {
        margin: "30px 0",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        "@media screen and (max-width: 767px)" : {
            margin: "16px 0",
            gap: "12px",
        },
        "&>.task-content-container": {
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            borderLeft: "3px solid #019F48",
            background: "#F5FFFB",
            padding: "20px 30px",
            "@media screen and (max-width: 767px)" : {
                padding: "12px"
            },
            "& .bottom-content-card-title": {
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: 600,
                "&>span": {
                     color: "#9E9E9E",
                     fontSize: "16px",
                     fontWeight: 500,
                },
                "@media screen and (max-width: 767px)" : {
                    fontSize: "12px",
                    lineHeight: "normal",
                    fontWeight: 700,
                    marginBottom: "7px",
                    "&>span": {
                        color: "#87898C",
                        fontSize: "8px",
                        fontWeight: 600
                    }
                }
            },
            "& .bottom-content-card-description": {
                fontSize: "18px",
                lineHeight: "35px",
                "@media screen and (max-width: 767px)" : {
                    fontSize: "12px",
                    lineHeight: "normal"
                }
            },
            "& .bottom-content-percenatage-container": {
                width: "40%",
                maxWidth: "425px",
                "&>div": {
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "15px"
                },
                "@media screen and (max-width: 767px)" : {
                    display: "none"
                },
                
            }
        }
    },
    "& .bottom-content-status": {
        fontSize: "16px",
        lineHeight: "30px",
        fontWeight: 500,
        "@media screen and (max-width: 767px)" : {
            color: "#333",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "normal",
        }
    },
    "& .bottom-content-percentage": {
        fontSize: "16px",
        lineHeight: "30px",
        fontWeight: 600,
        "@media screen and (max-width: 767px)" : {
            display: "none"
        }
    },
    "& .bottom-content-description": {
        display: "none",
        "@media screen and (max-width: 767px)" : {
            display: "block",
            color: "#333",
            fontSize: "12px",
            fontWeight: 400,
            maxWidth: "calc(100% - 25px)"
        }
    },
    "& .linear-progress": {
        background: "#D9D9D9",
        borderRadius: "30px",
        height: "10px",
        "@media screen and (max-width: 767px)": {
            display: "none"
        }
    },
    "& .divider": {
        "@media screen and (max-width: 767px)" : {
            display: "none"
        }
    },
    "& .status-not-started": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#D9D9D9"
        }
    },
    "& .status-pending": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#FF0000"
        }
    },
    "& .status-completed": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#019F48"
        }
    },
    "& .status-inprogress": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#FD8D02"
        }
    },
    "& .no-task-container": {
        textAlign: "center",
        margin: "60px 0",
        "&>.description": {
            fontSize: "24px",
            lineHeight: "33px",
            color: "#9E9E9E"
        },
        "&>.title": {
            fontSize: "38px",
            fontWeight: 700,
            marginTop: "30px"
        },
    },
    "& .task-not-done-icon": {
        width: "30px",
        height: "30px",
        flexShrink: 0,
        borderRadius: "52px",
        border: "3px solid #019F48",
        background: "#FFF",
        boxSizing: "border-box",
        cursor: "pointer",
        "@media screen and (max-width: 767px)" : {
            height: "22px",
            width: "22px"
        },
    },
    "& .detail-view-top-container": {
        borderRadius: "20px",
        padding: "30px",
        border: "1px solid rgba(1, 159, 72, 0.18)",
        background: "#FFF",
        boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
        "@media screen and (max-width: 767px)" : {
            padding: "16px",
            borderRadius: "20px 20px 0 0",
            borderBottom: "none"
        },
        "& .detail-view-top-label": {
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "30px",
            "@media screen and (max-width: 767px)" : {
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "18px"
            }
        },
        "& .detail-view-top-head": {
            display: "flex",
            alignItems: "center",
            gap: "15px",
            marginBottom: "20px",
            "@media screen and (max-width: 767px)" : {
                "&>img": {
                    display: "none"
                }
            }
        },
        "& .detail-view-date-content": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "18px",
            "&>div": {
                color: "#9E9E9E",
                fontSize: "16px",
                fontWeight: 500,
                "&>span": {
                    marginLeft: "10px",
                    color: "#333",
                    fontSize: "16px",
                    fontWeight: 500
                },
                "@media screen and (max-width: 767px)" : {
                    fontSize: "12px",
                    "&>span": {
                        fontSize: "12px"
                    }
                }
            }
        },
        "& .detail-view-status-wrap": {
            borderRadius: "8px",
            borderLeft: "3px solid #019F48",
            background: "#F5FFFB",
            padding: "20px 30px 30px",
            marginTop: "20px",
            "@media screen and (max-width: 767px)": {
                padding: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            },
            "&>.detail-view-status-content": {
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                "@media screen and (max-width: 767px)" : {
                    flexDirection: "column",
                    gap: "7px",
                    marginBottom: 0,
                    width: "calc(100% - 25px)"
                },
            }
        }
    },
    "& .detail-view-bottom-container": {
        marginTop: "30px",
        borderRadius: "20px",
        border: "1px solid rgba(1, 159, 72, 0.18)",
        background: "#FFF",
        boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
        padding: "30px",
        "@media screen and (max-width: 767px)" : {
            marginTop: 0,
            borderRadius: "0 0 20px 20px",
            borderTop: "none",
            padding: "16px"
        },
        "& .MuiStepLabel-labelContainer": {
            marginLeft: "12px",
        },
        "& .detail-view-title": {
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "30px",
            "@media screen and (max-width: 767px)" : {
                fontSize: "16px",
                lineHeight: "18px",
            }
        },
        "& .MuiStepper-root": {
            padding: "12px 0"
        },
        '& .MuiStepLabel-root': {
            alignItems: "start"
        },
        '& .MuiStepLabel-iconContainer': {
            zIndex: 111
        },
        '& .step-content-text': {
            wordBreak: "break-word"
        },
        '& .step-content-box': {
            borderLeft: "3px solid #019F48",
            paddingLeft: "36px",
            marginLeft: "-36px",
            zIndex: 0,
            "@media screen and (max-width: 767px)": {
                paddingLeft: "32.5px",
                marginLeft: "-32.5px"
            }
        },
        "& .step-title": {
            color: "#333",
            fontFamily: "Urbanist",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "30px",
            "@media screen and (max-width: 767px)": {
                fontSize: "16px",
                lineHeight: "18px"
            }
        },
        "& .step-description": {
            color: "#9E9E9E",
            fontFamily: "Urbanist",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "26px",
            "@media screen and (max-width: 767px)": {
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 500
            }
        },
        "& .MuiStepConnector-line": {
            borderLeftWidth: "3px",
            borderColor: "#019F48"
        },
        "& .MuiStepConnector-vertical": {
            padding: 0,
            marginLeft: "14.5px",
            "@media screen and (max-width: 767px)" : {
                marginLeft: "9.5px"
            },
        }
    },
    "& .circular-progress-wrapper": {
        display: "block",
        position: "relative",
        width: "30px",
        height: "30px",
        "&>div": {
            width: "30px",
            height: "30px",
        },
        "& .inner-progress-bar": {
            position: "absolute",
            top: 0,
            right: 0,
            transform: "rotate(-270deg) !important"
        },
        "& .circular-progress-label": {
            position: "absolute",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            right: 0,
            fontSize: "7px",
            fontWeight: 600
        },
        "@media screen and (min-width: 768px)" : {
            display: "none"
        },
        "& .outer-progress-bar": {
            position: "absolute",
            top: 0,
            right: 0,
            transform: "rotate(-270deg) !important",
            "& circle": {
                strokeLinecap: "round"
            }
        },
    }
})

const ModalWrapper = styled(Modal)({
    "& .modal-content": {
        padding: "50px",
        background: "#FFF",
        fontFamily: "Urbanist",
        color: "#333",
        maxWidth: "750px",
        width: "calc(100% - 48px)",
        boxSizing: "border-box",
        margin: "0",
        textAlign: "center",
        borderRadius: "20px",
        position: "absolute",
        top: "50%",
        left: "50%",
        outline:"none",
        msTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        "@media screen and (max-width: 767px)" : {
            padding: "20px",
            borderRadius: "8px",
            maxWidth: "400px",
        },
        '&>.modal-image': {
            "@media screen and (max-width: 767px)" : {
                width: "125px",
            }
        },
        "&>.title": {
            fontSize: "40px",
            fontWeight: 700,
            margin: "40px 0 20px",
            "@media screen and (max-width: 767px)" : {
                fontSize: "16px",
                margin: "16px 0 8px"
            },
        },
        "&>.description": {
            lineHeight: "33px",
            fontSize: "24px",
            fontWeight: 400,
            "@media screen and (max-width: 767px)" : {
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "normal"
            },
        },
        "& .okay-button": {
            borderRadius: "12px",
            border: "1px solid #9E9E9E",
            background: "#FFF",
            textTransform: "none",
            fontSize: "22px",
            fontWeight: 600,
            lineHeight: "30px",
            width: "190px",
            height: "56px",
            fontFamily: "Urbanist",
            color: "#333",
            marginTop: "30px",
            "@media screen and (max-width: 767px)" : {
                fontSize: "9px",
                lineHeight: "13px",
                marginTop: "15px",
                borderRadius: "5px",
                height: '22px',
                width: "77px"
            }
        }
    }
})
// Customizable Area End
