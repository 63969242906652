// Basic Details
Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  exports.urlGetValidations = "profile/validations";
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.loginAPiMethod = "POST";
  exports.loginApiContentType = "application/json";
  
  // Customizable Area Start
  exports.errorEmailNotValid = "Email not valid.";
  exports.errorPasswordNotValid = "Password not valid.";
  exports.placeHolderEmail = "Email";
  exports.placeHolderPassword = "Password";
  exports.labelHeader = "Login your account";
  exports.btnTxtLogin = "LOG IN";
  exports.labelRememberMe = "Remember me";
  exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
  exports.labelOr = "OR";
  exports.basicInfo = "Basic Details";
  
  exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
  exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
  exports.loginAPiEndPoint = "login/login";
  exports.getContriesDataUrl = "account_block/countries";
  exports.getStatesDataUrl = "account_block/states?country_code=:state";
  exports.houseNumberErrorText = "Building/House No is required";
  exports.countryErrorText = "Country is required";
  exports.streetErrorText = "Street is required";
  exports.postalCodeFormatErrorText = (text) => {
    if (!text) {
      return 'Postal code is required';
    } else if (text.length < 5) {
      return 'Postal code should have at least 5 digits';
    } else if (text.length > 6) {
      return 'Postal code should not exceed 6 digits';
    }
    return '';
  };
  exports.stateErrorText = "State is required";
  exports.dobErrorText = "DOB is required";
  exports.genderErrorText = "Gender is required";
  exports.dataNotFoundText = "No data available";

  // Customizable Area End