import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled
} from "@material-ui/core";
import { emptyNotification } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../../blocks/chat/src/LiveChat.web";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <NavigationMenu id={this.props.id} notifyCount={this.state.notifyCount} isDrawer={true} fullWidthNavigation={true} isScreenTitleCenter={true} screenTitle={configJSON.notificationText} navigation={this.props.navigation} screenName={configJSON.notificationText} />
        <NotificationWrapper>
          <Box className="notification-section"> 
            <Box className="notification-text">{configJSON.notificationText}</Box>
            { this.state.data.length === 0 ?
              <EmptyNotificationWrapper data-test-id="empty">
                <img src={emptyNotification} className="empty-notification-image" />
                <TextNotificationWrapper>
                    <Typography className="notNotification">{configJSON.notificationErrorText}</Typography>
                    <Typography className="getNotification">{configJSON.notificationErrorSubText}</Typography>
                </TextNotificationWrapper>
              </EmptyNotificationWrapper>
              : <Box className="notification-container">
                {this.state.data.map((notificationData)=> {
                const { is_read, headings, contents, created_at_formatted} = notificationData.attributes;
                return(
                  <Box data-test-id="notification-content" onClick={() => this.markAsRead(notificationData.id,  is_read)} className={is_read ? "readNotification" : "listItem"}>
                    <Box className="notification-title-content">
                      <Typography className="title-text" style={{ color: is_read ? '#333' : '#019F48'}}>{headings}</Typography>
                      <Typography className="time">{created_at_formatted}</Typography>
                    </Box>
                    <Typography className="description">{contents}</Typography>
                  </Box>
                )
              })}
              </Box>
            }
          </Box>
        </NotificationWrapper>
        <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
         <LiveChat />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const NotificationWrapper = styled(Box)({
  background: "#F3FAF7",
  fontFamily: "Urbanist",
  fontStyle: "normal",
  padding: "30px",
  color: "#333",
  fontWeight: 400,
  lineHeight: "normal",
  boxSizing: "border-box",
  minHeight: "calc(100vh - 100px)",
  "@media only screen and (max-width: 767px)" : {
    minHeight: "calc(100vh - 70px)",
    padding: "24px"
  },
  '& .notification-title-content': {
    flexDirection:'row',
    display:'flex',
    alignItems:'flex-end',
    flexWrap: 'wrap',
    "@media only screen and (max-width: 767px)" : {
      marginBottom: "8px"
    },
  },
  '& .notification-section': {
    borderRadius: "20px",
    boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
    border: "1px solid rgba(1, 159, 72, 0.18)",
    background: "#FFF",
    padding: "30px",
    "@media only screen and (max-width: 767px)" : {
      padding: "16px",
    },
    '& .notification-text': {
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: '30px',
      marginBottom: '25px', 
      "@media only screen and (max-width: 767px)" : {
        display: 'none'
      },
    }
  },
  '& .empty-notification-image': {
    "@media only screen and (max-width: 767px)" : {
      width: "150px",
    },
  },
  '& .notification-container': {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "@media only screen and (max-width: 767px)" : {
      gap: "16px"
    },
  },
  '& .listItem': {
    cursor: "pointer",
    display: 'flex',  
    padding: '20px 30px',
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid #DBDBDB',
    background: '#F5FFFB',
    "@media only screen and (max-width: 767px)" : {
      padding: "12px"
    }
  },
  '& .readNotification':{
    display: 'flex',
    padding: '20px 30px',
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid #DBDBDB',
    background: '#FFF',
    "@media only screen and (max-width: 767px)" : {
      padding: "12px"
    }
  },
  '& .title-text': {
    color: "#333",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Urbanist",
    paddingRight:'10px',
    "@media only screen and (max-width: 767px)" : {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: 'normal',
      paddingRight:'4px',
    },
  },
  '& .time': {
    color:'#9E9E9E',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    lineHeight: '24px',
    "@media only screen and (max-width: 767px)" : {
      fontSize: "8px",
      fontWeight: 400,
      opacity: 0.5,
      color: "#333",
      lineHeight: "13px"
    },
  },
  '& .description': {
    color: '#333',
    fontFamily: 'Urbanist',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '35px',
    "@media only screen and (max-width: 767px)" : {
      lineHeight: "normal",
      fontSize: "12px"
    }
  }
});

const EmptyNotificationWrapper = styled(Box)({
  margin: '150px 0 125px',
  display: "flex",
  gap: '30px',
  justifyContent: "center",
  alignItems:  "center",
  flexDirection: "column"
});

const TextNotificationWrapper = styled(Typography)({
  '& .notNotification':{
      color: '#333',
      fontFamily: 'Urbanist',
      fontSize: '38px',
      fontWeight: '700',
      "@media only screen and (max-width: 767px)" : {
        color: '#292D32',
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px"
      },
  },
  '& .getNotification':{
      color: '#9E9E9E',
      textAlign: 'center',
      fontFamily: 'Urbanist',
      fontSize: '24px',
      fontWeight: '400',
      marginTop: '8px',
      "@media only screen and (max-width: 767px)" : {
        color: "#333",
        fontSize: '12px',
        lineHeight: '19px'
      }
  }
});
// Customizable Area End
