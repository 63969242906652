import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import {IBlock} from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum,{
  getName
} from '../../../framework/src/Messages/MessageEnum';

export default class QuestionBankWebAdapter {
  send: (message: Message) => void;
  constructor(){ 
    const blocksId = uuidv4(); 
    this.send = message => runEngine.sendMessage(blocksId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationQuestionBankMessage)
    ]);
  };

  convert = ( from: Message ) : Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage) );
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'QuestionBank'
    );

    to.addData(
        getName(MessageEnum.NavigationPropsMessage),
        from.getData(getName(MessageEnum.NavigationPropsMessage)),
    );
    return to;
  };

  receive(from: string, message: Message):void {
    this.send(this.convert(message));
  }
}
