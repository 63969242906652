import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

type UserData = {
  id: string;
  type: "email_account";
  attributes: {
    role_id: string;
    user_name: string;
    full_phone_number: string;
    email: string;
    activated: boolean;
    is_completed: boolean;
    status: string;
    device_id: string | null;
    created_at: string;
    setting: {
      id: number;
      sms_notification: boolean;
      account_id: number;
    };
    updated_at: string;
    custom_form_attributes: {
      id: number;
      address: string;
      country: string;
      zip_code: string;
      state: string;
      gender: string;
      street: string;
      birthday: string;
    } | null;
    question_answers_attributes: {
      id?: number;
      question?: string;
      answer?: string[];
    }[] | null;
    file_url: {
      url: string;
      name: string;
    }[];
    image_url: string | null;
    tasks: {
      id: number;
      task_list_id: number | null;
      title: string;
      assigned_to_id: number | null;
      assigned_to_type: string | null;
      due_date: string | null;
      description: string;
      status: string;
      priority: string | null;
      created_at: string;
      updated_at: string;
      account_id: number;
      start_date: string;
      end_date: string;
    }[];
  };
}

interface TasksData {
  total: number;
  pending: number;
  inprogress: number;
  completed: number;
  notStarted: number;
}

interface TaskData {
  id: string;
  lastUpdated: string;
  status: string;
  percentage: number;
  description: string;
  title: string;
}

interface TaskActivityResData {
title: string;
description: string;
status: boolean;
id: string;
updated_at: string;
}

interface TaskDataRes {
id: string;
attributes: {
  title: string;
  description: string;
  status: string;
  updated_at: string;
  task_activities: TaskActivityResData[]
}
}

interface TasksDataRes {
data: TaskDataRes[];
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  userName: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  isOpenTransUnion: boolean;
  isOpenEquiefax: boolean;
  isOpenExperian: boolean;
  selectedCreditMenu: string;
  scoreValue: number;
  remainingSeconds: number;
  emailValue: string;
  pendingTasks: {
    title: string;
    lastUpdated: string;
    description: string;
    status: string;
    percentage: number;
  }[];
  allTasks: {
    title: string;
    lastUpdated: string;
    description: string;
    status: string;
    percentage: number;
  }[];
  chartData: {
    name: string;
    score: number;
  }[];
  modalData: {
    isModalOpen: boolean;
    modalImg: string;
    modalType: "ConnectAccount" | "VerifyOTP" | "AccountVerified" | "";
    modalName: string;
  };
  isEmailInvalid: boolean;
  isOTPInvalid: boolean;
  otpValue: string;
  allAssignedUsers: UserData[];
  userType: string | null;
  selectedUser: UserData | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  getAssignedUsersApiCallId: string = "";
  getUserDetailsApiCallId: string = "";
  getPendingTasksApiCallId: string = "";
  getAllTasksApiCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  interval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      userName: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      isOpenTransUnion: false,
      isOpenEquiefax: false,
      isOpenExperian: false,
      selectedCreditMenu: "TransUnion",
      scoreValue: 340,
      remainingSeconds: 0,
      pendingTasks: [],
      allTasks: [],
      chartData: [
        {
          name: "JAN",
          score: 350
        },
        {
          name: "FEB",
          score: 450
        },
        {
          name: "MAR",
          score: 480
        },
        {
          name: "APR",
          score: 580
        },
        {
          name: "MAY",
          score: 550
        },
        {
          name: "JUN",
          score: 670
        },
        {
          name: "JUL",
          score: 560
        },
        {
          name: "AUG",
          score: 330
        },
        {
          name: "SEP",
          score: 420
        },
        {
          name: "OCT",
          score: 480
        },
        {
          name: "NOV",
          score: 550
        },
        {
          name: "DEC",
          score: 450
        }
      ],
      modalData: {
        isModalOpen: false,
        modalImg: "",
        modalName: "",
        modalType: ""
      },
      isEmailInvalid: false,
      otpValue: "",
      isOTPInvalid: false,
      emailValue: "",
      allAssignedUsers: [],
      userType: null,
      selectedUser: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getUserName();
    const userDetail = await getStorageData("userData", true);

    this.setState({
      userType: userDetail.role_id
    }, () => {
      if(this.state.userType === "Agent") {
        this.getAssignedUsersList();
      } else {
        this.getPendingTasks();
      }
    });
    this.handleScrollToTop();

    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const webResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const webErrorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
      if (webResponseJson && !webResponseJson.errors) {
        this.handleSuccessResponse(webApiRequestCallId, webResponseJson);
      } else if (webResponseJson && webResponseJson.errors) {
        this.handleErrorResponse(webApiRequestCallId, webErrorReponse);
      }
    }  
    // Customizable Area End
  }
  // Customizable Area Start

  handleScrollToTop = () => {
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
  }

  handleSuccessResponse(webApiRequestCallId: string, webResponseJson: any) {
    switch (webApiRequestCallId) {
      case this.apiDashboardItemCallId:
        this.updateDashboardData(webResponseJson);
        break;
      case this.getAssignedUsersApiCallId:
        this.updateAssignedUsers(webResponseJson);
        break;
      case this.getUserDetailsApiCallId:
        this.updateUserDetails(webResponseJson);
        break;
      case this.getPendingTasksApiCallId:
        if (this.state.userType === 'User') {
          this.handlePendingTaskData(webResponseJson);
        }
        break;
      case this.getAllTasksApiCallId:
        if (this.state.userType === 'Agent') {
          this.handleAllTasksData(webResponseJson);
        }
        break;
      default:
        break;
    }
  }
  
  handleErrorResponse(webApiRequestCallId: string, webErrorReponse: any) {
    if (webApiRequestCallId === this.apiDashboardItemCallId) {
      this.setState({
        errorMsg: webErrorReponse,
        loading: false
      });
    }
  }
  
  updateDashboardData(webResponseJson: any) {
    this.setState({
      dashboardData: webResponseJson?.data?.attributes?.sub_attributres,
      totalCandidates: webResponseJson?.data?.attributes?.total_candidates,
      type: webResponseJson?.data?.type,
      errorMsg: "",
      loading: false
    });
  }
  
  updateAssignedUsers(webResponseJson: any) {
    this.setState({
      allAssignedUsers: webResponseJson?.data,
      errorMsg: "",
      loading: false
    });
  }
  
  updateUserDetails(webResponseJson: any) {
    this.setState({
      selectedUser: webResponseJson?.data,
      pendingTasks: webResponseJson?.data.attributes.tasks,
      errorMsg: "",
      loading: false
    });
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  getPercentageData = (taskAcitivities: TaskActivityResData[]) => {
    if(taskAcitivities.length > 0){
      const taskDone = taskAcitivities.filter((task) => task.status === true );
      return Math.round((taskDone.length/taskAcitivities.length)*100);
    }else{
      return 100;
    }
  }

  getStatusLabelText = (status: string) => {
    if(status === "pending"){
      return "Pending";
    }else if(status === "in_progress"){
      return "Inprogress";
    }else if(status === "completed"){
      return "Completed"
    }else{
      return "Not Started";
    }
  }

  handleTasksData = (webResponseJson: TasksDataRes, stateKey: string) => {
    const tasksData: TaskData[] = [];
    if (webResponseJson.data && webResponseJson.data.length > 0) {
      webResponseJson.data.forEach((taskDetail) => {
        tasksData.push({
          id: taskDetail.id,
          status: this.getStatusLabelText(taskDetail.attributes.status),
          lastUpdated: moment(taskDetail.attributes.updated_at).format('MMM DD'),
          title: taskDetail.attributes.title,
          description: taskDetail.attributes.description,
          percentage: this.getPercentageData(taskDetail.attributes.task_activities)
        });
      });
    }
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: tasksData,
      errorMsg: "",
      loading: false
    }));
  };
  
  handlePendingTaskData = (webResponseJson: TasksDataRes) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedUser: null
    }));
    this.handleTasksData(webResponseJson, 'pendingTasks');
  };
  
  handleAllTasksData = (webResponseJson: TasksDataRes) => {
    this.handleTasksData(webResponseJson, 'allTasks');
  };  

  getColorValue = (status: string) => {
    switch(status){
        case "Pending":
            return "#FF0000 ";
        case "Inprogress":
            return "#FD8D02";
        case "Completed":
            return "#019F48"
        case "Not Started":
            return "#9E9E9E";
    }
  }

  getBackColorValue = (status: string) => {
    switch(status){
      case "Pending": 
        return "#FFCFCF";
      case "Inprogress":
        return "#FFE8CB";
      case "Completed":
        return "#D0E1F1";
      case "Not Started":
        return "#E8E8E8";
    }
  }

  getBackgroundColorValue = (status: string) => {
    switch(status){
        case "Pending":
            return "#FFF3F3";
        case "Inprogress":
            return "#FFF8EF";
        case "Completed":
            return "#F5FFFB"
        case "Not Started":
            return "#F5F5F5";
    }
  }

  selectUser = (user: UserData) => {
    this.setState({
      selectedUser: user
    }, () => {
      this.handleScrollToTop();
      this.getUserDetails(user.id);
      this.getAllTasksForAssignedUser(user.id);
    });
  };

  goBackToAgentDashboard = () => {
    this.setState({
      selectedUser: null
    });
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  getAssignedUsersList = async () => {
    const token  = await getStorageData("authToken");
    const webHeader = {
      "content-type": "application/json",
      "token": token,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAssignedUsersApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/assigned_users`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getUserDetails = async (id: string) => {
    const token  = await getStorageData("authToken");
    const webHeader = {
      "content-type": "application/json",
      "token": token,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailsApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${id}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getPendingTasks = async () => {
    const token  = await getStorageData("authToken");
    const userId = await getStorageData('userId')
    const webHeader = {
      "content-type": "application/json",
      "token": token,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPendingTasksApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks?account_id=${userId}&status=${'pending'}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getAllTasksForAssignedUser = async (userId: string|number) => {
    const token  = await getStorageData("authToken");
    const webHeader = {
      "content-type": "application/json",
      "token": token,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTasksApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks?account_id=${userId}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getUserName = async () => {
    let name = await getStorageData('profileData') ?? "";
    if(name){
      name = JSON.parse(name).userName;
    }
    this.setState({ userName: name });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.remainingSeconds !== this.state.remainingSeconds && this.state.remainingSeconds === 120){
      this.setTimer();
    }
  }

  handleCreditMenuValue = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedCreditMenu: event.target.value as string });
  };

  handleCheckScore = (name: string, img: string) => {
    this.setState({
      modalData: {
        isModalOpen: true,
        modalName: name,
        modalType: "ConnectAccount",
        modalImg: img
      }
    });
  };

  getCreditScore = () => {
    if (this.state.otpValue.length < 4) {
      this.setState({ isOTPInvalid: true });
      return;
    }
    const name = this.state.modalData.modalName;
    switch (name) {
      case "TransUnion":
        this.setState({ isOpenTransUnion: true });
        break;
      case "Equifax":
        this.setState({ isOpenEquiefax: true });
        break;
      case "Experian":
        this.setState({ isOpenExperian: true });
        break;
    }
    this.setState({
      otpValue: "",
      modalData: {
        ...this.state.modalData,
        modalType: "AccountVerified"
      },
      isOTPInvalid: false
    });
  };

  handleModalClose = () => {
    this.setState({
      modalData: {
        isModalOpen: false,
        modalImg: "",
        modalName: "",
        modalType: ""
      },
      otpValue: "",
      emailValue: "",
      isEmailInvalid: false
    });
    clearInterval(this.interval);
  };

  handleEmailValue = (event: React.ChangeEvent<{ value: unknown }>) =>
    this.setState({ emailValue: event.target.value as string });

  setTimer = () => {
    this.interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.remainingSeconds === 0) {
          clearInterval(this.interval);
          return { remainingSeconds: 0 };
        }
        return { remainingSeconds: prevState.remainingSeconds - 1 };
      });
    }, 1000);
  };

  getRemainingTime = () => {
    const { remainingSeconds } = this.state;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`
  };

  emailVerify = () => {
    const verified = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
      this.state.emailValue
    );
    if (verified) {
      this.setState({
        emailValue: "",
        isEmailInvalid: false,
        modalData: {
          ...this.state.modalData,
          modalType: "VerifyOTP"
        },
        remainingSeconds: 120
      });
    } else {
      this.setState({ isEmailInvalid: true });
    }
  };

  handleResendOTP = () => this.setState({ remainingSeconds: 120 });

  handleOTP = (value: string) => this.setState({ otpValue: value });

  handleModalSubmit = () => {
    const { modalType } = this.state.modalData;
    if (modalType === "ConnectAccount") {
      this.emailVerify();
    } else if (modalType === "VerifyOTP") {
      this.getCreditScore();
    } else {
      this.handleModalClose();
    }
  };

  getModalButtonText = () => {
    const { modalType } = this.state.modalData;
    if (modalType === "ConnectAccount") {
      return webConfigJSON.sendOTPLabel;
    } else if (modalType === "VerifyOTP") {
      return webConfigJSON.verifyText;
    } else if (modalType === "AccountVerified") {
      return webConfigJSON.okayText;
    }
  };

  getModalHeadingText = () => {
    const { modalType } = this.state.modalData;
    if (modalType === "ConnectAccount") {
      return `${webConfigJSON.connectText} ${this.state.modalData.modalName} ${webConfigJSON.accountText}`
    } else if (modalType === "VerifyOTP") {
      return webConfigJSON.otpAuthenticationLabel;
    } else if (modalType === "AccountVerified") {
      return webConfigJSON.accountVerifiedLabel;
    }
  };

  getModalDescriptionText = () => {
    const { modalType } = this.state.modalData;
    if (modalType === "ConnectAccount") {
      return `${webConfigJSON.connectAccountDescription} ${this.state.modalData.modalName.toLowerCase()} ${webConfigJSON.accountText.toLowerCase()}`;
    } else if (modalType === "VerifyOTP") {
      return webConfigJSON.otpAuthenticationDescription;
    } else if (modalType === "AccountVerified") {
      return `${webConfigJSON.yourText} ${this.state.modalData.modalName} ${webConfigJSON.verifiedSuccessText}`;
    }
  };

  goToReport = (type: string) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationReportMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), {...this.props, type});
    this.send(msg);
  }

  goToAllTasks = (userId: string | number) => {
    const navigateToAllTasks: Message = new Message(
      getName(MessageEnum.NavigationTaskDetailMessage)
    );
    navigateToAllTasks.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigateToAllTasks.addData(getName(MessageEnum.UrlPathParamMessage), userId);
    this.send(navigateToAllTasks);
  }
  // Customizable Area End
}
