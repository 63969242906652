Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.NewPwdBlockAPIEndPoint = "bx_block_forgot_password/reset";
exports.newPasswordLabel = 'New Password';
exports.pleaseEnterValidPasswordText = "Please enter valid password";
exports.passwordRequiredText = "Password is required";
exports.passwordNotMatchError = "Password and confirm password does not match";
exports.passwordRegex = /^[a-zA-Z0-9!@#$%^&*]{8,32}$/;
exports.passwordTextErrorHeading = "Password Must Include:";
exports.capitalCharacterRegex = /[A-Z]/;
exports.passwordTextCharacterError = "At least 8 characters";
exports.smallCharacterRegex = /[a-z]/;
exports.passwordTextCharacterSmallError = "At least one small letter";
exports.passwordTextCapitalError = "At least one capital letter";
exports.numberSymbolRegex = /^(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])/;
exports.passwordTextNumberSymbolError = "At least one number and symbol";
exports.httpPutMethod = "PUT";
exports.invalidEmaidErrorText = "Please enter valid email";
exports.forgotPasswordText = "Forgot Password?";
exports.createNewPasswordText = "Create your new password using your";
exports.emailAddressText = "email address.";
exports.checkEmail = "Check Your Email";
exports.recoverEmailText = "We have sent a password recover link to your email."
exports.okayButtonText = "Okay";
exports.resetYourPasswordText = "Reset Your Password";
exports.submitButtonText = "Submit";
exports.resetPasswordDescriptionText = "Enter the email associated with your account & we will send you a code to reset your password.";
exports.emailDoesNotExist = "Email address not found. Please check and try again.";
exports.emailPlaceholder = "Email";
exports.emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
exports.copyRightText = "© Credit Lift. All Rights Reserved";

exports.resetPasswordEndpoint = "bx_block_forgot_password/forgot_passwords";
exports.newPasswordErrorText = "New password should not match with Current password";
// Customizable Area End