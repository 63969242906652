import React from "react";

// Customizable Area Start
import { createRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Avatar, 
  Badge,
  TextareaAutosize,
  Link,
  CircularProgress,
  Button
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CloseIcon from '@material-ui/icons/Close';
import EmojiPicker from 'emoji-picker-react';  
import { getStorageData } from '../../../framework/src/Utilities';
import { ResolveCondition } from "../../utilities/src/ResolveCondition";
import ChatController, { Props, Messages} from "./ChatController";


// Customizable Area End
// Customizable Area Start
// Customizable Area End
export default class LiveChat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  private fileInputRef = React.createRef<HTMLInputElement>();

  handleFileButtonClick = () => {
    this.fileInputRef.current?.click();
  };

  toggleChat = async () => {
    const userId = await getStorageData('userId');
    const chatRoomId = await getStorageData('chatRoomId')
    if (this.state.isOpen) {
      this.disconnectSocket();
    }

    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        if (this.state.isOpen) {
          this.scrollToLatestMessage();
        }
      }
    );
  };
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { isOpen, adminOnline, messages, newMessage, isEmojiPickerVisible} = this.state;
    return (
      <>
        <button
          onClick={this.toggleChat}
          className="toggle-chat-button"
          data-testid="toggle-chat-button"
          style={{
            height: "50px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "10px",
            paddingBottom: "10px",
            backgroundColor: 'transparent',
            display: this.getDisplayStyle(),
            border: '0',
            alignItems: 'center',
            justifyContent: 'center',
            ...this.getChatButtonStyles()
          }}
        >
          <Typography style={{ fontWeight: "700", ...this.getChatButtonTextStyles()}}>
            Live Chat
          </Typography>
          <ChatBubbleOutlineIcon style={{ color: "#FFFFFF", height: "24px", width: "24px", ...this.getChatButtonIconStyles(), }} />
        </button>

        {isOpen && (
          <Paper elevation={3}
            className="paper PapperToggel"
            style={{
              position: "fixed",
              bottom: 10,
              right: ResolveCondition(this.state.windowWidth <= 767, 15, 30),
              height: ResolveCondition(this.state.windowWidth <= 767, "calc(100vh - 90px)", "calc(100vh - 30%)"),
              width: ResolveCondition(this.state.windowWidth <= 767, "calc(100vw - 30px)", "calc(100vw - 65%)"),
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              zIndex: 222222,
              padding: 2,
              borderRadius: "20px",
            }}
          >
            <style>
              {` 
                .MuiBadge-colorSecondary {
                  background-color: green !important;
                }
                .MuiBadge-colorPrimary {
                  background-color: grey !important;
                }  
              `
              }
            </style>
            <Box
              style={{
                backgroundColor: "#019F48",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "55px",
                borderRadius: "20px",
                zIndex: 10,
              }}
            >
              <Typography style={{ fontSize: "27px", fontWeight: "700", fontFamily: "'Inter', sans-serif" }}>Live Chat</Typography>
              <button
                className="MuiButtonBase-root MuiIconButton-root crossLogo Close-button"
                style={{ color: "#fff", height: "32px", width: "32px", position: 'absolute', top: '12px', right: '5px' }}
                onClick={this.toggleChat}
                data-testid="close-button" 
              >
                <CloseIcon  />
              </button>
            </Box>

            <Box data-testid="admin-status" className="admin-status" sx={webStyles.AdminStatus} >
              <Badge
                variant="dot"
                color={ResolveCondition(adminOnline, "secondary", "primary")}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                overlap="circular"
                className="badge"

              >
                <Avatar style={{ backgroundColor: "#ccc", height: "32px", width: "32px" }} />
              </Badge>
              <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "8px" }}>
                <Typography style={{ fontWeight: "600", color: "black", fontSize: "14px", fontFamily: "Corbel, sans-serif" }}>Admin</Typography>
                <Typography style={{ color: '#059669', fontSize: "9px", fontWeight: "400", fontFamily: "Corbel, sans-serif" }}>
                  {ResolveCondition(adminOnline, "Online", "Offline")}
                </Typography>
              </Box>
            </Box>

            <div ref={this.chatContainerRef} onScroll={this.handleScroll} style={{
              padding: "8",
              paddingTop: "0",
              flex: "1",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column", height: "40%"
            }}>
              <Box id="load-more" >
                {messages.map((message: Messages, index) => {

                  const messageDate = this.formatDate(message.timestamp);
                  const isNewDate = index === 0 || this.formatDate(messages[index - 1].timestamp) !== messageDate;

                  return (
                    <>
                      {isNewDate && (
                        <Box style={{ display: "flex", justifyContent: "center", padding: "8px 0px" }}>
                          <Typography
                            variant="caption"
                            style={{
                              color: "#37657F",
                              fontSize: "12px",
                              fontWeight: "400",
                              fontStyle: "italic",
                              fontFamily: "Corbel, sans-serif"
                            }}
                          >
                            {this.getDateLabel(message.timestamp)}
                          </Typography>
                        </Box>
                      )}

                      <Box
                        key={message.id}
                        sx={ResolveCondition(message.sender === "user", webStyles.MessageContainerTrue, webStyles.MessageContainerFalse)}

                      >
                        <Box
                          sx={ResolveCondition(message.sender === "admin", webStyles.MessageBubbleTrue, webStyles.MessageBubbleFalse)}

                        >
                          {message.message &&
                            <Typography variant="body2" style={{
                              lineHeight: 'normal', fontSize: "12px", fontWeight: "400", fontFamily: "Corbel, sans-serif",
                              color: ResolveCondition(message.sender === "admin", '#000000', '#FFFFFF')
                            }}>
                              {message.message}
                            </Typography>
                          }

                          {Array.isArray(message.file) &&
                            message.file.length > 0 &&
                            message.file.map((fileItem: { url: string }, index: number) => {
                              return (
                                <Link
                                  data-testid={`file-link-${index}`}
                                  key={index}
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    fontFamily: "Corbel, sans-serif",
                                    color: ResolveCondition(message.sender === "admin", '#000000', '#FFFFFF'),
                                    display: "block",
                                  }}
                                  href={fileItem.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {fileItem.url.split('/').pop()}
                                </Link>
                              );
                            })}


                          <Typography
                            style={{
                              display: "block",
                              marginTop: 10,
                              color: ResolveCondition(message.sender === "admin", '#059669', '#FFFFFF'),
                              fontSize: "10px",
                              fontWeight: "400",
                              fontStyle: "italic",
                              textAlign: "end",
                              fontFamily: "Corbel, sans-serif"
                            }}
                          >
                            {this.formatTime(message.timestamp)}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
                <div ref={this.messagesEndRef} />
                {this.state.isLoadingMessages && <div style={{ display: 'flex', justifyContent: 'center' }}> <CircularProgress data-testid="circular-progress" value={10} size={20} style={{ color: '#059669' }} /></div>}
              </Box>
            </div>

          
              <Box sx={webStyles.ChatInputContainer}>

                <input
                  type="file"
                  ref={this.fileInputRef}
                  style={{ display: "none" }}
                  onChange={this.handleFileChange}
                  multiple
                />
                <button
                  className="MuiButtonBase-root MuiIconButton-root file-input-button"
                  data-testid="file-input-button"
                  style={{ color: "#019F48", height: "32px", width: "32px" }}
                  onClick={this.handleFileButtonClick}
                >
                  <AttachFileIcon />
                </button>

                <button
                  className="MuiButtonBase-root MuiIconButton-root file-input-button"
                  style={{ color: "#019F48", height: "32px", width: "32px" }}
                  onClick={this.toggleEmojiPicker}
                >
                  <EmojiEmotionsIcon />
                </button>
                {isEmojiPickerVisible && (
                  <div style={{ position: "absolute", bottom: "80px", left: "5px", zIndex: 333333 }}>
                    <EmojiPicker style={{ height: '280px', width: '300px' }} onEmojiClick={this.handleEmojiClick} data-testid='emoji-picker' className="emoji-picker" />
                  </div>
                )}
                <TextareaAutosize
                  style={{  flex: "1",
                    resize: "none",
                    overflowY: "auto",
                    paddingLeft: "10px",
                    fontSize: "16px",
                    fontWeight: 400,
                    marginLeft: 1,
                    color: "#64748B",
                    maxHeight: "50px",
                    outline: "none",
                    border: "none",
                    fontFamily: "Corbel, sans-serif"}}
                  minRows={1}
                  maxRows={1}
                  placeholder="Type here"
                  value={newMessage}
                  onChange={this.handleInputChange}
                />
                
                <Button
                style={webStyles.SendButton}
                  data-testid="send-button"
                  className="send-button"
                  onClick={this.handleSend}
                  disabled={!newMessage.trim()}
                >

                  <SendIcon />
                </Button>
              </Box>
          </Paper>        
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  AdminStatus: {
    height: 55,
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    padding: "14px 8px",
  },
  MessageContainerTrue: {
    flexDirection: "row-reverse",
    minHeight: 'fit-content', padding: '5px', maxHeight: "fit-content",
    maxWidth: "100%", display: "flex",
    marginBottom: 1,
  },
  MessageContainerFalse: {
    flexDirection: "row",
    minHeight: 'fit-content', padding: '5px', maxHeight: "fit-content",
    maxWidth: "100%", display: "flex",
    marginBottom: 1,
  },
  MessageBubbleTrue: {
    backgroundColor: "#F1F5F9",
    padding: 8,
    maxWidth: "80%",
    borderRadius: 5,
    wordWrap: "break-word",
  },
  MessageBubbleFalse: {
    backgroundColor: "#019F48",
    color: '#FFFFFF', padding: 8,
    maxWidth: "80%",
    borderRadius: 5,
    wordWrap: "break-word",

  }, ChatInputContainer: {
    alignItems: "center",
    border: "1px solid #ddd",
    height: '40px',
    margin: '8px',
    marginBottom: '16px',
    borderRadius: '8px',
    padding: "8px 16px", maxHeight: '58px', display: 'flex', flexDirection: 'row', columnGap: '10px',
  },
  SendButton:{
    color: '#37657F',
    height: "44px",
    width: "44px",
    backgroundColor: 'transparent',
    borderRadius: '8px',
  }
}

// Customizable Area End
