import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {imgPasswordVisible , imgPasswordInVisible, imgCreditLift, welcomeLogo, imgGraph, imgWatch, imgUploadDoc } from "./assets"
import { view_Vertical_line } from "../../email-account-login/src/assets";
import { logoutUser } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  accountStatus: any;
  passwordRules: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  password: string;
  confirmPassword: string;
  passwordError: boolean;
  errorPopUpShow: boolean;
  confirmPasswordError: boolean;
  enableConfirmPasswordField: boolean;
  passwordErrorData: PasswordErrorData;
  samePasswordError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  password: string;
  // Customizable Area End
}

// Customizable Area Start
interface PasswordErrorData {
  characterError: boolean;
  numberSymbolError: boolean;
  smallCharacterError: boolean;
  capitalCharacterError: boolean;
}
// Customizable Area End

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestGoToConfirmationCallId: any;

  //Properties from config
  imgPasswordInVisible : any = imgPasswordInVisible ;
  imgPasswordVisible : any = imgPasswordVisible ;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      passwordRules: "",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      password: '',
      confirmPassword:'',
      passwordError:false,
      errorPopUpShow: false,
      confirmPasswordError:false,
      enableConfirmPasswordField: true,
      samePasswordError: false,
      passwordErrorData: {
        capitalCharacterError: false,
        smallCharacterError: false,
        characterError: false,
        numberSymbolError: false,
      }
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(webApiRequestCallId === this.requestGoToConfirmationCallId){
        if(responseJson.message === "Password Reset Successful!"){
          this.navigateToLogin();
        }else{
          const error = responseJson && responseJson.errors && responseJson.errors[0];
          const tokenError = error?.token;
          const passwordError = error?.password;
          if(tokenError && (tokenError === "Token has Expired" || tokenError === "Invalid token")){
            this.navigateToLogin();
          }else if(passwordError && passwordError === "New password must be different from old password"){
            this.setState({ samePasswordError: true })
          }
        }
      }
    }
     
    


    // Customizable Area End
  }

  // Customizable Area Start
  imgCreditLiftProps = {
    src: imgCreditLift,
  };
  imgwelcomeLogoProps = {
    src: welcomeLogo,
  };
  imgGraphProps = {
    src: imgGraph,
  };
  imgWatcProps = {
    src: imgWatch,
  };
  imgVerticalLineProps = {
    src: view_Vertical_line,
  };
  imgUploadDocProps = {
    src: imgUploadDoc,
  };

  getNewPasswordErrorData = (newPassword: string) => {
    const smallCharacterError = !configJSON.smallCharacterRegex.test(newPassword)
    const numberSymbolError = !configJSON.numberSymbolRegex.test(newPassword)
    const characterError =  newPassword.trim().length < 8;
    const capitalCharacterError = !configJSON.capitalCharacterRegex.test(newPassword);

    const passwordError = capitalCharacterError || smallCharacterError || characterError || numberSymbolError;
      
    this.setState({
      passwordError,
      passwordErrorData: {
        smallCharacterError,
        numberSymbolError,
        characterError,
        capitalCharacterError,
      }
    })

    return passwordError;
  }

  navigateToLogin = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    logoutUser();
  }

  btnSubmit = () =>{
    const passwordError = this.getNewPasswordErrorData(this.state.password);

    if (passwordError) {
      this.setState({ passwordError:true })
    } 
    else if (
      this.state.confirmPassword === null ||
      this.state.confirmPassword.length === 0 ||
      this.state.confirmPassword !== this.state.password
    ) {
      this.setState({ confirmPasswordError:true })
    }
    else{
        const header = {
          "Content-Type": configJSON.forgotPasswordAPiContentType,
          token: this.props.navigation.getParam("navigationBarTitleText")
        };
      
        const attrs = {
            password: this.state.password
        };
      
        const httpBody = attrs
      
        const resetPwdRequestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
        
        this.requestGoToConfirmationCallId = resetPwdRequestMessage.messageId;
        resetPwdRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.NewPwdBlockAPIEndPoint
        );
      
        resetPwdRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        resetPwdRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
      
        resetPwdRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPutMethod
        );
      
        runEngine.sendMessage(resetPwdRequestMessage.id, resetPwdRequestMessage);
      }
   
  }
  setPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.value.includes(' ')){
      this.setState({ password: e.target.value, samePasswordError: false, confirmPasswordError: false });
      this.getNewPasswordErrorData(e.target.value);
    }
  };
  setConfirmPassword = (e: any) => {
    if(!e.target.value.includes(' ')){
      this.setState({
        confirmPassword: e?.target?.value,confirmPasswordError:false
      });
    }
  }; 
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField
    })
  }

  getPasswordText = () => this.state.password ? configJSON.pleaseEnterValidPasswordText : configJSON.passwordRequiredText;

  popUpOpen = () => this.setState({ errorPopUpShow: true})

  popUpClose = () => this.setState({ errorPopUpShow: false})
  
  // Customizable Area End
}