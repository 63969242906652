import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link,
  // Customizable Area Start
  Stepper,
  Step,
  StepLabel,
  StepContent,
  LinearProgress,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { imgDescription, redDocument } from "./assets";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Container style={{padding:'0px',maxWidth:'100%'}} className="topScroll">
      <Loader loading={this.state.isLoading} />
      <BulkUploadWrapper>
        <Box
            className="mainView" width={'100%'}
        >
          <Box
            className="leftView"            >
            <img  {...this.imgCreditLiftProps} style={{ width: 185, height: 53 }} alt="CreditLiftLogo" className="creditLiftLogo" />
            <img  {...this.txtLableStartedProps} style={{ width: 400, height: 45,paddingTop:'5px' }} alt="Lets get you started" className="txtLableStarted" />
            <TypographyWrapper>
                <Typography className="letsGet">Lets get you started</Typography>
                <Typography className="keepInfo">to keep your information</Typography>
                <Typography  className="txtInfo">To keep connected with us please<br/>enter your information.</Typography>
               </TypographyWrapper>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "30px 0px 0px 0px",
                top: "100px",
                marginTop:"20px",
                marginLeft:'0px',
              }}
            >
              <Stepper activeStep={2} orientation="vertical" style={{ paddingBottom: 0,paddingTop: 0, backgroundColor: '#EEFFF8', paddingLeft: '0px', marginTop: '0px',}} className="MuiStepper">
                {this.state.stepperValue.map((label, index) => (
                  <Step key={label} color="success" style={{ height: '25px' }} >
                    <StepLabel color="#019F48">
                      <Typography>{label}</Typography>
                    </StepLabel>
                    <StepContent />
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <Box
             className="rightView"

          >
            <TypographyWrapper>
              <Typography  className="uoploadDocText" >Upload Documents</Typography>
              <Typography className="uoploadText" >Upload your Documents</Typography>
            </TypographyWrapper>
            <Box
                className="uploadDoc"
              border={'4px dashed #019F48'}
              borderRadius={'12px'}
              
            >
              <img  {...this.docUploadProps} alt ="Upload  Document" style={{ width: 97, height: 97, marginTop: 15 }} />
              <Typography style={{ padding: 16, fontSize: 30,fontWeight:500,fontFamily:'Urbanist', color: '#333333', }}>Drag & Drop Your File Here</Typography>
              <ButtonWrapper>
              <Button variant="outlined" component="label" data-test-id="filePicker" className="uploadDocs" style={{ textTransform: 'none',fontFamily:'Urbanist',fontSize:'22px',borderRadius:'12px',width:'auto' }}>
                <input ref={'uploadInputRef'}
                  data-test-id="uploadFile"
                  type="file"
                  accept=".pdf,.jpeg,.jpg,.png"
                  id={'uploadFile'}
                  onChange={this.setFile}
                  name="filename"
                  hidden
                  multiple
                />
                Upload Documents</Button> </ButtonWrapper>
            </Box>
            {(this.state.uploadDocError || this.state.uploadDocFormatError) && <Box data-test-id="error-data" className="error-text">{this.getDocumentErrorMessage()}</Box>}
            {
              this?.state?.documentData?.length>0&&  this?.state?.documentData?.map((data:any,ind:any)=>{
               return(  <React.Fragment key={data.BulkUploading}>
              <Box sx={{
                display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'space-between',maxWidth:'49rem',
                boxShadow: 3, 
                borderRadius: 12, paddingBottom: 10,
                border: '1px solid #CEE1D6',marginLeft:'20px',
                 height: '60px',
                 marginTop:'20px',
              }} color={'rgba(1, 159, 72, 0.18)'}
                bgcolor={'#FFF'}
                boxShadow={'0px 4px 11px 0px rgba(11, 109, 55, 0.07)'}
                data-test-id="selectedFile-1">
                <Box className="document-content">
                  <img src={this.isFileError(data.content_type) ? redDocument : imgDescription} alt="fileicon" style={{ width: 34, height: 34,paddingTop:'9px' }} />
  
                </Box>
                <Box sx={{ alignSelf: 'flex-start', width: '100%',maxWidth:"35rem" }} >
                  <Box sx={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                  <Typography className="text-overflow" style={{ paddingTop: 10,maxWidth:'19rem',paddingLeft:'0px', fontFamily:'Inter', fontStyle:'normal', fontSize: 16, color: '#333333', textAlign: 'left' }}>{`${data?.file_name}`}</Typography>

                  <Typography style={{ paddingTop: 10, paddingLeft:'0px', paddingRight:'0px', fontFamily: 'Inter', fontStyle:'normal', paddingBottom:'10px', fontSize: 16, color: '#333333', textAlign: 'left' }}>{this.isFileError(data.content_type) ? '0' : '100'}%</Typography>
                  </Box>
                  <LinearProgress variant="determinate" value={this.isFileError(data.content_type) ? 0 : 100} className="linearProgressColor" />
                </Box>
                <Box className="icon-button-content" >
                  <span data-test-id="remove-file" onClick={() => this.handleRemoveFile(ind, data.id)}>
                    <HighlightOffOutlinedIcon style={{ cursor: 'pointer', color: 'red' }} />
                  </span>
                  {!this.isFileError(data.content_type) && <img  {...this.greenCrossProps} data-test-id="removefile" alt="greenTickicon"
                  style={{ width: 20, height: 20 }}/>}
                </Box>
  
              </Box>
              {this.isFileError(data.content_type) && <Box className="error-text">{configJSON.fileTypeErrorText}</Box>}
              </React.Fragment>)
              })
            }
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: 'space-between',
                paddingTop: '100px',
                width: '100%',
                marginRight: '0px',
              }}
            >
              <Box sx={{ padding: "20px 20px" }}>
                <Button variant="outlined" data-test-id="back-button" onClick={() => this.props.navigation.goBack()} className="backbtnStyle" style={{ borderRadius: '12px',}}>Back</Button>
              </Box>
              <Box sx={{ padding: "20px 0px" }}>
                <Button variant="contained" className="btnStyle" color="secondary" style={{ backgroundColor: '#019F48', borderRadius: '12px', }} data-test-id="next-button" onClick={this.handleNext}>Next</Button>
              </Box>
            </Box>
          </Box>
        </Box>
        </BulkUploadWrapper>
      </Container>
    </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BulkUploadWrapper = styled(Box)({
  '& .mainView': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "@media only screen and (max-width: 900px)" : {
      display: "flex",
      flexDirection: "column",
    }

  },
  '& .error-text': {
    color: "#F00", 
    margin: "0px 0 0px 20px"
  },
  '& .document-content': {
    paddingLeft: '18px',
    paddingRight: '18px',
    "@media only screen and (max-width: 767px)" : {
      paddingLeft: '10px'
    }
   },
  '& .linearProgressColor': {
    backgroundColor: "#F00 !important",
    borderRadius: "5px",
    height: '9px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: "#019F48 !important",
      height: '9px'
    }
  },
  '& .leftView': {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    top: "100px",
    paddingTop: '56px',
    paddingRight: '30px',
    paddingBottom: '0px',
    paddingLeft: '30px',  
    backgroundColor: '#EEFFF8',
    width:'40%',
    height: 'auto',
"@media only screen and (max-width: 900px)" : {
  paddingTop: '20px',
    paddingRight: '30px',
    paddingBottom: '0px',
    paddingLeft: '30px',
    backgroundColor: '#FFFF !important',
    alignSelf:'center',
    width:'auto'
}

  },
  '& .infoView':{
    position:'relative',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop:'35px',
    "@media only screen and (max-width: 900px)" : {
      backgroundColor: '#FFFF !important',

    }
  },
  '& .text-overflow': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  '& .icon-button-content': {
    display: 'flex',
    gap: '20px',
    paddingTop:'12px',
    paddingLeft:'17px',
    paddingRight: '17px',
    alignItems: 'center',
    width: '64px',
    "@media only screen and (max-width: 767px)" : {
      paddingRight: '10px',
      gap: '10px',
      width: '54px',
    },
  },
  '& .rightView':{
      display: "flex",
      flexDirection: "column",
      paddingTop: '50px',
      paddingRight: '112px',
      paddingBottom: '0px',
      paddingLeft: '72px',
      fontFamily:'Urbanist',
      width:'70%',
      "@media only screen and (max-width: 900px)" : {
        paddingTop: '10px',
          paddingRight: '30px',
          paddingBottom: '0px',
          paddingLeft: '30px',
          backgroundColor: '#FFFF',
          width:'85%'
      }
      
  },
  '& .loginBtnView':{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    alignSelf: 'center',
    padding: "10px 0px",
    fontFamily:'Urbanist',
    "@media only screen and (max-width: 900px)" : {
      fontSize: "12px",
    }
  },
  '& .reserved':{fontSize:'12px',
  textAlign:'center',
  fontFamily:'Urbanist'
},
'& .uploadDoc':{
  display: "flex",
  flexDirection: "column",
  alignItems: 'center',
  justifyContent: 'center',
  borderColor: '#019F48',
   height: 320, marginLeft: 20,
   border:'2px dashed #019F48',
   marginBottom:'20px',
   "@media only screen and (max-width: 900px)" : {
    border:'1px dashed #333',
    marginTop:'10px'
   }
},
'& .creditLiftLogo':{
  width:'200px',
  height:'54px'
},
'& .txtLableStarted':{
  width:'300px',
  paddingTop:'20px',
  paddingBottom:'20px',
},
'& .MuiStepIcon-root.MuiStepIcon-completed':{
  color:'#2aaf66',
  fontFamily:'Urbanist',
},
'& .MuiStepIcon-root.MuiStepIcon-active': {
  color:'#2aaf66',
  fontFamily:'Urbanist',
},
'& .backbtnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  textTransform:'none',
  fontWeight:'bold',
  padding:'8px 40px',
  "@media only screen and (max-width: 900px)" : {
    fontSize:'12px',
    fontFamily:'Urbanist',
}
},
'& .btnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  textTransform:'none',
  color:'#FFFFF',
  fontWeight:'bold',
  padding:'8px 40px',
  "@media only screen and (max-width: 900px)" : {
    fontSize:'12px',
    fontFamily:'Urbanist',
  }
}
});
const TypographyWrapper = styled(Typography)({
  '& .letsGet': {
    display:'none !important',
    "@media only screen and (max-width: 900px)" : {
      fontSize:'20px',
      color:'#292D32',
      display:'block !important',
      fontFamily:'Urbanist',
      fontWeight:500,
      textAlign:'center'
    }
  },
  '& .txtInfo': {
    fontFamily:'Urbanist',
    fontSize:'24px',
    "@media only screen and (max-width: 900px)" : {
      display:'none !important'
    }
  },
  '& .keepInfo': {
    display:'none !important',
    "@media only screen and (max-width: 900px)" : {
      fontFamily:'Urbanist',
      fontWeight:500,
      alignSelf:'center',
      textAlign:'center',
      fontSize:'12px',
      color:'#292D32',
      display:'block !important',
    }
  },
  '& .uploadDocs':{
    fontSize: '22px', color: '#333',
    fontFamily:'Urbanist',
    padding:'10px'
  },
  '& .uoploadDocText': { 
    paddingTop: '0px', fontSize: '38px', color: '#333333', fontWeight:700,
  paddingLeft: 20,
  textTransform:'none',
  fontFamily:'Urbanist',
  "@media only screen and (max-width: 900px)" : {
    fontSize:'22px',
    color:'#292D32',
    display:'block !important',
    fontFamily:'Urbanist',
    fontWeight:500,
  }
 },
 '& .uoploadText':{ 
  paddingTop: '10px', fontSize: '26px', color: '#333333', 
  paddingLeft: '20px', paddingBottom: '20PX' , 
  fontFamily: 'Urbanist',fontWeight:'400',
  lineHeight:'35px',
 "@media only screen and (max-width: 900px)" : {
  fontSize:'12px',
  color:'#333333',
  display:'block !important',
  fontFamily:'Urbanist',
  fontWeight:500,
}
 },
'& .MuiBox-root-18.demo1':{
  marginTop :'10px'

}
});
const ButtonWrapper = styled(Button)({
  "&.MuiButton-text":{
    padding:0,
    borderRadius:'12px'
  }
});
// Customizable Area End