import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

export interface SocialMediaLinkData {
  twitter_link: string;
  linkedin_link: string;
  fb_link: string;
  instagram_link: string;
} 

export interface FAQData {
  id: string;
  question: string;
  answer: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  phone_number: string;
  address: string;
  email_address: string;
  socialMediaLink: SocialMediaLinkData;
  faq: FAQData[];
  message: string;
  nameError: boolean;
  emailError: boolean;
  messageError: boolean;
  isLoggedIn: boolean;
  isModalOpen: boolean;
  loadingContactUs: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getFooterLinkDataCallId: string = "";
  getFAQDataCallId: string = "";
  contactDataCallId: string = "";
  getContactUsDataCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      phone_number: '',
      address: '',
      email_address: '',
      socialMediaLink: {
        instagram_link: '',
        linkedin_link: '',
        fb_link: '',
        twitter_link: '',
      },
      faq: [],
      message: '',
      nameError: false,
      emailError: false,
      messageError: false,
      isLoggedIn: false,
      isModalOpen: false,
      loadingContactUs: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
    this.getFAQData();
    this.getContactUsData();
    this.getFooterLinks();
    const loggedIn = await getStorageData('profileData');
    this.setState({ isLoggedIn: loggedIn !== null })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.setState({ contactUsList: responseJson.data });
        }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.props.navigation.goBack();
        }
        if (apiRequestCallId === this.getFooterLinkDataCallId) {
          const { linkedin_link, fb_link, twitter_link, instagram_link } = responseJson.social_media_links;
          this.setState({
            socialMediaLink: {
              fb_link,
              instagram_link,
              linkedin_link,
              twitter_link,
            }
           });
        }
        if (apiRequestCallId === this.getContactUsDataCallId) {
          if(responseJson.contact_us){
            const { address, phone_number, email} = responseJson.contact_us;
            this.setState({ email_address: email, phone_number, address })
          }
        }
        if (apiRequestCallId === this.getFAQDataCallId) {
          if(responseJson.faqs && responseJson.faqs.length > 0){
            const faqData = responseJson.faqs.map((faq: FAQData) => ({
              id: faq.id,
              question: faq.question,
              answer: faq.answer
            }))
            this.setState({ faq: faqData });
          }else{
            this.setState({ faq: [] })
          }
        }
        if(apiRequestCallId === this.contactDataCallId) {
          this.setState({
            loadingContactUs: false,
          })
          this.setState({ isModalOpen: true, name: '', email: '', message: ''})
        }
      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.addContactApiCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevProps.navigation !== this.props.navigation) {
      document.getElementsByClassName("topScroll")[0].scrollIntoView();
    }
  }

  getContactUsData= () => {
      const webRequestMessage: Message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getContactUsDataCallId = webRequestMessage.messageId;
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsGetUrl
      );
  
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  getFAQData = () => {
      const webRequestMessage: Message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getFAQDataCallId = webRequestMessage.messageId;
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.faqGetUrl
      );
  
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  setMessage = (message: string) => {
    this.setState({ message, messageError: false })
  }

  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  getEmailErrorText = () => {
    if(!this.state.email){
      return configJSON.emailRequired;
    }else{
      return configJSON.emailError;
    }
  }

  getNameErrorText = () => {
    if(!this.state.name){
      return configJSON.nameRequired;
    }else{
      return configJSON.nameError;
    }
  }

  getFooterLinks = () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const footerRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFooterLinkDataCallId = footerRequestMessage.messageId;

    footerRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFooterLinkDataEndpoint
    );

    footerRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    footerRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(footerRequestMessage.id, footerRequestMessage);

  }

  setName = (text: string) => {
    if(text === "" || configJSON.nameChangeRegex.test(text)){
      this.setState({ name: text, nameError: false });
    }
  };

  setEmail = (text: string) => {
    this.setState({ email: text, emailError: false });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false })
  }

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  handleSignUp = () => {
    const signUpMsg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    signUpMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(signUpMsg);
  }

  submit = () => {
    if(!configJSON.nameRegex.test(this.state.name)){
      this.setState({ nameError: true});
    }else if(!configJSON.emailRegex.test(this.state.email)){
      this.setState({ emailError: true });
    }else if(this.state.message.trim() === ""){
      this.setState({ messageError: true });
    }else{
        this.setState({
          loadingContactUs: true,
        })
        const webHeader = {
            "Content-Type": configJSON.contactUsApiContentType,
          };
          const httpBody = {
            "data": {
                "name": this.state.name,
                "email": this.state.email,
                "description": this.state.message
            }
        }
      
          const webRequestMessage: Message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.contactDataCallId = webRequestMessage.messageId;
          webRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.contactUsPostUrl
          );
      
          webRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(webHeader)
          );
      
          webRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      
          webRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
          );
          runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
        
    }
    
  }
  // Customizable Area End
}
