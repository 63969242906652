Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";
exports.testimonialAPIEndpoint = "bx_block_landingpage2/testimonials";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

exports.contactText= "Contact";
exports.usText ="Us";
exports.contactUsSubHeadingText = "It refers to a section on a website, app, or other forms of communication that provides users or visitors with a means to get in touch with the organization or individual behind the platform.";
exports.getInTouchText = "Get in touch with us !";
exports.nameText = "Name";
exports.emailText = "Email";
exports.messageText = "Message";
exports.messagePlaceholderText = "Tell us How can we help you";
exports.cardTitleText = "Our friendly team is always here to support you.";
exports.emailUsText = "Email us";
exports.emailUsDescription = "Chat with us by Email";
exports.phoneText = "Phone";
exports.phoneDetailText = "Mon-Fri from 10:00 AM to 7:00 PM";
exports.officeText = "Office";
exports.visitHQText = "Visit our office HQ";
exports.FAQText = "FAQ";
exports.FAQDescriptionText = "Here is the list of answers given to the questions asked by our users of Credit Lift";
exports.letsGetStartedText = "Let's Get Started";
exports.welcomeDescriptionText1 = "Welcome! I'm here to help you get started on your journey.";
exports.welcomeDescriptionText2 = "Whether you're starting a new journey, embarking on a new venture.";
exports.signUpNowText = "Sign Up Now !";
exports.getFooterLinkDataEndpoint = "bx_block_contact_us/social_media_links";
exports.faqGetUrl = "bx_block_profile/faqs";
exports.contactUsGetUrl = "bx_block_contact_us/contact_us";
exports.aboutUsGetUrl = "bx_block_landingpage2/about_us";
exports.submitText = "Submit";
exports.emailError = 'Enter valid email address';
exports.emailRequired = 'Email is required';
exports.nameRequired = 'Name is required';
exports.messageRequired = "Message is required";
exports.nameError = 'Name must be more than 3 characters';
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.nameChangeRegex = /^[a-zA-Z\s\b]+$/;
exports.nameRegex = /^[a-zA-Z\s]{3,50}$/;
exports.contactUsPostUrl = "bx_block_contact_us/contacts";
exports.formType = 'multipart/form-data';
exports.okayText = "Okay";
exports.doneText = "Done";
exports.modalTitleText = "Request Received";
exports.modalDescriptionText = "Your form request has been received successfully.";
exports.aboutText = "About";
exports.usText = "Us";
exports.ourStoryText = "Our Story";
exports.whoWeAreText = "Who We Are";
exports.ourMission = "Our Mission";
exports.weHelpPeopleWithText = "We Help People With";
exports.testimonialText = "Testimonials";
exports.contactUsText = "Contact Us";

// Customizable Area End