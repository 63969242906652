import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";

interface TaskActivityResData {
  description: string;
  status: boolean;
  title: string;
  id: string;
  task_id: number;
}


interface TaskDetailData {
  lastUpdate: string;
  status: string;
  startDate: string;
  finishDate: string;
  percentage: number;
  taskActivity: TaskActivityData[];
  label: string;
  description: string
}

interface TaskDataRes {
  id: string;
  attributes: {
    title: string;
    status: string;
    start_date: string;
    end_date: string;
    task_activities: TaskActivityResData[];
    updated_at: string;
    description: string;
  }
}

interface TasksDataRes {
  data: TaskDataRes;
}

interface TaskActivityData {
    title: string;
    description: string;
    status: boolean;
    id: string;
    task_id: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string;
  isModalOpen: boolean;
  taskDetail: TaskDetailData;
  id: string;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskDetailViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  updateTaskByIdApiId: any;
  showTaskByIdApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      id: "",
      isModalOpen: false,
      taskDetail: {
        label: "",
        description: "",
        startDate: "",
        finishDate: "",
        lastUpdate: "",
        status: "",
        percentage: 0,
        taskActivity: []
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let webResponseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (webResponseJson && !webResponseJson.errors) {
          if(apiRequestCallId === this.showTaskByIdApiId){
            this.handleTaskData(webResponseJson);
          }else if(apiRequestCallId === this.updateTaskByIdApiId){
            this.handleUpdateTask(webResponseJson);
          }
        }else{
          const tokenError = webResponseJson?.errors && webResponseJson?.errors[0]?.token;
          if(tokenError && tokenError === "Token has Expired"){
            this.goToLoginPage();
          }
        }
      }
      // Customizable Area End
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const id = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ id })

    const token = await getStorageData('authToken');
    this.setState({ token });
    this.getTaskById(id, token);
  }

  getBackColor = (status: string) => {
    switch(status){
      case "Completed":
        return "#D0E1F1";
      case "Not Started":
        return "#E8E8E8";
      case "Pending": 
        return "#FFCFCF";
      case "Inprogress":
        return "#FFE8CB";
    }
  }

  getColor = (status: string) => {
    switch(status){
        case "Pending":
            return "#FF0000 ";
        case "Inprogress":
            return "#FD8D02";
        case "Completed":
            return "#019F48"
        case "Not Started":
            return "#9E9E9E";
    }
  }

  getBackgroundColor = (status: string) => {
    switch(status){
        case "Completed":
            return "#F5FFFB"
        case "Not Started":
            return "#F5F5F5";  
        case "Pending":
            return "#FFF3F3";
        case "Inprogress":
            return "#FFF8EF";
    }
  }

  handleClose = () => this.setState({ isModalOpen: false });

  getPercentage = (taskAcitivitiesData: TaskActivityResData[]) => {
    if(taskAcitivitiesData.length > 0){
      const taskDone = taskAcitivitiesData.filter((task) => task.status === true );
      return Math.round((taskDone.length/taskAcitivitiesData.length)*100);
    }else{
      return 100;
    }
  }

  getStatusLabel = (statusValue: string) => {
    if(statusValue === "pending"){
      return "Pending";
    }else if(statusValue === "in_progress"){
      return "Inprogress";
    }else if(statusValue === "completed"){
      return "Completed"
    }else{
      return "Not Started";
    }
  }

  getFormatedDate = (date: string) => moment(date).format('MMM DD YYYY');

  handleTaskData = (taskData: TasksDataRes) => {
    if(taskData.data){
      const {title, description, start_date, updated_at, end_date, task_activities, status} = taskData.data.attributes;
      this.setState({
        taskDetail: {
          label: title,
          description,
          startDate: this.getFormatedDate(start_date),
          finishDate: this.getFormatedDate(end_date),
          lastUpdate: this.getFormatedDate(updated_at),
          status: this.getStatusLabel(status),
          percentage: this.getPercentage(task_activities),
          taskActivity: task_activities
        }
      })
    }
  }

  handleUpdateTask = (response: any) => {
    if(!response.errors){
      this.setState({ isModalOpen: true });
      this.getTaskById(response.data.attributes.task.id);
    }
  }

  handleActivityClick = (activity: TaskActivityData) => {
    if(!activity.status){
      this.updateTaskById(activity.id, activity.task_id);
    }
  }

  handleBack = async() => {
    const navigateToTaskView: Message = new Message(
      getName(MessageEnum.NavigationTaskDetailMessage)
    );
    navigateToTaskView.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    let userId = await getStorageData('userId');
    navigateToTaskView.addData(getName(MessageEnum.UrlPathParamMessage), userId);
    this.send(navigateToTaskView);
  }

  getTaskById = (id: number, tokenData?: string) => {
    const token = tokenData ?? this.state.token;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showTaskByIdApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskById.replace(":id", id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateTaskById = (id: string, taskId: number) => {
    const token = this.state.token;
    const body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "task_id": taskId,
          "status": true
        }
      }
    };
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskByIdApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTask.replace(':id', id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLoginPage = () => {
    const loginPageMsg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    loginPageMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(loginPageMsg);
  }

  // Customizable Area End
}