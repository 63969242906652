import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  InputLabel,
  styled,
  Modal
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { successImg } from './assets';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import ContactusController, { Props, configJSON } from "./ContactusController";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class AddContactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loadingContactUs} />
        <ModalWrapper open={this.state.isModalOpen}>
              <Box className="modal-container">
                <img className="modal-image" src={successImg} alt="contact-us" />
                <Box className="modal-title">{configJSON.modalTitleText}</Box>
                <Box display='flex' justifyContent='center'>
                  <Box className="modal-description">{configJSON.modalDescriptionText}</Box>
                </Box>
                <Box className="button-wrap">
                  <Button className="okay-button" onClick={this.handleClose}>{configJSON.okayText}</Button>
                  <Button className="done-button" onClick={this.handleClose}>{configJSON.doneText}</Button>
                </Box>
              </Box>
            </ModalWrapper>
        <FormWrapper>
              <Box sx={{alignSelf:'flex-start',}} className="input-wrapper">
                <InputLabelWrapper htmlFor="outlined-adornment-amount">{configJSON.nameText}</InputLabelWrapper>
                  <TextField
                  className="text-field"
                  data-test-id={'txtName'}
                  fullWidth
                  variant="outlined"
                  value={this.state.name}
                  onChange={(e) => this.setName(e.target.value)}
                  placeholder={configJSON.nameText}
                  InputProps={{
                    style: {
                        borderRadius:12
                    }
                }}
                error={this.state.nameError}
                />
                {this.state.nameError && <Box pt={1}>
                  <Typography className="error-text">{this.getNameErrorText()}</Typography>  
                </Box>}
             </Box>
             <Box pt={2} className="input-wrapper">
                <InputLabelWrapper htmlFor="outlined-adornment-amount">{configJSON.emailText}</InputLabelWrapper>
                  <TextField 
                  className="text-field"
                  data-test-id={'txtEmail'}
                  fullWidth
                  variant="outlined"
                  value={this.state.email}
                  onChange={(e) => this.setEmail(e.target.value)}
                  placeholder={configJSON.emailText}
                  InputProps={{
                    style: {
                        borderRadius:12
                    }
                }}
                />
               {(this.state.emailError)&& <Box pt={1}>
                  <Typography className="error-text">{this.getEmailErrorText()}</Typography>
                </Box>}
             </Box>
             <Box pt={2} className="input-wrapper">
                <InputLabelWrapper htmlFor="outlined-adornment-amount">{configJSON.messageText}</InputLabelWrapper>
                  <TextField
                    className="text-field"
                   data-test-id={'txtComments'}
                  fullWidth
                  variant="outlined"
                  value={this.state.message}
                  onChange={(event) => this.setMessage(event.target.value)}
                  rows={4}
                  placeholder={configJSON.messagePlaceholderText}
                  multiline
                  InputProps={{
                    style: {
                        padding: 0,
                        borderRadius:12
                    }
                }}
                />
                {(this.state.messageError)&& <Box pt={1}>
                  <Typography className="error-text">{configJSON.messageRequired}</Typography>
                </Box>}
             </Box>
             <Box  color={'white'} mt={2} className="submit-button-wrapper">
                <Button className="submit-button"   onClick={()=>{
                  this.submit(); 
                      }}
                      data-test-id={'btnSubmit'}
                >{configJSON.submitText}</Button>
                </Box>
              </FormWrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalWrapper = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  '& .modal-container': {
    background: '#FFF',
    borderRadius: '20px',
    padding: '50px',
    textAlign: 'center',
    maxWidth: '750px',
    width: '100%',
    margin: '40px',
    boxSizing: 'border-box',
    "@media only screen and (max-width: 767px)" : {
      padding: '17px 12px 12px',
      margin: '24px'
    }
  },
  '& .modal-image': {
    "@media only screen and (max-width: 767px)" : {
      height: '120px'
    }
  },
  '& .modal-title': {
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginTop: '40px',
    "@media only screen and (max-width: 767px)" : {
      color: '#292D32',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: "18px",
      marginTop: '23px',
    }
  },
  '& .modal-description': {
    color: "#333",
    textAlign: "center",
    fontFamily: "Urbanist",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "33px",
    marginTop: '20px',
    marginBottom: '25px',
    "@media only screen and (max-width: 767px)" : {
      marginTop: '7px',
      opacity: 0.8,
      fontSize: '12px',
      lineHeight: '19px',
      width: '205px',
    }
  },
  '& .button-wrap': {
    display: "flex",
    justifyContent: "center"
  },
  '& .okay-button': {
    width: "190px",
    height: "56px",
    padding: "20px 50px",
    borderRadius: "12px",
    border: "1px solid #9E9E9E",
    background: "#FFF",
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    textTransform: 'capitalize',
    "@media only screen and (max-width: 767px)" : {
      display: 'none'
    }
  },
  '& .done-button': {
    height: "56px",
    padding: "19px 0px 18px 0px",
    borderRadius: "12px",
    border: "1px solid #9E9E9E",
    background: "#FFF",
    width: '100%',
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: 'capitalize',
    "@media only screen and (min-width: 768px)" : {
      display: 'none'
    }
  }
});

const FormWrapper = styled(Box)({
  width: "100%",
  '& .input-wrapper': {
    width: "100%",
    "@media only screen and (max-width: 767px)" : {
      boxSizing: 'border-box',
      paddingLeft: 0
    },
    '& fieldset': {
      borderColor: 'transparent !important'
    }
  },
  '& .error-text': {
    color: "#F00",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  '& .submit-button-wrapper': {
    "@media only screen and (max-width: 767px)" : {
      marginTop: '20px',
      paddingLeft: 0
    }
  },
  '& .submit-button': {
    background:'#333',
    borderRadius: '12px',
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    width: "220px",
    height: "56px",
    textTransform: "none",
    "@media only screen and (max-width: 767px)" : {
      width: '100%',
      fontSize: "16px",
      lineHeight: "normal",
      borderRadius: "8px",
      background: "linear-gradient(0deg, #019F48 0%, #019F48 100%)"
    }
  },
  '& .text-field input, .text-field textarea': {
    display: "flex",
    height: "56px",
    padding: "10px 20px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    border: "1px solid #CEE1D6",
    background: "#FFF",
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "35px",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)" : {
      padding: "17px 8px 20px 8px",
      background: "#F4FAF7",
      fontSize: "16px",
      color: "#363A3F",
      fontWeight: 500,
      lineHeight: "normal",
    }
  },
  '& .text-field textarea': {
    height: "auto"
  },
})

const InputLabelWrapper = styled(InputLabel)({
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "35px",
  "@media only screen and (max-width: 767px)" : {
    paddingBottom: '4px',
    lineHeight: "normal"
  },
})
// Customizable Area End

