// Customizable Area Start
import React from "react";

import {
  Container,
  Button,
 Box, Typography, Stepper, Step, StepLabel, StepContent,Modal
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SignatureCanvas from 'react-signature-canvas';
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1876d2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc004e",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import DocusignIntegrationController, {
  Props,
} from "./DocusignIntegrationController.web";
import { imgVerification } from "./assets";


export default class DocusignIntegration extends DocusignIntegrationController {
  constructor(props: Props) {
    super(props);
  }
   body =()=>{return (
    <div style={{alignItems:'center',justifyContent:'center'}} >
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position:'absolute',top:220,bottom:300,right:0,alignItems:'center',justifyContent:'center',
              left:400,padding:20,alignSelf:'center',borderRadius:20,width:600,height:430
            }}
           style={{backgroundColor:'#fff'}}
          >
              <img  src={imgVerification} alt="verificationicon" style={{ width: 300, height: 180, }} />
              <Typography id="simple-modal-description" className="pending-verification-text"style={{color: "#333",fontFamily: "Urbanist",fontSize: "32px",fontStyle: "normal",fontWeight: 700,lineHeight: "normal",marginTop:"20px",marginBottom:"17px"}}>
              Pending Verification
              </Typography>
              <Typography  style={{textAlign:'center',fontFamily:'Urbanist',fontSize:'19px',marginBottom:'13px'}}>
              We have received your profile details after <br></br>
                profile verification your account will be activated.
              </Typography>
              <Button variant="outlined" onClick={()=>{this.closeModal()}} className="okay-button"style={{  borderRadius: "12px",border: "1px solid #9E9E9E",display: "flex",width: "190px",height: "56px",justifyContent: "center",alignItems: "center",textTransform: "none",color: "#333",fontFamily: "Urbanist",fontSize: "22px",fontWeight: 600,lineHeight: "30px"}} >Okay</Button>
      </Box>
    </div>
  )
};
renderStep = (label:string, index:number) => (
  <StepperSection key={label} color="success" style={{ height: '25px' }} >
    <StepLabel color="#019F48" >
      <Typography>{label}</Typography>
    </StepLabel>
    <StepContent />
  </StepperSection>
)

handleBack = ()=>{
  if(this.signPad) {
    localStorage.setItem("signPad", this.signPad.toDataURL("image/png"));
  }
  this.props.navigation.goBack()
}

canvasProps = {
  canvasProps: { height: 300, width: 710, className: "sigCanvas" },
  backgroundColor: "#F5FFFB",
  penColor: "black",
};

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ backgroundColor: "#FFFFF", display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }} className="topScroll">
          <Container style={{padding:'0px',maxWidth:'100%'}}>
          <Loader loading={this.state.isLoading} />
           <DocumentWrapper> 
            <Box
             className="mainView" width={'100%'}            >
              <Box
                className="leftView"
                style={{ backgroundColor: '#EEFFF8' }}
              >
                <img  {...this.imgCreditLiftProps} alt="CreditLifticon" style={{ width: 185, height: 53,marginBottom:32, }} />
                <img  {...this.txtLableStartedProps} alt="Lets get you started icon" style={{ width: 400, height: 40,marginBottom:30, }} />
                <Typography style={{ fontFamily:'Urbanist',fontSize:'24px', fontWeight:400, }}>To keep connected with us please<br />enter your information.</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "30px 0px 0px 0px",
                    top: "100px"
                  }}
                  bgcolor={'#EEFFF8'}
                >
                  {
                    (this.state.userdata.role_id=='User') ? <Stepper activeStep={4}  orientation="vertical" style={{ paddingBottom: 0,paddingTop: 0, backgroundColor: '#EEFFF8', paddingLeft: '0px', marginTop: '0px',}}
                      className="MuiStepper"
                      >
                      {this.state.stepperValue.map(this.renderStep)}
                    </Stepper>
                    : <Stepper activeStep={1}  orientation="vertical" style={{ paddingBottom: 0,paddingTop: 0, backgroundColor: '#EEFFF8', paddingLeft: '0px', marginTop: '0px',}}
                      className="MuiStepper"
                      >
                      {this.state.stepper2.map(this.renderStep)}
                    </Stepper>
                  }

                </Box>
              </Box>
              <Box
                 className="rightView"
              >
                <Typography style={{ paddingTop: 20, fontSize: 38, color: '#333333', paddingLeft: 20,fontFamily:'Urbanist',fontWeight:700 }}>DocuSign</Typography>
                <Typography style={{ paddingTop: 0, fontSize: 26, color: '#333333', paddingLeft: 20,fontFamily:'Urbanist' }}>Sign the below document</Typography>
                <Typography style={{ paddingTop: 20, paddingLeft: 20, fontSize: 20, color: '#333333',fontFamily:'Urbanist' }}>A digital signature is a cryptographic technique used to verify the authenticity and integrity of digital documents, messages, or transactions. It serves the same purpose as a traditional handwritten signature, providing assurance that the content of a digital file has not been altered or tampered with and that it indeed comes from the claimed sender.</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px 20px",
                    fontFamily:'Urbanist'
                  }}
                >
                  <div style={{ overflow:'hidden', backgroundColor: "#F5FFFB", border: "1px solid #CEE1D6",borderRadius: '12px', position: 'relative' }}>
                    <SignatureCanvas {...this.canvasProps}
                    ref={this.setRef}
                    />
                    <button
                      data-testid="clearBtn"
                      onClick={this.handleCanvasClear}
                      style={{
                        textTransform: 'none',
                        color: '#CF141F',
                        borderRadius: '6px',
                        fontSize: '14px',
                        fontFamily: 'Urbanist',
                        padding: '7px 15px',
                        border: '1px solid #CF141F',
                        backgroundColor: '#FFF',
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px',
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                  {this.state.signatureError && (
                    <div style={{ color: 'red', paddingTop: '5px',fontFamily:"Urbanist",fontSize:"18px",fontWeight:500,position:'sticky'}}>
                    Please sign before proceeding.
                    </div>
                  )}
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingTop: this.state.signatureError ? '20px': '50px',
                      width: '100%',
                      marginRight: '0px',
                    }}
                  >
                    <Box >
                      <Button style={{ textTransform: 'none',borderRadius: '12px', }} className="backbtnStyle" variant="outlined" onClick={this.handleBack}>Back</Button>
                    </Box>
                    <Box >
                      <Button variant="contained" data-testid="completeBtn" className="btnStyle" color="primary" onClick={() =>{this.updateAccount(this.signPad?.toDataURL("image/png") ?? "")}} style={{ backgroundColor: '#019F48',textTransform:'none',borderRadius: '12px', }}>Complete</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>

            </Box>
            </DocumentWrapper>
          </Container>
        </div>
        <div>
          <Modal
            open={this.state.open}
            onClose={this.closeModal}
          >
            {this.body()}
          </Modal>
        </div>
      </ThemeProvider>
    );
  }
}
const StepperSection = styled(Step)({
  "&>svg>circle": {
    color: 'green'
  },
});
const DocumentWrapper = styled(Box)({
  '& .mainView': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "@media only screen and (max-width: 900px)" : {
      display: "flex",
      flexDirection: "column",
    }

  },
  '& .leftView': {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    top: "100px",
    paddingTop: '56px',
    paddingRight: '85px',
    paddingBottom: '0px',
    paddingLeft: '30px',
    backgroundColor: '#EEFFF8',
    width: '27%',
    marginTop: '50px',
"@media only screen and (max-width: 900px)" : {
  paddingTop: '20px',
    paddingRight: '30px',
    paddingBottom: '0px',
    paddingLeft: '30px',
    backgroundColor: '#FFFF !important',
    alignSelf:'center'
}

  },
  '& .infoView':{
    position:'relative',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop:'35px',
    "@media only screen and (max-width: 900px)" : {
      backgroundColor: '#FFFF !important',

    }
  },
  '& .rightView':{
    boxSizing: "border-box",
    paddingRight: '76px',
    paddingBottom: '0px',
    paddingLeft: '40px',
    fontFamily:'Urbanist',
    width:'64%',
    display: "flex",
    flexDirection: "column",
    paddingTop: '40px',
    marginTop:'40px',
      "@media only screen and (max-width: 900px)" : {
        paddingTop: '0px',
          paddingRight: '30px',
          paddingBottom: '0px',
          paddingLeft: '30px',
          backgroundColor: '#FFFF',
      }
      
  },
  '& .loginBtnView':{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    alignSelf: 'center',
    padding: "10px 0px",
    fontFamily:'Urbanist',
    "@media only screen and (max-width: 900px)" : {
      fontSize: "12px",
    }
  },
  '& .reserved':{fontSize:'12px',
  textAlign:'center',
  fontFamily:'Urbanist'
},
'& .backbtnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  textTransform:'none',
  fontWeight:'bold',
  padding:'4px 40px',
  "@media only screen and (max-width: 900px)" : {
    fontSize:'12px',
    fontFamily:'Urbanist',
}
},
'& .btnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  textTransform:'none',
  color:'#FFFFF',
  fontWeight:'bold',
  padding:'4px 40px',
  "@media only screen and (max-width: 900px)" : {
    fontSize:'12px',
    fontFamily:'Urbanist',
  }
}
})
// Customizable Area End
// Customizable Area End
