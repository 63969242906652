import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { Platform } from "react-native";
import { imgCreditLift, imgCross, imgDescription, imgDocUpload, imgGreenCross, txtLableStarted } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export const baseURL = require("../../../framework/src/config.js").baseURL;

interface IUploadedFile {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      files:
        | {
            id: number;
            file_name: string;
            file_url: string;
          }[]
        | null;
      status: string;
    };
  };
  meta: {
    message: string;
  };
}

interface IFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

interface IDownloadResponse {
  jobId: number;
  statusCode: number;
  bytesWritten: number;
}
interface ApiResponse {
  data?: any;
  errors?: any;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  filesWeb: any[];
  files: any[];
  uploadedFiles: IUploadedFile[];
  filesStatus: (undefined | "uploading" | "success" | "failed")[];
  stepperValue:string[];
  fileName: any;
  file: any;
  documentData: any;
  uploadDocError: boolean;
  uploadDocFormatError: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  updateAccountAPIEndPoint: string = "";
  maxFileSize = 5e6;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      filesWeb: [],
      files: [],
      uploadedFiles: [],
      filesStatus: [],
      stepperValue:['Basic Details','Questions','Upload Documents','Review','DocuSign'],
      fileName:'',
      file:'',
      documentData: [],
      uploadDocError: false,
      uploadDocFormatError: false,
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSessionResponse(message: Message) {
    runEngine.debugLog("Message Received", message);
  }

  async handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (errorResponse) {
      // Handle error
    }

    if (apiRequestCallId) {
      if (apiRequestCallId === this.createBulkUploadCallId) {
        this.handleCreateBulkUpload(responseJson);
      } else if (apiRequestCallId === this.getBulkUploadCallId && responseJson !== undefined) {
        this.handleGetBulkUpload(responseJson);
      } else if (apiRequestCallId === this.deleteBulkUploadCallId && responseJson !== undefined) {
        this.setState({isLoading: true});
        this.handleDeleteBulkUpload();
      } if (apiRequestCallId === this.updateAccountAPIEndPoint && responseJson !== undefined) {
        await setStorageData('documents',JSON.stringify(responseJson.data.attributes.file_url));
        this.setState({isLoading: false});
        this.goToNextScreen();
    }
    }
  }

  handleCreateBulkUpload(responseJson: ApiResponse) {
    let filesStatus = this.state.filesStatus;
    if (responseJson && responseJson.data) {
      filesStatus[filesStatus.length - 1] = "success";
    }
    this.setState({ filesStatus });
    this.uploadFile();
  }

  handleGetBulkUpload(responseJson: any) {
    if (!responseJson.errors) {
      this.setState({ uploadedFiles: responseJson });
    }
  }

  handleDeleteBulkUpload() {
    this.setState({isLoading: false});
  }
  componentDidMount = async () => {
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
    this.getToken();
    await this.getDocumentData();
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  getDocumentData = async () => {
    const document = await getStorageData('documents');
    if(document){
      const documentData = JSON.parse(document);
      this.setState({ documentData });
    }
  }

  uploadFile = () => {
    const length = this.isPlatformWeb()
      ? this.state.filesWeb.length
      : this.state.files.length;

    if (length > this.state.filesStatus.length) {
      this.setState({ filesStatus: [...this.state.filesStatus, "uploading"] });
    }
  };

  removeFile = (index: number) => {
    this.setState({
      files: Array.from(this.state.files).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    });
  };

  clearAllFile = () => {
    this.setState({ filesWeb: [], files: [], filesStatus: [] });
  };

  onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempFile: File[] = [];
    if (event.target.files) {
      for (const file of event.target.files) {
        if (file.size <= this.maxFileSize) {
          tempFile.push(file);
        }
      }
    }
    tempFile.length > 0 &&
      this.setState({
        filesWeb: [...this.state.filesWeb, ...tempFile],
      });
  };

  selectFiles = async () => {
    try {
      const pickerResult = await DocumentPicker.pickMultiple({
        presentationStyle: "fullScreen",
        copyTo: "cachesDirectory",
      });
      const tempFile: DocumentPickerResponse[] = pickerResult.filter((result) => {
        if (result.size && result.size > this.maxFileSize) {
          this.showAlert("Alert", result.name + configJSON.fileSizeErrorMsg);
          return false;
        } else {
          return true;
        }
      });

      this.setState({
        files: [...this.state.files, ...tempFile],
      });
    } catch (error) {
      this.showAlert("Alert", configJSON.downloadingFailedMsg);
    }
  };

  getUploadedFiles = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBulkUploadCallId = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBulkUploadEndpoint
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  };

  deleteFile = async(fileGroupId: string|number) => {
    const userId = await getStorageData('userId');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBulkUploadCallId = deleteBulkUploadMsg.messageId;

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${userId}/delete_document/${fileGroupId}`
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
  };

  fileDownloadHandler = (filePath: string, fileName: string) => {
    if (Platform.OS !== "web") {
      try {
        const RNFS = require("react-native-fs");
        const dirPath = this.isPlatformiOS()
          ? RNFS.DocumentDirectoryPath
          : RNFS.DownloadDirectoryPath;
        const toFile = `${dirPath}/${Date.now()}${fileName}`;
        RNFS.downloadFile({
          fromUrl: baseURL + filePath,
          toFile,
        }).promise.then((response: IDownloadResponse) => {
          if (response.statusCode === 200) {
          }
        });
      } catch (error) {
      }
    }
  };
  imgCreditLiftProps = {
    src: imgCreditLift,
  };
  txtLableStartedProps = {
    src: txtLableStarted,
  };

docUploadProps = {
    src: imgDocUpload,
  };
descriptionOutlineProps = {
    src: imgDescription,
  };
  crossProps = {
    src: imgCross,
  };
  greenCrossProps = {
    src: imgGreenCross,
  }

  isFileError = (type: string) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf" ];
    return !allowedTypes.includes(type);
  };

  setFile = async ( e : any ) => {
    this.setState({ isLoading: true });
    const files = e?.target?.files;
    let docData:any = [];
    if(files.length > 0){
        for (const file of files) {
            if(this.isFileError(file.type)){
                let obj = {
                    "file_name": file.name,
                    "content_type": file.type,
                    "file": '',
                };
                docData.push(obj);
            }else{
                await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        let obj = {
                            "file_name": file.name,
                            "content_type": file.type,
                            "file": reader.result,
                        };
                        docData.push(obj);
                        resolve(() => {});
                    };
                });
            }
        }
        const documentData = [...this.state.documentData,...docData];
        this.setState({ documentData, uploadDocError: false, isLoading: false  })
    }
}

  handleRemoveFile = async (index: number|string, id: string) => {
    if(id){
      this.deleteFile(id);
    }
    const { documentData } = this.state;
    documentData.splice(index, 1);
    this.setState({ documentData });
    await setStorageData('documents',JSON.stringify(documentData));
  };

  getDocumentErrorMessage = () => this.state.uploadDocFormatError ? configJSON.fileTypeNotSupportedText : configJSON.uploadDocErrorText;
  
  updateCountry = (obj: { country: string; selectedCountry: string; }) => {
    obj.country = "USA";
    obj.selectedCountry = "USA";
    return obj;
  };

  updateAccount = async(filteredData: {
    file_name: string;
    content_type: string;
    file: string;
  }[]) => {
    this.setState({isLoading: true});
    const basicInfo= await getStorageData('basicInfo');
    const basic_info = this.updateCountry(JSON.parse(basicInfo));
    const questionsAttributes = await getStorageData('questionsAttributes');
      let questionData = [];
      if(questionsAttributes && JSON.parse(questionsAttributes).length > 0){
        questionData = JSON.parse(questionsAttributes).map((question: {
          id: string;
          queId: number;
          question: string;
          answer: string;
        }) => ({
          question_id: question.queId,
          answer: [question.answer]
        }))
      }
    const userdata= await getStorageData('userData');
    const userId = JSON.parse(userdata).setting.account_id;
    const docs = filteredData;
    const data = {
      type: "email_account",
      "attributes": {
        "custom_form_attributes": basic_info,
        "question_answers_attributes": questionData,
        "documents[]": docs,
        "is_completed": false
    }
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAccountAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccountAPIEndPoint}/${userId}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNext = () => {
    if(this.state.documentData.length === 0){
      this.setState({ uploadDocError: true});
      return;
    }
      
    for (const document of this.state.documentData) {
      if (this.isFileError(document.content_type)) {
        return;
      }
    }
    let filteredData = this.state.documentData.filter((item: {
      id?: number;
      file_name: string;
      content_type: string;
      file?: string;
    }) => !item.hasOwnProperty('id'));
    this.updateAccount(filteredData);
  }

  goToNextScreen = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationReviewWebMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
