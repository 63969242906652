Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.weAreText = "We are";
exports.ratedPlatform = "rated platform,";
exports.customerBoostText = "Our customers are boosting their credit scores.";

exports.startNow = "Sign Up Now !";
exports.getStartedText = "Let’s Get Started";
exports.boostYourText = "Boost your";
exports.creditScoreText = "Credit Score";
exports.nowText = "Now!";
exports.increaseYourText = "Increase your";
exports.creditLiftDescriptionText = "Unlock your credit potential with our finance executives,";
exports.findCreditScore = "Find Your Credit Score";
exports.getStartedText = "Get Started";
exports.whyCreditLiftText = "Why Credit Lift";


exports.getLandingPageDataEndpoint = "/bx_block_landingpage2/landing_pages";
exports.getFooterDataEndpoint = "/bx_block_contact_us/social_media_links";
exports.contactUsGetUrl = "bx_block_contact_us/contact_us";

exports.underStandingCredit = "Understanding Your Credit";
exports.beforeEmbarkingText = "Before embarking on your credit score improvement journey.";
exports.reviewingCreditScoreText = "Reviewing Your Credit Report";
exports.creatingBudgetText = "Creating a Budget and Reducing Debt";
exports.establishingPositiveText = "Establishing Positive Payment Habits";
exports.avgCreditScoreText = "Average Credit Score";
exports.partnerText = "Parteners";
exports.activeUserText = "Active Users";
exports.creditLiftWorkText = "How does Credit Lift work?";
exports.downloadCreditAppText = "Download the Credit Lift Mobile App";
exports.downloadCreditAppDescription1 = "To access our services on the go, we highly recommend downloading our mobile app.";
exports.downloadCreditAppDescription21 = "Scan or click to";
exports.downloadCreditAppDescription22 = "Download App on your mobile"
exports.orText = "OR";
// Customizable Area End