import React from "react";
// Customizable Area Start
import { Box, Button, CircularProgress, MenuItem, MenuProps, Select, styled } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../chat/src/LiveChat.web";
import { GaugeChart } from "../../../components/src/GaugeChart.web";
import { backImg, dropdownImg } from "./assets";
import {
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer
} from "recharts";
// Customizable Area End
import ReportController, { 
  Props,
  webConfigJSON
} from "./ReportController.web";


export default class Report extends ReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getHeading = (title: string) => <Box className="title-text">{title}</Box>

  getHeadingTitle = (heading: string) => <Box className="heading-text">{heading}</Box>
  
  getDetailText = (title: string, value: string | number, isDollar?: boolean) => <Box className="detail-wrapper">
    <Box className="detail-title">{title}</Box>
    <Box className="detail-value">{isDollar && webConfigJSON.dollarText}{value}</Box>
  </Box>
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <>
            <NavigationMenu 
                id={this.props.id} 
                navigation={this.props.navigation} 
                screenName="Report"
                isDrawer
                isNotification
                isDashboard
                fullWidthNavigation
            />
            <ReportWrapper>
              <Box className="report-head-section-wrap">
                <Box className="report-head-left-section">
                  <img onClick={this.goToDashboard} data-test-id="back-button" src={backImg} className="back-button" alt="back-button" />
                  <Box className="report-heading">{this.state.type} {webConfigJSON.textReport}</Box>
                  <Box className="updated-text">{webConfigJSON.updateOnText} {this.state.updatedOn}</Box>
                </Box>
                <Box className="report-head-right-section">
                  <Select
                    variant="outlined"
                    IconComponent={() => <img className="dropdown-icon" src={dropdownImg} alt="dropdown" />}
                    className="select"
                    onChange={this.handleChange}
                    value={this.state.cycleValue}
                    MenuProps={menuProps}
                  >
                    {this.state.reportDurationData.length > 0 ? this.state.reportDurationData.map((item) => (
                        <MenuItemSection disableRipple value={item.value} >
                          <Box className={`${item.value === this.state.cycleValue ? 'activate-item' : 'not-activate-item'}`}>{item.label}</Box>
                        </MenuItemSection>
                    )) : 
                        <MenuItemSection disabled disableRipple><Box>{webConfigJSON.dataNotFoundText}</Box></MenuItemSection>
                    }
                  </Select>
                  <Button className="download-report-button desktop" data-test-id="download-report-button" onClick={this.downloadReport}>{webConfigJSON.downloadReportText}</Button>
                </Box>
              </Box>
              <Box className="report-section-wrap">
                <Box className="report-upper-section">
                  <Box className="gauge-chart-section">
                    <img className="credit-score-logo" src={this.getCreditLogo()} alt="" />
                    <Box className="updated-text-mobile mobile">{webConfigJSON.updateOnText} {this.state.updatedOn}</Box>
                    <Box className="gauge-container">
                      <GaugeChart value={800} diff={100} />
                    </Box>
                    <Box className="next-update-container">{webConfigJSON.nextUpdateLabel} {this.state.nextUpdate}</Box>
                    <Button className="download-report-button mobile" data-test-id="download-report-button" onClick={this.downloadReport}>{webConfigJSON.downloadReportText}</Button>
                  </Box>
                  <Box className="credit-history-section">
                    {this.getHeadingTitle(webConfigJSON.creditHistoryLabel)}
                    <ResponsiveContainer width="100%" height={330}>
                      <AreaChart
                        data={this.state.chartData}
                        margin={{
                          right: 0,
                          top: 30,
                          left: -30
                        }}
                      >
                        <defs>
                          <linearGradient id="score" x1="0" y1="0" x2="0" y2="1">
                            <stop stopColor="#FFBA65" />
                            <stop offset="1" stopColor="#FFF8EF" />
                          </linearGradient>
                        </defs>
                        <XAxis
                          tick={{
                            fill: "#333",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            opacity: 0.7,
                            fontFamily: "Urbanist",
                          }}
                          dataKey="name"
                          tickLine={false}
                        />
                        <YAxis
                          ticks={[350,450, 550, 650, 750, 850]}
                          tick={{
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fill: "#333",
                            opacity: 0.7,
                            fontFamily: "Urbanist",
                          }}
                          tickLine={false}
                          domain={[300, 850]}
                        />
                        <CartesianGrid stroke="#F9F4EE" strokeWidth="2px" />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="score"
                          stroke="#FD8D02"
                          strokeWidth="1px"
                          fillOpacity={1}
                          fill="url(#score)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>  
                  </Box>
                  <Box className="report-details-section">
                  <Box className="report-detail-heading">{this.getHeadingTitle(webConfigJSON.reportDetailsText)}</Box>
                  <Box className="report-details-section-wrap">
                    <Box className="account-summary">
                      {this.getHeading(webConfigJSON.accountsSummaryText)}
                      {this.getDetailText(webConfigJSON.textOpenAccount, this.state.reportDetails.open_accounts)}
                      {this.getDetailText(webConfigJSON.textSelfReportedAccount, this.state.reportDetails.self_reported_accounts)}
                      {this.getDetailText(webConfigJSON.textAccountsEverLate, this.state.reportDetails.accounts_ever_late)}
                      {this.getDetailText(webConfigJSON.textClosedAccounts, this.state.reportDetails.closed_accounts)}
                      {this.getDetailText(webConfigJSON.textCollections, this.state.reportDetails.collections)}
                    </Box>
                    <Box className="account-summary">
                      {this.getHeading(webConfigJSON.overallCreditUsageText)}
                      <Box className="credit-usage-section">
                      <div className="circular-progress-wrapper">
                        <CircularProgress
                          className="inner-progress-bar"
                          variant="determinate"
                          thickness={4}
                          value={100}
                        />
                        <CircularProgress
                            className="outer-progress-bar"
                            variant="determinate"
                            thickness={4}
                            value={this.state.percentage}
                        />
                        <Box className="circular-progress-label">{this.state.percentage}%</Box>
                      </div>
                        <Box className="credit-usage-wrapper-section">
                          <Box className="credit-usage-right-section">
                            <Box className="credit-usage green" />
                            <Box>
                              <Box className="detail-title">{webConfigJSON.textCreditAndDebt}</Box> 
                              <Box className="detail-value">{webConfigJSON.dollarText}{this.state.reportDetails.credit_and_debt}</Box>
                            </Box>
                          </Box>
                          <Box className="credit-usage-right-section">
                            <Box className="credit-usage grey" />
                            <Box>
                              <Box className="detail-title">{webConfigJSON.textTotalCredit}</Box>
                              <Box className="detail-value">{webConfigJSON.dollarText}{this.state.reportDetails.total_credit}</Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="account-summary">
                      {this.getHeading(webConfigJSON.debtSummaryText)}
                      {this.getDetailText(webConfigJSON.textCreditCardAndCreditLineDebt, this.state.reportDetails.credit_card_and_credit_line_debt, true)}
                      {this.getDetailText(webConfigJSON.textSelfReported, this.state.reportDetails.self_reported, true)}
                      {this.getDetailText(webConfigJSON.textLoanDebt, this.state.reportDetails.loan_debt, true)}
                      {this.getDetailText(webConfigJSON.textCollectionDebt, this.state.reportDetails.collection_debt, true)}
                      {this.getDetailText(webConfigJSON.textTotalDebt, this.state.reportDetails.total_debt, true)}
                    </Box>
                  </Box>
                </Box>
                </Box>
              </Box>
            </ReportWrapper>
            <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
              <LiveChat />
            </Box>
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const menuProps: Partial<MenuProps> = {
  transformOrigin:{
    vertical: "top",
    horizontal: "left"
  },
  anchorOrigin:{
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  PaperProps: {
      style: {
          marginTop: "4px",
          borderRadius: "4px",
          border: "1px solid rgba(1, 159, 72, 0.18)",
          background: "#FFF",
          boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)"
      }
  },
  MenuListProps:{
    style:{
        padding:0,
    },
  },
}

const ReportWrapper = styled(Box)({
    background: "#F3FAF7",
    padding: "30px 50px",
    boxSizing: "border-box",
    color: "#333",
    fontFamily: "Urbanist",
    fontStyle: "normal",
    fontWeight: 400,
    "@media only screen and (max-width: 767px)": {
        background: "#FFF",
        padding: "24px"
    },
    "& .report-details-section": {
        width: "100% !important",
        "@media only screen and (max-width: 767px)": {
          order: 2,
          position: "relative",
          marginTop: "30px"
      },
    },
    "& .report-detail-heading": {
      position: "relative",
      "@media only screen and (max-width: 767px)": {
        top: "-30px",
        left: 0,
        position: "absolute"
      },
    },
    "& .report-upper-section": {
      display: "flex",
      gap: "30px",
      flexWrap: "wrap",
      "&>div": {
        width: "calc(50% - 15px)",
        borderRadius: "20px",
        border: "1px solid rgba(1, 159, 72, 0.18)",
        background: "#FFF",
        boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
        padding: "30px",
        boxSizing: "border-box",
        "@media only screen and (max-width: 1100px) ": {
          width: "100%"
        },
        "@media only screen and (max-width: 767px) ": {
          width: "100%",
          padding: "12px",
          borderRadius: "10px"
        }
      },
      "@media only screen and (max-width: 767px)": {
        "& .gauge-chart-section": {
          order: 1,
          textAlign: "center"
        },
        "& .credit-history-section": {
          order: 3,
        },
      }
    },
    "& .account-summary": {
      padding: "20px 30px",
      borderRadius: "20px",
      borderLeft: "3px solid #019F48",
      background: "#F5FFFB",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "33%",
      minWidth: "280px",
      "@media only screen and (max-width: 767px) ": {
        width: "100%",
        boxSizing: "border-box",
        padding: "12px",
        gap: 0,
        borderRadius: "10px",
        borderWidth: "2px"
      }
    },
    "& .heading-text": {
      fontSize: "24px", 
      fontWeight: 700,
      lineHeight: "30px",
      "@media only screen and (max-width: 767px) ": {
        color: "#292D32",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px"
      }
    },
    "& .title-text": {
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: "35px",
      "@media only screen and (max-width: 767px) ": {
        fontSize: "12px",
        lineHeight: "normal"
      }
    },
    "& .detail-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .detail-title": {
      fontSize: "18px",
      lineHeight: "35px",
      "@media only screen and (max-width: 767px) ": {
        fontSize: "12px",
        lineHeight: "22px"
      }
    },
    "& .detail-value": {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "30px",
      "@media only screen and (max-width: 767px) ": {
        fontSize: "12px",
        lineHeight: "22px",
        fontWeight: 400 
      } 
    },
    "& .report-details-section-wrap": {
      marginTop: "20px", 
      display: "flex", 
      gap: "30px",
      "@media only screen and (max-width: 1260px) ": {
        flexWrap: "wrap"
      },
      "@media only screen and (max-width: 767px) ": {
        flexDirection: "column",
        gap: "12px",
        marginTop: "12px",
      }
    },
    "& .credit-usage": {
      height: "24px",
      width: "24px",
      borderRadius: "3px",
      marginTop: "5px",
      "@media only screen and (max-width: 767px) ": {
        height: "12px",
        width: "12px"
      }
    },
    "& .green": {
      background: "#019F48"
    },
    "& .grey": {
      background: "#D9D9D9"
    },
    "& .credit-usage-right-section": {
      display: "flex",
      gap: "10px"
    },
    "& .credit-usage-section": {
      display: "flex",
      alignItems: "center",
      height: "100%",
      gap: "40px",
      justifyContent: "center",
      "@media only screen and (max-width: 767px) ": {
        margin: "12px 0 0 12px",
        alignItems: "start",
        justifyContent: "start"
      }
    },
    "& .circular-progress-wrapper": {
      display: "block",
      position: "relative",
      height: "150px",
      width: "150px",
      "@media only screen and (max-width: 1500px) ": {
        height: "125px",
        width: "125px"
      },
      "@media only screen and (max-width: 1400px) ": {
        height: "100px",
        width: "100px"
      },
      "@media only screen and (max-width: 1300px) ": {
        height: "75px",
        width: "75px"
      },
      "@media only screen and (max-width: 767px) ": {
        height: "27px",
        width: "27px"
      },
      "& .MuiCircularProgress-colorPrimary": {
        color: "#D9D9D9"
      },
      "& .inner-progress-bar": {
          position: "absolute",
          top: 0,
          right: 0,
          height: "150px !important",
          width: "150px !important",
          "@media only screen and (max-width: 1500px) ": {
            height: "125px !important",
            width: "125px !important"
          },
          "@media only screen and (max-width: 1400px) ": {
            height: "100px !important",
            width: "100px !important"
          },
          "@media only screen and (max-width: 1300px) ": {
            height: "75px !important",
            width: "75px !important"
          },
          "@media only screen and (max-width: 767px) ": {
            height: "27px !important",
            width: "27px !important"
          },
      },
      "& .outer-progress-bar": {
          color: "#019F48",
          position: "absolute",
          top: 0,
          right: 0,
          transform: "rotate(-135deg) !important",
          height: "150px !important",
          width: "150px !important",
          "@media only screen and (max-width: 1500px) ": {
            height: "125px !important",
            width: "125px !important"
          },
          "@media only screen and (max-width: 1400px) ": {
            height: "100px !important",
            width: "100px !important"
          },
          "@media only screen and (max-width: 1300px) ": {
            height: "75px !important",
            width: "75px !important"
          },
          "@media only screen and (max-width: 767px) ": {
            height: "27px !important",
            width: "27px !important"
          },
          "& circle": {
              strokeLinecap: "round"
          }
      },
      "& .circular-progress-label": {
          position: "absolute",
          width: "150px",
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          left: 0,
          fontSize: "38px", 
          fontWeight: 700,
          "@media only screen and (max-width: 1500px) ": {
            height: "125px",
            width: "125px",
            fontSize: "30px"
          },
          "@media only screen and (max-width: 1400px) ": {
            height: "100px",
            width: "100px",
            fontSize: "24px"
          },
          "@media only screen and (max-width: 1300px) ": {
            height: "75px",
            width: "75px",
            fontSize: "16px"
          },
          "@media only screen and (max-width: 767px) ": {
            height: "27px",
            width: "27px",
            fontSize: "7px",
            fontWeight: 600
          },
      },
  },
  "& .credit-usage-wrapper-section": {
    "@media only screen and (max-width: 767px) ": {
      display: "flex",
      gap: "20px",
      "& .detail-value": {
        fontWeight: 700
      }
    },
  },
  "& .back-button": {
    cursor: "pointer",
    "@media only screen and (max-width: 767px) ": {
      display: "none"
    }
  },
  "& .report-heading": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "30px",
    textTransform: "capitalize",
    "@media only screen and (max-width: 767px)": {
      lineHeight: "normal",
      color: "#FFF"
    }
  },
  "& .updated-text": {
    color: "#9E9E9E",
    fontSize: "18px",
    fontWeight: 500,
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      lineHeight: "18px",
      color: "#FFF"
    }
  },
  "& .updated-text-mobile": {
    color: "#9E9E9E",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "8px 0"
  },
  "& .download-report-button": {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "35px",
    borderRadius: "8px",
    background: "#019F48",
    textTransform: "none",
    width: "185px"
  },
  "& .report-head-section-wrap": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    flexWrap: "wrap",
    marginBottom: "30px",
    "@media only screen and (max-width: 767px)": {
      margin: "-24px -24px 24px",
      padding: "0 24px 24px 24px",
      background: "#019F48"
    }
  },
  "& .report-head-left-section": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      alignItems: "start"
    }
  },
  "& .report-head-right-section": {
    display: "flex",
    gap: "20px",
    alignItems: "center"
  },
  "& .select": {
    display: "inline-flex",
    height: "40px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "4px",
    border: "1px solid #CEE1D6",
    background: "#FFF",
    "&>div": {
      height: "40px",
      display: "flex",
      alignItems: "center",
      padding: "0 30px 0 14px",
      color: "#333",
      fontFamily: "Urbanist",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 600,
    },
    "@media only screen and (max-width: 767px)": {
      height: "34px"
    }
  },
  "& .dropdown-icon": {
    pointerEvents: "none",
    position: "absolute",
    right: "14px"
  },
  "& .report-section-wrap": {
    display: "flex",
    flexDirection: "column",
    gap: "30px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important"
  },
  "& .MuiSelect-select:focus": {
    background: "transparent"
  },
  "& .next-update-container": {
    padding: "10px",
    color: "#FD8D02", 
    fontSize: "16px", 
    fontWeight: 600,
    borderRadius: "4px", 
    background: "rgba(253, 141, 2, 0.08)",
    textAlign: "center",
    "@media only screen and (max-width: 767px)": {
      background: "#FFF",
      fontSize: "14px",
      lineHeight: "22px"
    }
  },
  "& .gauge-container": {
    height: "calc(100% - 132px)",
    margin: "30px 0",
    textAlign: "center",
    minHeight: "225px",
    "@media only screen and (max-width: 767px)": {
      height: "225px",
      margin: "0",
    }
  },
  "& .credit-score-logo": {
    height: "30px",
    "@media only screen and (max-width: 767px)": {
      height: "40px"
    }
  },
  "& .desktop": {
    "@media only screen and (max-width: 767px)": {
      display: "none"
    }
  },
  "& .mobile": {
    "@media only screen and (min-width: 768px)": {
      display: "none"
    }
  }
})

const MenuItemSection = styled(MenuItem)({
  padding: 0,
  "@media screen and (max-width: 600px)" : {
    minHeight: "auto",
  },
  '& .activate-item': {
    color: "#019F48",
    fontWeight: 600
  },
  '& .not-activate-item': {
      color: "#333",
      fontWeight: 500
  },
  '&>div': {
    fontSize: "16px",
    fontFamily: "Urbanist",
    width:"100%",
    padding:"6px 14px",
    background: "#FFF !important",
    lineHeight: "normal",
    '&:hover': {
        background: 'rgba(1, 159, 72, 0.15) !important'
    }
  }
})
// Customizable Area End
