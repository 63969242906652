// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const backIcon = require("../assets/back_icon.svg");
export const dropdownIcon = require("../assets/dropdown.svg");
export const emptyTaskIcon = require("../assets/empty_tasks.svg");
export const inprogressIcon = require("../assets/inprogress.svg");
export const searchNormalIcon = require("../assets/search-normal.svg");
export const tasksCompletedIcon = require("../assets/tasks_completed.svg");
export const tickCircleIcon = require("../assets/tick-circle.svg");
export const timerPauseIcon = require("../assets/timer-pause.svg");
export const totalTasksIcon = require("../assets/total_tasks.svg");
export const trueIcon = require("../assets/true_icon.svg");
// Customizable Area End