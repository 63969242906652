import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from 'react';
import Slider from "react-slick";
import { nextArrow, nextArrowGrey, backArrow, backArrowGrey } from "./assets";

export interface SocialMediaLinksData {
  twitter_link: string;
  instagram_link: string;
  linkedin_link: string;
  fb_link: string;
}

export interface TestimonialData {
  id: string;
  attributes: {
    name: string;
    quote: string;
    photo: string;
  }
}

export interface LandingPageData {
  partners: number;
  active_user_count: number;
  active_users: number;
  how_credit_lift_works: string;
  why_credit_lift: string;
  heading: string;
  description1: string;
  sub_heading: string;
  description2: string;
  testimonials: TestimonialData[];
}

export interface ContactUsData {
  phone: string;
  email: string;
  location: string;
}

export interface AboutUsData {
  description1: string;
  description2: string;
  subHeading: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  socialMediaLinks: SocialMediaLinksData;
  landingPageData: LandingPageData;
  activeContent: number;
  activeSlide: number;
  contactUsData: ContactUsData;
  about_us: AboutUsData[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      socialMediaLinks: {
        twitter_link: "",
        instagram_link: "",
        linkedin_link: "",
        fb_link: "",
      },
      activeContent: 1,
      activeSlide: 1,
      landingPageData: {
        partners: 0,
        active_user_count: 0,
        active_users: 0,
        how_credit_lift_works: '',
        why_credit_lift: '',
        heading: '',
        description1: '',
        sub_heading: '',
        description2: '',
        testimonials: [],
      },
      about_us: [{
        description1: '',
        description2: '',
        subHeading: ''
      }],
      contactUsData: { 
        phone: '', 
        email: '', 
        location: ''
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (requestCallId === this.getFooterLinksDataCallId) {
          const { linkedin_link, fb_link, twitter_link, instagram_link } = responseJson.social_media_links;
          this.setState({
            socialMediaLinks: {
              linkedin_link,
              twitter_link,
              fb_link,
              instagram_link
            }
           });
        } else if (requestCallId === this.getContactUsDataCallId) {
          if(responseJson.contact_us){
            const { address, phone_number, email} = responseJson.contact_us;
            this.setState({ contactUsData: { email, phone: phone_number, location: address } });
          }
        }else if (requestCallId === this.getLandingPageDataCallId) {
          const { partners, active_user_count, testimonials, active_users, landing_page, about_us } = responseJson;
          const { data, heading, description1, sub_heading, description2} = landing_page;
          const testimonialData = testimonials.data.length > 0 ? testimonials.data.map((item: TestimonialData) => ({ id: item.id, attributes: item.attributes })) : [];
          this.setState({ landingPageData: {
            partners,
            active_user_count,
            active_users,
            heading,
            description1,
            sub_heading,
            description2,
            why_credit_lift : data.attributes.why_credit_lift_details.title,
            how_credit_lift_works: data.attributes.how_credit_lift_details.title,
            testimonials: testimonialData,
          }, about_us: this.getAboutUsData(about_us) }, ()=>{
          })
        }
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getFooterLinksDataCallId: string = "";
  getLandingPageDataCallId: string = "";
  getContactUsDataCallId: string = "";
  sliderRef: RefObject<Slider> = React.createRef();
  
  async componentDidMount() {
    super.componentDidMount();
    this.getContactUsData();
    this.getLandingPageData();
    this.getFooterLinkData();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getAboutUsData = (aboutUs: AboutUsData) => {
    let aboutUsData = [{
      description1: '',
      description2: '',
      subHeading: ''
    }]
    if(Array.isArray(aboutUs) && aboutUs.length > 0){
      const { description1, description2, sub_heading } = aboutUs[0];
      aboutUsData = [{
        description1,
        description2,
        subHeading: sub_heading
      }]
    }
    return aboutUsData;
  }

  getLandingPageData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getLandingPageDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageDataEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getContactUsData= () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const contactUsRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactUsDataCallId = contactUsRequestMessage.messageId;
    contactUsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsGetUrl
    );
    contactUsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    contactUsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(contactUsRequestMessage.id, contactUsRequestMessage);
}

  getFooterLinkData = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFooterLinksDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFooterDataEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getNextImage = () => this.state.activeSlide !== this.state.landingPageData.testimonials.length ? nextArrow : nextArrowGrey;

  getNextClassName = () => this.state.activeSlide !== this.state.landingPageData.testimonials.length ? 'pointer' : '';

  getBackClassName = () => this.state.activeSlide > 1 ? 'pointer' : ''

  getBackImage = () => this.state.activeSlide > 1 ? backArrow : backArrowGrey

  handleNextSlide = () => {
    if(this.sliderRef.current && this.sliderRef.current.slickNext && this.state.activeSlide !== this.state.landingPageData.testimonials.length){
      this.sliderRef.current.slickNext();
      this.setState({ activeSlide: this.state.activeSlide + 1});
    }
  }

  handleBackSlide = () => {
    if(this.sliderRef.current && this.sliderRef.current.slickPrev && this.state.activeSlide > 1){
      this.sliderRef.current.slickPrev();
      this.setState({ activeSlide: this.state.activeSlide - 1});
    }
  }

  navigateToSignUp = () => {
    const navigateSignupMessage: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    navigateSignupMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateSignupMessage);
  }

  navigateToLogin = () => {
    const navigateLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    navigateLoginMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateLoginMessage);
  }

  getFontSize = (label: number) => label === this.state.activeContent ? "72px" : "48px"
  
  getClassForLiftWork = (label: number) => label === this.state.activeContent ? "active-credit-lift": "" ;
  // Customizable Area End
}
