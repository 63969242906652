import React from "react";

// Customizable Area Start
import {
  styled,
  Box
} from "@material-ui/core";
import { termsAndConditionCoverImg, viewLineImg, privacyPolicyBg } from "./assets";

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../chat/src/LiveChat.web";
import Footer from "../../../components/src/Footer.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    let isPrivacyPage = window.location.pathname== '/PrivacyPage/web';
    return(
      <TermsAndConditionsWrapper className="topScroll">
        <NavigationMenu 
          id={this.props.id} 
          screenName="TermsAndConditions" 
          fullWidthNavigation={false} 
          navigation={this.props.navigation}
          screenTitle="Terms & Condition"
          isBackButton={true}
        />
        <img className="view-line" alt="viewline" src={viewLineImg} />
        <Box className="terms-conditions-wrapper">
          <Box className="head-container">
            <Box className="terms-header-wrapper">
              <Box className="header-wrap">
                <Box className="terms-conditions-text" data-test-id="terms-condition-heading">
                {isPrivacyPage ? (
                  <>
                  {configJSON.privacytext} <span><br /></span> <span style={{ color: 'black' }}>{configJSON.policytext}</span>
                  </>
                   ) : (
                  <>
                  {configJSON.termsText} <span>{configJSON.andText}</span> {configJSON.conditionsText} &#x1F4C4;
                  
                </>
                )}
                </Box>
                <Box>{this.state.termsAndConditionsData.note}</Box>
              </Box>
              <img 
                src={isPrivacyPage ? privacyPolicyBg : termsAndConditionCoverImg} 
              />
            </Box>
            <img className="cover-image" data-test-id="cover-image" />
            <Box className="title">
              {this.state.termsAndConditionsData.title || ""}
            </Box>
            <Box className="description">
              {this.state.termsAndConditionsData?.description1 || ""}
            </Box>
            <Box className="terms-conditions-points">
              {Object.values(this.state.termsAndConditionsData).length > 0 && this.state.termsAndConditionsData.terms_and_conditions?.map(
                (termsAndCondition) => (
                  <React.Fragment key={termsAndCondition.id}>
                    <Box className="subtitle">
                      {termsAndCondition.term}
                    </Box>
                    <Box className="description">
                      {termsAndCondition.condition}
                    </Box>
                  </React.Fragment>
                )
              )}
            </Box>
            <Box className="title">
              {this.state.termsAndConditionsData.sub_title}
            </Box>
            <Box className="description">
              {this.state.termsAndConditionsData.description2}
            </Box>
            <Box className="copyrightText">
              {configJSON.copyrightText}
            </Box>
          </Box>
        </Box>
        <FooterWrapper>
          <Footer id={this.props.id} navigation={this.props.navigation} contactUsData={this.state.contactUsData} socialMediaLinkData={this.state.socialMediaLinks} />
        </FooterWrapper>
        <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
         <LiveChat />
        </Box>
      </TermsAndConditionsWrapper>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export const TermsAndConditionsWrapper = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  fontFamily: "Urbanist",
  color: "#333",
  fontStyle: "normal",
  marginTop: "-10px",
  paddingTop: "10px",
  background: "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(180deg, #E1FFF1 0%, rgba(251, 253, 255, 0.47) 85.09%)",
  '@media only screen and (max-width: 640px)' : {
    background: "#FFF",
  },
  "& .terms-conditions-wrapper": {
    padding: "80px 150px 0",
    position: "relative",
    zIndex: 1,
    '@media only screen and (max-width: 1000px)' : {
      padding: "40px 50px 0",
    },
    '@media only screen and (max-width: 640px)' : {
      margin: "24px",
      borderRadius: "3.746px",
      border: "0.187px solid rgba(1, 159, 72, 0.18)",
      background: "#FFF",
      boxShadow: "0px 1.49851px 5.6194px 0px rgba(11, 109, 55, 0.10)",
      padding: "16px",
      maxHeight: "calc(100vh - 150px)",
      overflow: "scroll",
      "&::-webkit-scrollbar": { 
        width: "3px" 
      },
      "&::-webkit-scrollbar-track": { 
        borderRadius: "10px" 
      },
      "&::-webkit-scrollbar-thumb": { 
        borderRadius: "53px", background: "#019F48" 
      }
    }
  },
  "& .view-line": {
    position: "absolute",
    zIndex: 0,
    top: "70px",
    width: "100%",
    pointerEvents: "none",
    '@media only screen and (min-width: 700px)' : {
      top: "60px",
    },
    '@media only screen and (min-width: 800px)' : {
      top: "50px",
    },
    '@media only screen and (min-width: 900px)' : {
      top: "40px",
    },
    '@media only screen and (min-width: 960px)' : {
      top: "30px",
    },
    '@media only screen and (min-width: 1440px)' : {
      top: "0",
    },
    '@media only screen and (min-width: 1600px)' : {
      top: "-35px",
    },
    '@media only screen and (max-width: 640px)' : {
      display: "none"
    }
  },
  "& .title": {
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: "35px",
    margin: "90px 0 20px",
    '@media only screen and (max-width: 640px)' : {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      color: "#292D32",
      margin: "0 0 5px 0"
    }
  },
  "& .description": {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#333",
    '@media only screen and (max-width: 640px)' : {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "18px"
    }
  },
  "& .subtitle": {
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "30px",
    margin: "40px 0 20px",
    '@media only screen and (max-width: 640px)' : {
      color: "#292D32",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      margin: "8px 0"
    }
  },
  "& .terms-header-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "60px",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "30px",
    "&>img": {
      width: "350px",
      "@media only screen and (max-width: 1260px)": {
        width: "300px"
      }
    },
    "@media only screen and (max-width: 640px)": {
      display: "none"
    }
  },
  "& .header-wrap": {
    display: "flex",
    flexDirection: "column",
    gap: "30px"
  },
  "& .terms-conditions-text": {
    color: "#019F48",
    fontSize: "56px",
    fontStyle: "italic",
    fontWeight: 900,
    lineHeight: "normal",
    "&>span": {
      color: "#333",
    },
    "@media only screen and (max-width: 1260px)": {
      fontSize: "40px"
    }
  },
  "& .copyrightText": {
    display: "none",
    "@media only screen and (max-width: 640px)": {
      display: "block",
      color: "#019F48",
      marginTop: "20px",    
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px"
    }
  },
  "& .terms-conditions-points": { 
    "@media only screen and (max-width: 640px)": {
      marginBottom: "20px"
    }
  }
});

export const FooterWrapper = styled(Box)({
  "@media only screen and (max-width: 640px)": {
    display: "none"
  }
})
// Customizable Area End
