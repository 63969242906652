import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  styled
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../chat/src/LiveChat.web";
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
// Customizable Area End

import AboutUsController, {
  Props,
  configJSON
} from "./AboutUsController.web";
import { aboutUsBackgroundImg, aboutUsImg, backgroundPattern, fullUnderLine, greenCheckBox, quotationEndImg, quotationStartImg, rightLaptopImg, underLineImg, viewLineImg, whiteCheckBox, whiteUnderlineImg, whoWeAreImg, youngWomalImg } from "./assets";

export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
renderSlick = () => {
    return this.state.testimonials.length > 0 ? this.state.testimonials.map((item) => <Box className="testimonial-section" >
        <Box display="flex" justifyContent="center" style={{gap: "16px"}} position="relative">
          <img height={50} width={50} src={quotationStartImg} />
          <Box className="testimonial-quote">{item.attributes.quote}</Box>
          <Box className="end-comma">
            <img height={50} width={50} src={quotationEndImg} />
          </Box>
        </Box>
        <Box className="testimonial-photo">
          <img height={80} width={80} className="testimonial-user-photo" src={item.attributes.photo} />
          <Box className="testimonial-username">{item.attributes.name}</Box>
        </Box>
      </Box>) : <div></div>;
  }

renderOurStoryDescription = () => this.state.aboutUsData.about_us_records.length > 0 && this.state.aboutUsData.about_us_records[0].about_us_descriptions.length > 1 && this.state.aboutUsData.about_us_records[0].about_us_descriptions.slice(1).map((text) => <Box>{text.description}</Box>);

renderOurMissionPoints = () => this.state.aboutUsData.about_us_records.length > 2 && this.state.aboutUsData.about_us_records[2].about_us_descriptions.length > 0 && this.state.aboutUsData.about_us_records[2].about_us_descriptions.map((item) => <Box>{item.description}</Box>);

weHelpPeoplePoints = () => this.state.aboutUsData.about_us_records.length > 3 && this.state.aboutUsData.about_us_records[3].about_us_descriptions.length > 0 && this.state.aboutUsData.about_us_records[3].about_us_descriptions.map((item) => <Box className="people-help-with-container" display="flex">
<img className="people-help-point" src={whiteCheckBox} alt="white-checkbox" />
<Box className="people-white-description">{item.description}</Box>
</Box>);

getWhoWeAreDescription = () => this.state.aboutUsData.about_us_records.length > 1 && this.state.aboutUsData.about_us_records[1].about_us_descriptions.length > 1 && this.state.aboutUsData.about_us_records[1].about_us_descriptions.slice(1).map((description) => (
  <Box display="flex" alignItems="start" style={{ gap: '10px' }} key={description.id}>
    <img className="who-we-are-description-point" src={greenCheckBox} alt="" />
    <Box className="description-texts">{description.description}</Box>
  </Box>
));
// Customizable Area End
  render() {
    return (
       // Customizable Area Start
        <AboutUsMainWrapper className='topScroll'>
            <Box className="linearBackground" mt={this.state.isLoggedIn ? 0 : -5} />
            <Box pt={ this.state.isLoggedIn ? 0 : 8 }>
                <NavigationMenu 
                  id={this.props.id} 
                  navigation={this.props.navigation} 
                  screenName="AboutUs"
                  screenTitle="Get in touch"
                  isScreenTitleCenter={true}
                  fullWidthNavigation={this.state.isLoggedIn}
                  isBackButton={true}
                />
            </Box>
            <img src={viewLineImg} className="top-line-image" />
            <Box className="main-container">
                <Box display="flex" style={{ gap: '30px' }} justifyContent="space-between" alignItems='center'>
                    <Box>
                      <Box className="about-us-heading">
                        <span className='about-text'>{configJSON.aboutText}</span>&nbsp;
                        <span className='us-text'>{configJSON.usText}</span>&nbsp;
                        &#127970;
                      </Box>
                      <Box style={{ margin: "20px 0", maxWidth: '490px' }}>{this.state.aboutUsData.description1}</Box>
                      <Button data-test-id="contact-us-button" onClick={this.handleContactUs} className="contact-us-button">{configJSON.contactUsText}</Button>
                    </Box>
                    <img src={aboutUsImg} className="about-us-image" alt="about-us-image" />
                </Box>
                <Box>
                    <Box textAlign='center' mt="100px" className="heading-texts our-story-text">{configJSON.ourStoryText}</Box>
                    <Box m="5px 0 10px" textAlign='center'>
                      <img src={fullUnderLine} alt="green-line" />
                    </Box>
                    <Box textAlign="center" className="text-center description-texts">{this.getOurStoryFirstDescription()}</Box>
                    <img className="background-image" src={aboutUsBackgroundImg} alt="about-us-background" />
                    <Box mb="60px" textAlign='center' className="description-texts">
                      {this.renderOurStoryDescription()}
                    </Box>
                </Box>
                <Box display="flex" className="main-content" style={{ gap: '60px' }}>
                    <img className="who-we-are-image" src={whoWeAreImg} alt="who-we-are" />
                    <Box>
                      <Box className="heading-texts head-space">{configJSON.whoWeAreText}</Box>
                      <img src={underLineImg} alt="under-line" />
                      <Box m="10px 0 20px 0" className="description-texts">{this.getWhoWeAreFirstDescription()}</Box>
                      <Box>
                        {this.getWhoWeAreDescription()}
                      </Box>
                    </Box>
                </Box>
                <Box className="main-content" display="flex" justifyContent="space-between" style={{ gap: '60px' }}>
                    <Box>
                      <Box className="heading-texts head-space">{configJSON.ourMission}</Box>
                      <img src={underLineImg} alt="under-line" />
                      <Box mt="10px" className="description-texts">{this.renderOurMissionPoints()}</Box>
                    </Box>
                    <Box className="image-container">
                      <Box />
                    </Box>
                </Box>
                <Box className="people-with-container main-content">
                    <Box className="heading-texts" mb="20px" style={{ color: '#FFF', width: '232px' }}>{configJSON.weHelpPeopleWithText}</Box>
                    <img src={whiteUnderlineImg}  alt="white-under-line" />
                    <Box display="flex" flexWrap="wrap" m="25px 0" style={{ gap: '16px' }}>
                      {this.weHelpPeoplePoints()}
                    </Box>
                </Box>
                <Box>
                    <Box sx={{alignItems:'center',justifyContent:'center',padding:"0 30px 30px"}} textAlign={'center'} color={'#333333'}>
                        <Box className="heading-texts">{configJSON.testimonialText}</Box>
                        <img src={fullUnderLine}  className="creditLiftLogo" style={{height:'3px',width:'80px'}} />
                    </Box>
                    <Box position="relative" className="main-content" maxWidth="100%" overflow="hidden" >
                        <Slider ref={this.slideRef} dots={true} adaptiveHeight={true}>{this.renderSlick()}</Slider>
                        <Box className="buttons-wrap">
                            <img className={this.getBackClass()} src={this.getBackImages()} style={{ paddingRight: "8px"}} data-test-id="next-slide-button" onClick={this.handleBackSlides} />
                            <img className={this.getNextClass()} src={this.getNextImages()} data-test-id="prev-slide-button" onClick={this.handleNextSlides} />
                        </Box>
                    </Box>
                </Box>
                <Box className="getStartrd desktop-view" >
                <Box boxShadow={'0px -23px 34px 0px rgba(0, 0, 0'} >
                  <img src={youngWomalImg} className="young-lady-image"/>
                </Box>
                <Box className="get-started-container" >
                  <Box alignItems='center' justifyContent='center' color='#FFF' >
                    <Box className="get-started-text">{configJSON.letsGetStartedText}</Box>
                    <Box className="get-started-description-text">{configJSON.welcomeDescriptionText1}<br/>{configJSON.welcomeDescriptionText2}</Box>
                  </Box>
                  <Box className="signUpNow" color={'#fff'}>
                  <Button className="signUpButton" data-test-id="sign-up-button" variant="outlined"  onClick={this.handleSignUp}>{configJSON.signUpNowText}</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <FooterWrapper>
                <Footer 
                    id={this.props.id}
                    navigation={this.props.navigation} 
                    socialMediaLinkData={this.state.socialMediaData}
                    contactUsData={this.state.contactUs}
                />
              </FooterWrapper>
              <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
               <LiveChat />
              </Box>
        </AboutUsMainWrapper>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const AboutUsMainWrapper = styled(Box)({
  color: "#333",
  fontWeight: 400,
  fontStyle: 'normal',
  position: 'relative',
  zIndex: 111,
  fontFamily: "Urbanist",
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(180deg, #E1FFF1 0%, rgba(251, 253, 255, 0.47) 10.09%)',
  "@media only screen and (max-width: 767px)":{
    background: '#FFF'
  },
  '& .head-space': {
    marginTop: '40px',
    "@media only screen and (max-width: 767px)":{
      marginTop: 0
    },
  },
  '& .linearBackground': {
    justifyContent:'center',
    alignItems:'center',
  },
  '& .main-content': {
    marginBottom: '100px',
    "@media only screen and (max-width: 1000px)":{
      marginBottom: '75px'
    },
    "@media only screen and (max-width: 767px)":{
      marginBottom: '60px'
    },
  },
  '& .people-help-with-container': {
    width: 'calc(50% - 8px)',
    "@media only screen and (max-width: 1000px)":{
      width: '100%'
    },
  },
  '& .slick-list': {
    padding: "15px 0 60px"
  },
  '& .slick-slide': {
    height: "auto"
  },
  '& .slick-dots li button::before': {
    fontSize: "10px"
  },
  '& .slick-dots': {
    bottom: 0
  },
  '& .slick-dots li button:hover::before': {
    opacity: 0.35,
  },
  '& .slick-dots li.slick-active button::before': {
    opacity: 1,
    color: '#019F48'
  },
  '& .buttons-wrap': {
    bottom: 0,
    right: 0,
    position: "absolute",
    '& .pointer': {
      cursor: 'pointer'
    }
  },
  '& .background-image': {
    margin:'40px 0',
    width: '100%'
  },
  '& .people-white-description': {
    paddingTop: '6px',
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
    "@media only screen and (max-width: 1000px)" : {
      paddingTop: 0,
      fontSize: '14px',
    }
  },
  '& .who-we-are-description-point, .people-help-point': {
    height: '36px',
    width: '36px',
    "@media only screen and (max-width: 1000px)" : {
      height: '30px',
      width: '30px',
    },
    "@media only screen and (max-width: 767px)" : {
      height: '25px',
      width: '25px'
    },
  },
  '& .image-container': {
    width: '500px',
    height: '534px',
    borderRadius: '36px',
    background: '#FD8D02',
    marginRight: '20px',
    '&>div': {
      width: '500px',
      height: '554px',
      backgroundColor: '#FFF7ED',
      borderRadius: '24px',
      background: `url(${rightLaptopImg}), lightgray -144.48px 28px / 157.651% 94.946% no-repeat`,
      margin: '-20px 0 0 20px',
      backgroundSize: '100%',
      "@media only screen and (max-width: 1000px)" : {
        height: '340px',
        width: '300px',
        margin: '-10px 0 0 10px',
        backgroundSize: '103%',
      },
    },
    "@media only screen and (max-width: 1000px)" : {
      height: '330px',
      width: '300px',
      marginRight: '10px',
    },
    "@media only screen and (max-width: 767px)" : {
      display: 'none'
    },
  },
  '& .main-container': {
    padding: '70px 150px',
    "@media only screen and (max-width: 1300px)" : {
      padding: '60px 80px'
    },
    "@media only screen and (max-width: 767px)" : {
      padding: '24px'
    },
  },
  '& .about-us-image': {
    width: '40%',
    maxWidth: '560px',
    "@media only screen and (max-width: 767px)" : {
      display: 'none'
    },
  },
  '& .people-with-container': {
    color: '#FFF',
    borderRadius: '24px',
    backgroundColor: 'rgba(1, 159, 72, 0.97)',
    background: `url(${backgroundPattern}), lightgray 0px 0px / 104.386% 100.023% no-repeat`,
    padding: '40px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '90px'
  },
  '& .about-us-heading': {
    fontSize: "56px",  
    fontWeight: 900,
    '& .about-text': {
      color: "#019F48",
      fontStyle: "italic",
    },
    '& .us-text': {
      color: "#333",
      fontStyle: "italic",
    },
    "@media only screen and (max-width: 1000px)" :{
      fontSize: "30px", 
    },
    "@media only screen and (max-width: 767px)" :{
      fontSize: "26px",
    },
  },
  '& .who-we-are-image': {
    height: '500px',
    "@media only screen and (max-width: 1000px)" :{
      height: "300px"
    },
    "@media only screen and (max-width: 767px)" :{
      display: 'none'
    },
  },
  '& .top-line-image': {
    height: '610px',
    position:'absolute',
    top:-1,
    width:'100%',
    zIndex: -1,
    "@media only screen and (max-width: 767px)" : {
      display: 'none'
    }
  },
  '& .heading-texts': {
    color: "#333", 
    fontSize: "40px", 
    fontWeight: 700,
    "@media only screen and (max-width: 1000px)" :{
      fontSize: '28px'
    },
    "@media only screen and (max-width: 767px)" :{
      fontSize: '20px'
    },
  },
  '& .our-story-text': {
    marginTop: '100px',
    "@media only screen and (max-width: 1000px)" :{
      marginTop: '40px'
    },
    "@media only screen and (max-width: 767px)" :{
      marginTop: '30px'
    },
  },
  '& .description-texts': {
    color: '#333',
    fontSize: '18px',
    lineHeight: '30px',
    "@media only screen and (max-width: 1000px)" :{
      fontSize: '14px',
      lineHeight: '22px',
    },
    "@media only screen and (max-width: 767px)" :{
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  '& .getStartrd':{
    flexDirection:'row',
    display:'flex',
    borderRadius: "24px",
    padding: "40px",
    background: "#FD8D02",
    position: "relative",
  },
  '& .contact-us-button': {
    height: "56px",
    borderRadius: "12px",
    background: "#019F48",
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    width: '200px',
    textTransform: 'capitalize',
    "@media only screen and (max-width: 767px)" : {
      width: '130px',
      height: '35px',
      fontSize: '16px'
    }
  },
  '& .testimonial-section': {
    width:'99% !important',
    boxSizing: "border-box",
    boxShadow: "0px 8px 0px 0px rgba(11, 109, 55, 0.10)",
    padding: "40px 80px",
    textAlign: "center",
    position: "relative",
    borderRadius: "24px",
    border: "1px solid rgba(11, 109, 55, 0.18)",
    opacity: 0.9,
    background: "#FFF",
    "@media only screen and (max-width: 768px)" :{
      padding: "20px"
    },
    '& .testimonial-photo': {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    '& .testimonial-user-photo':{
      marginTop: "18px",
      borderRadius: "50%",
    },
    '& .testimonial-quote': {
      color: "#333",
      fontWeight: 500,
      lineHeight: "44px",
      textAlign: "center",
      fontFamily: "Urbanist",
      fontSize: "28px",
      padding: "18px 0",
      "@media only screen and (max-width: 1000px)" : {
        lineHeight: "30px",
        fontSize: "18px",
      },
    },
    '& .end-comma': {
      alignItems: "end",
      display: "flex",
    },
    '& .testimonial-username': {
      fontSize: "20px",
      fontWeight: 600,
      marginTop: '18px',
      color: "#333",
      textAlign: "center",
      fontFamily: "Urbanist",
      "@media only screen and (max-width: 1000px)": {
        fontSize: "14px"
      }
    },
  },
  '& .get-started-container': {
    display: "flex",
    alignItems: "center",
    paddingLeft: "263px",
    width: "100%",
    justifyContent: 'space-between',
    "@media only screen and (max-width: 1000px)" : {
      paddingLeft: 0,
    },
  },
  '& .get-started-text':{
    color: "#FFF",
    fontStyle: "normal",
    fontFamily: "Urbanist",
    fontSize: "36px",
    lineHeight: "30px",
    fontWeight: 700,
    textShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
    "@media only screen and (max-width: 1200px)" : {
      lineHeight: "25px",
      fontSize: "25px",
    },
    "@media only screen and (max-width: 700px)" : {
      lineHeight: "18px",
      fontSize: "16px",
    }
  },
  '& .get-started-description-text': {
    color: "#FFF",
    textShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
    fontStyle: "normal",
    marginTop: "26px",
    fontFamily: "Urbanist",
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 500,
    "@media only screen and (max-width: 1200px)" : {
      marginTop: "10px",
      lineHeight: "25px",
      fontSize: "14px",
    },
    "@media only screen and (max-width: 700px)" : {
      fontSize: "12px",
      marginTop: "6px",
      lineHeight: "15px",
    }
  },
  '& .signUpButton': {
    borderRadius: "12px",
    height: "56px",
    lineheight: "56px",
    textTransform: "capitalize",
    color: "#333",
    border: "1px solid #9E9E9E",
    background: "#FFF",
    display: "flex",
    width: "185px",
    fontFamily: "Urbanist",
    fontWeight: 600,
    fontSize: "20px",
    "@media only screen and (max-width: 900px)" : {
      lineHeight: '50px',
      height:'50px',
      fontSize:'14px',
      width:'125px',
    },
    "@media only screen and (max-width: 500px)" : {
      width:'100px',
      fontSize:'10px',
    }
  },
  '& .young-lady-image': {
    position: "absolute",
    bottom: '-9px',
    left: 0,
    height: "255px",
    width: "277px",
    "@media only screen and (max-width: 1000px)" : {
      display: "none"
    },
  },
});

const FooterWrapper = styled(Box)({
  display: "block",
  "@media only screen and (max-width: 767px)": {
    display: "none"
  },
});
// Customizable Area End