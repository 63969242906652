Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.helloText = "Hello,";
exports.tasksText = "Tasks";
exports.aboutUsText = "About Us";
exports.invoicesText = "Invoices";
exports.contactUsText = "Contact Us";
exports.myProfileText = "My Profile";
exports.myMeetingsText = "My Meetings";
exports.logOutText = "Log Out";
exports.logoutTitleText = "Logout";
exports.logoutConfirmationText = "Are you sure you want to logout now?";
exports.confirmText = "Confirm";
exports.cancelText = "Cancel";
exports.loginText = "Login";
exports.signupText = "Sign Up";
exports.helloTxt = "Hello";
exports.termsConditionsText = "Terms & Conditions";
exports.dashboardText = "Dashboard";
exports.userDataGettingUrl = "account_block/show_profile";
exports.getNotificationEndpoint = "bx_block_notifications/notifications";

// Customizable Area End