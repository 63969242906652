Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelTitleText = "Bulk Upload";
exports.uploadBtnLabel = "Upload";
exports.clearFileBtnLabel = "Clear files";
exports.submitBtnLabel = "Submit";
exports.getUploadedFileLabel = "Get All Uploaded File";
exports.idLabel = "ID:";
exports.statusLabel = "Status: ";
exports.deleteBtnLabel = "Delete";
exports.removeBtnLabel = "Remove";
exports.downloadBtnLabel = "Download";
exports.apiContentType = "application/json"
exports.createContentType = "multipart/form-data"
exports.createBulkUploadEndpoint = "bulk_uploading/attachments";
exports.createBulkUploadMethod = "POST";
exports.uploadingSuccessMsg = "File uploaded Successfully!"
exports.getBulkUploadEndpoint = "bulk_uploading/attachments";
exports.getBulkUploadMethod = "GET";
exports.deleteBulkUploadSingleEndpoint = "bulk_uploading/attachments/delete_attachment";
exports.deleteBulkUploadEndpoint = "bulk_uploading/attachments";
exports.deleteBulkUploadMethod = "DELETE";
exports.deletedSuccessMsg = "Attachment file deleted successfully!";
exports.fileTypeErrorText = "This file type is not supported. You can only upload jpg, jpeg, png, and pdf files.";
exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 5MB!";
exports.downloadedSuccessMsg = "file has been downloaded! File Path: ";
exports.downloadingFailedMsg = "Something went wrong!";
exports.somethingWentWrongMsg = "Something went wrong!";
exports.fileTypeNotSupportedText = "File type is not supported";
exports.uploadDocErrorText = "This field is required.";
exports.loginAlertMessage = "Please login first.";
exports.updateAccountAPIEndPoint = "account_block/accounts";
exports.validationApiContentType = "application/json";
exports.apiMethodTypeAddDetail = "PUT";


// Customizable Area End