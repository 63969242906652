Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.getContriesUrl = "/account_block/countries"
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.transUnionText = "TransUnion";
exports.equifaxText = "Equifax";
exports.experianText = "Experian";
exports.checkYourText = "Check your";
exports.creditScoreText = "credit score";
exports.makeTheRightCreditDecisionsWithText = "Make the right credit decisions with";
exports.increaseYourCreditScoreText = "and Increase your Credit score.";
exports.checkScoreLabel = "Check Score";
exports.pendingTasksLabel = "Pending Tasks";
exports.allTasksLabel = "All Tasks";
exports.creditHistoryLabel = "Credit History";
exports.updateOnText = "Updated On";
exports.nextUpdateLabel = "Next Update will be available on";
exports.viewReportLabel = "View Report";
exports.lastUpdatedLabel = "Last updated:";
exports.impactOnScoreLabel = "Impact on Score";
exports.percentSymbol = "%";
exports.connectText = "Connect";
exports.accountText = "Account";
exports.connectAccountDescription = "Enter your valid username/email and password to connect your";
exports.emailFieldLabel = "Username/Email ID";
exports.emailFieldErrorText = "Invalid Username/Email ID";
exports.sendOTPLabel = "Send OTP"; 
exports.otpAuthenticationLabel = "OTP Authentication";
exports.otpAuthenticationDescription = "Enter the OTP sent to your associated email and verify";
exports.timeLeftText = "Time left";
exports.didNotReceiveOtpText = "Did not receive the OTP?";
exports.resendText = "Resend";
exports.verifyText = "Verify";
exports.textHey = "Hey";
exports.textYourCreditScoreFor = "Your credit score for"
exports.accountVerifiedLabel = "Account Verified";
exports.yourText = "Your";
exports.verifiedSuccessText = "account has been verified successfully."
exports.okayText = "Okay";
exports.otpFieldErrorText = "Invalid OTP";
exports.reportDetailsText = "Report Details";
exports.accountsSummaryText = "Accounts Summary";
exports.overallCreditUsageText = "Overall Credit Usage";
exports.debtSummaryText = "Debt Summary";
exports.downloadReportText = "Download Report";
exports.dollarText = "$";
exports.textOpenAccount = "Open account";
exports.textSelfReportedAccount = "Self reported accounts";
exports.textAccountsEverLate = "Acconts ever late";
exports.textClosedAccounts = "Closed accounts";
exports.textCollections = "Collections";
exports.textCreditCardAndCreditLineDebt = "Credit card and credit line debt";
exports.textSelfReported = "Self reported";
exports.textLoanDebt = "Loan debt";
exports.textCollectionDebt = "Collection debt";
exports.textTotalDebt = "Total debt";
exports.textCreditAndDebt = "Credit and Debt";
exports.textTotalCredit = "Total Credit";
exports.textReport = "Report";
exports.myProfileText = "My Profile";
exports.createdOnText = "Created on";
exports.editProfileText = "Edit Profile";
exports.SaveProfileText = "Save Profile";
exports.changePasswordText = "Change Password";
exports.disableAccountText = "Disable Account";
exports.AddressDetailsText = "Address Details";
exports.UpdatePasswordText = "Update Password";
exports.otherDetailsText = "Other Details";
exports.maleText = "Male";
exports.femaleText = "Female";
exports.changePasswordDescription = "Enter your new password to change it and make sure it does not match the previous password.";
exports.changePassword = "Are you sure you want to change your password?";
exports.disableAccount = "Are you sure you would like to disable your account?";
exports.disableAccountBrief = "Your account will be disabled temporarily. You can activate your account again with your login credentials."
exports.userDataGetUrl = "account_block/show_profile";
exports.userDataUpdateUrl = "account_block/profile_update";
exports.cancelText = "Cancel";
exports.confirmText = "Confirm";
exports.putMethod = "PUT";
exports.changePasswordUrl = "account_block/change_password";
exports.disableAccountUrl = "account_block/account_disable";
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
exports.nameChangeRegex = /^[a-zA-Z\s\b]+$/;
exports.nameRegex = /^[a-zA-Z\s]{3,50}$/;
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.getStatesUrl = "account_block/states?country_code=:state";
exports.notificationEndpoint = "bx_block_settings/settings";
exports.getNotificationEndpoint = "bx_block_settings/settings/user_setting";
exports.matchCurrentPasswordEndpoint = "account_block/check_current_password";
exports.currentPasswordErrorText = "Current password is required";
exports.newPasswordErrorText = "New password should not match with Current password";
exports.confirmPasswordErrorText = "Confirm password should match with New password";
exports.currentPasswordIncorrectErrorText = "Current password is incorrect";
exports.newPasswordRequiredText = "New Password is required";
exports.newPasswordInvalidText = "Please enter valid password";

exports.passwordErrorHeadingText = "Password Must Include:";
exports.passwordCharacterErrorText = "At least 8 characters";
exports.passwordCharacterSmallErrorText = "At least one small letter";
exports.passwordCapitalErrorText = "At least one capital letter";
exports.passwordNumberSymbolErrorText = "At least one number and symbol";
exports.stateLabel = "State";
exports.notificationLabel = "Notification";

exports.smallCharacterRegex = /[a-z]/;
exports.numberSymbolRegex = /^(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])/;
exports.capitalCharacterRegex = /[A-Z]/;

exports.streetErrorText = "Street is required";

exports.houseNumberErrorText = "Building/House No is required";
exports.countryErrorText = "Country is required";
exports.stateErrorText = "State is required";
exports.dobErrorText = "DOB is required";
exports.genderErrorText = "Gender is required";
exports.nameErrorText = "Name is required";
exports.emailErrorText = "Email is required";
exports.phoneErrorText = "Phone No is required";
exports.phoneErrorMaximumText = "Phone number should have a minimum of [10] digits";
exports.phoneErrorNotExceedText = "Phone number should not exceed [10] digits";
exports.minCharacterNameErrorText = "Name should have atleast 3 characters";
exports.maxCharacterNameErrorText = "Name should not exceed 50 characters";
exports.dataNotFoundText = "No data available";
exports.fileErrorText = "File type is not supported"
// Customizable Area End