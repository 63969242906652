Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.myMeetingsText = "My Meetings";
exports.schedulingMeetingText = "Schedule Meeting";
exports.scheduledOnText = "Scheduled on: ";
exports.scheduleYourMeetingText = "Schedule your Meeting";
exports.scheduleDescriptionText = "Enter your valid details and schedule the meeting with our admin team to clear all your queries.";
exports.scheduleText = "Schedule";
exports.dateText = "Date";
exports.datePlaceholder = "Select date for the meeting";
exports.timeText = "Time";
exports.timePlaceholder = "Select time for the meeting";
exports.agendaText = "Agenda";
exports.agendaPlaceholder = "Enter the agenda for the meeting";
exports.getSlotDetailsApiId = "bx_block_scheduling/slots";
exports.getMettingDetailAPIEndpoint = "bx_block_scheduling/schedule_meetings";
exports.scheduleMeetingAPIEndpoint = "bx_block_scheduling/schedule_meetings";
// Customizable Area End