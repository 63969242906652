import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../chat/src/LiveChat.web";
import { viewLineImg, groupImg, underLineImg, imgPhoneIcon, imgmapIcon, imgEmailIcon, arrowDownImg, youngWomalImg } from "./assets";
import AddContactUs from './AddContactus.web';
import Footer from "../../../components/src/Footer.web";
// Customizable Area End

import ContactusController, { Props, configJSON } from "./ContactusController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} disableGutters className='topScroll'>
          <> 
          <ContactUsWrapper>
            <Box className="linearBackground" mt={this.state.isLoggedIn ? 0 : -5} >
             <Box pt={this.state.isLoggedIn ? 0 : 8}>
                <NavigationMenu id={this.props.id} navigation={this.props.navigation} 
                  screenName="Contactus"
                  screenTitle="Get in touch"
                  isScreenTitleCenter={true}
                  isBackButton={true}
                  fullWidthNavigation={this.state.isLoggedIn}
                />
            </Box>
            <img src={viewLineImg} className="top-line-image" />
            <Box  className="landingMainBox">
              <Box className="desktop-view">
                  <Box className="contact-text">{configJSON.contactText} <span>{configJSON.usText}</span> 📞</Box>
                  <Typography className="contact-description-text">{configJSON.contactUsSubHeadingText}</Typography>
              </Box>
              <Box>
              <img  src={groupImg}  className="contact-us-image" />
              </Box>
      
            </Box>

            <Box className="desktop-view" textAlign="center">
                <Box className="get-touch-text">{configJSON.getInTouchText}</Box>
                <img src={underLineImg} className="creditLiftLogo" style={{height:'3px',width:'80px'}}/>
            </Box>
            <Box className="get-in-touch-wrapper">
              <Box className="desktop-view information-content" >
                <Box className="card-title-text">{configJSON.cardTitleText}</Box>
                <Box className="card-content">  
                  <Box sx={{flexDirection:'row',alignItems:'center',
                display:'flex'}} color={'white'}>
                  <img src={imgEmailIcon} className="card-image" />
                  <Box>
                     <Typography className="card-label-text">{configJSON.emailUsText}</Typography>
                     <Typography className="card-description-text">{configJSON.emailUsDescription}</Typography>
                     <a className="no-decoration" href={`mailto:${this.state.email_address}`}><Typography className="card-value-text">{this.state.email_address}</Typography></a>
                  </Box>
                </Box>
                <Box sx={{flexDirection:'row',justifyContent:'space-betwwen',
                display:'flex'}} color={'white'}>
                <img src={imgPhoneIcon} className="card-image" />
                  <Box>
                     <Typography className="card-label-text">{configJSON.phoneText}</Typography>
                     <Typography className="card-description-text">{configJSON.phoneDetailText}</Typography>
                     <a className="no-decoration" href={`tel:${this.state.phone_number}`}><Typography className="card-value-text">{this.state.phone_number}</Typography></a>
                  </Box>
                </Box>
                <Box 
                sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-betwwen',display:'flex'}}
               >
                 <img src={imgmapIcon} className="card-image" />
                  <Box>
                     <Typography className="card-label-text">{configJSON.officeText}</Typography>
                     <Typography className="card-description-text">{configJSON.visitHQText}</Typography>
                     <Typography className="card-value-text">{`${this.state.address}`}</Typography>
                  </Box>
                </Box>
                </Box>
              </Box>
             <AddContactUs navigation={this.props.navigation} id={""}/>
              
            </Box>
            <Box className="faq-wrapper">
                  <Box className="faq-heading">{configJSON.FAQText}</Box>
                  <img  src={underLineImg}  className="faq-underline" />
                  <Box className="faq-subtitle">{configJSON.FAQDescriptionText}</Box> 
                  <Box className="faq-container">
                    {this.state.faq.length > 0 && this.state.faq.map((faq) => (
                      <Accordion className="accordion-wrap" key={faq.id}>
                        <AccordionSummary
                          expandIcon={<img src={arrowDownImg} alt="more-icon" />}
                        >
                          <Box className="accordion-title">{faq.question}</Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="accordion-description">{faq.answer}</Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
                
              <Box className="getStartrd desktop-view">
                <Box boxShadow={'0px -23px 34px 0px rgba(0, 0, 0'}>
                  <img src={youngWomalImg} className="young-lady-image" />
                </Box>
                <Box className="get-started-container">
                  <Box  sx={{alignItems:'center',justifyContent:'center'}} color={'white'}>
                    <Box className="get-started-text" >{configJSON.letsGetStartedText}</Box>
                    <Box className="get-started-description-text">{configJSON.welcomeDescriptionText1}<br/>{configJSON.welcomeDescriptionText2}</Box>
                  </Box>
                  <Box className="signUpNow" color={'#fff'}>
                  <Button className="signUpButton" variant="outlined"  onClick={this.handleSignUp}>{configJSON.signUpNowText}</Button>
                  </Box>
                </Box>
              </Box>
              
              </Box>
              <FooterWrap>
                <Footer 
                    id={this.props.id}
                    navigation={this.props.navigation} 
                    socialMediaLinkData={this.state.socialMediaLink}
                    contactUsData={{ phone: this.state.phone_number, email: this.state.email_address, location: this.state.address}}
                />
              </FooterWrap>
              </ContactUsWrapper>
          </>
        </Container>
        <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
         <LiveChat />
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ContactUsWrapper = styled(Box)({
  color: "#333",
  fontFamily: "Urbanist",
  fontWeight: 400,
  fontStyle: 'normal',
  '& .no-decoration': {
    textDecoration: "underline",
    textDecorationColor: "#FFF",
    textUnderlineOffset: "5px"
  },
  '& .linearBackground': {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(180deg, #E1FFF1 0%, rgba(251, 253, 255, 0.47) 30.09%)',
    alignItems:'center',
    justifyContent:'center',
    "@media only screen and (max-width: 767px)" : {
      background: '#FFF'
    }
  },
  '& .contact-text': {
    color: "#019F48",
    fontFamily: "Urbanist",
    fontSize: "56px",
    fontStyle: "italic",
    fontWeight: 900,
    '&>span': {
      color: '#333'
    },
    "@media only screen and (max-width: 1000px)" : {
      fontSize: '35px'
    },
  },
  '& .card-title-text': {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    marginBottom: "25px"
  },
  '& .young-lady-image': {
    height: "255px",
    width: "277px",
    position: "absolute",
    bottom: '-9px',
    left: 0,
    "@media only screen and (max-width: 1000px)" : {
      display: "none"
    },
  },
  '& .contact-description-text': {
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    marginTop: "20px"
  },
  '& .get-touch-text': {
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "40px",
    fontWeight: 700
  },
  '& .card-label-text': {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "30px"
  },
  '& .card-description-text': {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px"
  },
  '& .card-content': {
    display: "flex",
    flexDirection: "column",
    gap: "40px"
  },
  '& .card-value-text': {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "30px"
  },
  '& .contact-us-image': {
    width: "514px",
    height: "472px",
    "@media only screen and (max-width: 1480px)" : {
      width: '380px',
      height: 'auto'
    },
    "@media only screen and (max-width: 970px)" : {
      width: '250px',
      height: 'auto'
    },
    "@media only screen and (max-width: 767px)" : {
      width: '150px',
      height: 'auto'
    }
  },
'& .landingMainBox': {
  flexDirection:'row',display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  margin: "100px 150px 130px",
  gap: "100px",
  position: "relative",
  "@media only screen and (max-width: 1300px)" : {
    margin: '100px 60px 130px'
  },
  "@media only screen and (max-width: 767px)" : {
    margin: "0 0 30px",
    justifyContent: "center"
  }
},
'& .faq-container': {
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  "@media only screen and (max-width: 767px)": {
    gap: "12px"
  },
  '& .MuiAccordionSummary-content': {
    margin: "24px 0",
    "@media only screen and (max-width: 767px)" : {
      margin: "12px 0",
    }
  },
  '& .MuiAccordionSummary-root': {
    padding: '0 30px',
    "@media only screen and (max-width: 767px)" : {
      padding: '0 16px'
    }
  },
  '& .Mui-expanded': {
    margin: "15px 0 0",
    "@media only screen and (max-width: 767px)" : {
      margin: "8px 0 0",
    }
  },
},
'& .get-started-container': {
  display: "flex",
  width: "100%",
  justifyContent: 'space-between',
  alignItems: "center",
  paddingLeft: "263px",
  "@media only screen and (max-width: 1000px)" : {
    paddingLeft: 0,
  },
},
'& .get-started-text': {
  color: "#FFF",
  fontStyle: "normal",
  fontWeight: 700,
  textShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
  fontFamily: "Urbanist",
  fontSize: "36px",
  lineHeight: "30px",
  "@media only screen and (max-width: 1200px)" : {
    fontSize: "25px",
    lineHeight: "25px",
  },
  "@media only screen and (max-width: 700px)" : {
    fontSize: "16px",
    lineHeight: "18px",
  }
},
'& .get-started-description-text': {
  marginTop: "26px",
  fontFamily: "Urbanist",
  fontSize: "18px",
  color: "#FFF",
  textShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
  fontStyle: "normal",
  lineHeight: "27px",
  fontWeight: 500,
  "@media only screen and (max-width: 1200px)" : {
    fontSize: "14px",
    marginTop: "10px",
    lineHeight: "25px",
  },
  "@media only screen and (max-width: 700px)" : {
    lineHeight: "15px",
    fontSize: "12px",
    marginTop: "6px",
  }
},
'& .card-image': {
  height: '70px',
  width: '70px',
  marginRight: '30px'
},
'& .faq-underline': {
  height: '3px',
  width: '80px',
  "@media only screen and (max-width: 767px)" : {
    height: '2px',
    width: '42px'
  }
},
'& .signUpButton': {
  borderRadius: "12px",
  border: "1px solid #9E9E9E",
  background: "#FFF",
  display: "flex",
  width: "185px",
  height: "56px",
  lineheight: "56px",
  textTransform: "capitalize",
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontWeight: 600,
  "@media only screen and (max-width: 900px)" : {
    fontSize:'14px',
    width:'125px',
    lineHeight: '50px',
    height:'50px',
  },
  "@media only screen and (max-width: 500px)" : {
    fontSize:'10px',
    width:'100px',
  }
},
'& .top-line-image': {
  position:'absolute',
  top:-1,
  width:'100%',
  height: '510px',
  "@media only screen and (max-width: 767px)" : {
    display: 'none'
  }
},
'& .information-content': {
  borderRadius: "20px",
  border: "1px solid #CEE1D6",
  background: "#019F48",
  padding: "30px",
  minWidth: "415px",
  boxSizing: "border-box"
},
'& .MuiAccordion-root:before': {
  display: 'none'
},
'& .faq-wrapper': { 
  alignItems:'center',
  justifyContent:'center',
  padding: '30px 150px',
  textAlign: 'center',
  color: '#333333',
  marginBottom: "50px",
  "@media only screen and (max-width: 1300px)" : {
    padding: '30px 60px'
  },
  "@media only screen and (max-width: 767px)" : {
    padding: "35px 24px"
  }
},
'& .faq-heading': {
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  "@media only screen and (max-width: 767px)" : {
    fontSize: "22px"
  },
},
'& .faq-subtitle': {
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "18px",
  lineHeight: "30px",
  fontWeight: 400,
  fontStyle: "normal",
  margin: "20px 0 30px",
  "@media only screen and (max-width: 767px)" : {
    color: "#363A3F",
    fontSize: "16px",
    lineHeight: "23px",
    opacity: 0.5,
    margin: "12px 0 20px",
    fontWeight: 500,
  },
},
'& .desktop-view': {
  "@media only screen and (max-width: 767px)" : {
    display: 'none'
  }
},
'& .getStartrd':{
  flexDirection:'row',
  display:'flex',
  margin: "0 150px 80px",
  padding: "40px",
  background: "#FD8D02",
  position: "relative",
  borderRadius: "24px",
  "@media only screen and (max-width: 1300px)" : {
    margin: '0 60px 80px'
  },
},
'& .creditLiftWorkView': {
  flexDirection:'row',
  display:'flex',
  marginTop:14,
  alignItems:'center',
  justifyContent:'space-between',
  padding:'8px'
},
'& .MuiAccordionDetails-root': {
  borderLeft: '3px solid #019F48',
  margin: '0 20px 20px',
  padding: '0 50px 0 20px'
},
'& .accordion-wrap': {
  background: "#FFF",
  borderRadius: "24px",
  border: "1px solid rgba(11, 109, 55, 0.18)",
  boxShadow: "0px 8px 0px 0px rgba(11, 109, 55, 0.10)",
  "@media only screen and (max-width: 767px)" : {
    borderRadius: "6.884px",
    border: "0.287px solid rgba(11, 109, 55, 0.18)",
    boxShadow: "0px 2.295px 0px 0px rgba(11, 109, 55, 0.10)"
  }
},
'& .accordion-title': {
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "30px",
  textAlign: "left",
  "@media only screen and (max-width: 767px)" : {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "23px",
  }
},
'& .accordion-description': {
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  textAlign: "left",
  "@media only screen and (max-width: 767px)" : {
    fontSize: "14px",
    lineHeight: "18px",
  }
},
'& .get-in-touch-wrapper': {
  flexDirection:'row',
  justifyContent:'space-between',
  gap: '50px',
  display:'flex',
  color: 'white',
  width: "100%",
  padding: "50px 150px",
  boxSizing: "border-box",
  "@media only screen and (max-width: 1300px)" : {
    padding: '50px 60px'
  },
  "@media only screen and (max-width: 767px)" : {
    padding: '0 24px',
  }
},
'& .downloadCreditImg':{
  alignItems:'center', justifyContent:'center',border:'0px solid white !important',borderColor:'#FFFFF'
},
'& .signUpNow':{alignItems:'center',justifyContent:'center',padding:20},
'& .aboutUsView': {flexDirection:'row',display:'flex',paddingTop:30,paddingBottom:30,margin:10},
'& .credtScore':{alignItems:'center',justifyContent:'center',textAlign:'center',padding:20}
})

const FooterWrap = styled(Box)({
  display: "block",
  "@media only screen and (max-width: 767px)": {
    display: "none"
  }
})

// Customizable Area End
