import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React, { RefObject } from 'react';
import { nextArrow, nextArrowGrey, backArrow, backArrowGrey } from "./assets";
import Slider from "react-slick";

export interface ContactUsData {
    phone: string;
    email: string;
    location: string;
}

export interface SocialMediaLinksData {
    twitter_link: string;
    linkedin_link: string;
    fb_link: string;
    instagram_link: string;
}

export interface TestimonialData {
    attributes: {
        quote: string; 
        name: string; 
        photo: string;
    }
}

export interface AboutUsRecordData {
  title: string;
  about_us_descriptions: {
    id: number;
    description: string
  }[]
}

export interface AboutUsData {
  description1: string;
  description2: string
  about_us_records: AboutUsRecordData[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoggedIn: boolean;
  socialMediaData: SocialMediaLinksData;
  activeSlide: number;
  aboutUsData: AboutUsData;
  contactUs: ContactUsData;
  testimonials: TestimonialData[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
// Customizable Area Start
getFooterDataCallId: string = "";
getContactUsCallId: string = "";
getAboutUsCallId: string = "";
getTestmonialCallId: string = "";
slideRef: RefObject<Slider> = React.createRef();
// Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        isLoggedIn: false,
        socialMediaData: {
            linkedin_link: '',
            fb_link: '',
            twitter_link: '',
            instagram_link: '',
        },
        activeSlide: 1,
        aboutUsData: {
          description1: "",
          description2: "",
          about_us_records: []
        },
        testimonials: [],
        contactUs: {
          email: '',
          location: '',
          phone: '',
        }
    };

    this.getContactUs();
    this.getFooterData();
    this.getAboutUs();
    this.getTestimonialData();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors){
        if (requestCallId === this.getFooterDataCallId) {
          const { linkedin_link, fb_link, twitter_link, instagram_link } = responseJson.social_media_links
          this.setState({
            socialMediaData: {
              fb_link,
              instagram_link,
              linkedin_link,
              twitter_link,
            }
           });
        }else if(requestCallId === this.getContactUsCallId){
          if(responseJson.contact_us){
            const { address, phone_number, email} = responseJson.contact_us;
            this.setState({ contactUs: { email, phone: phone_number, location: address } })
          }
        }else if(requestCallId === this.getTestmonialCallId){
          if(responseJson.data){
            this.setState({ testimonials: responseJson.data})
          }
        }else if(requestCallId === this.getAboutUsCallId){
          if(responseJson.data && responseJson.data.length > 0){
            this.setState({ aboutUsData: responseJson.data[0].attributes})
          }
        }
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
    const loggedIn = await getStorageData('profileData');
    this.setState({ isLoggedIn: loggedIn !== null })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevProps.navigation !== this.props.navigation) {
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
  }
  }

  getFooterData = () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType
    };

    const footerDataRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFooterDataCallId = footerDataRequestMessage.messageId;
    footerDataRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFooterLinkDataEndpoint
    );

    footerDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    footerDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(footerDataRequestMessage.id, footerDataRequestMessage);

  }

  getTestimonialData = () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType
    };

    const testimonialDataRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestmonialCallId = testimonialDataRequestMessage.messageId;
    testimonialDataRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.testimonialAPIEndpoint
    );

    testimonialDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    testimonialDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(testimonialDataRequestMessage.id, testimonialDataRequestMessage);
  }

  getNextImages = () => this.state.activeSlide !== this.state.testimonials.length ? nextArrow : nextArrowGrey;

  getNextClass = () => this.state.activeSlide !== this.state.testimonials.length ? 'pointer' : '';

  getBackClass = () => this.state.activeSlide > 1 ? 'pointer' : ''

  getBackImages = () => this.state.activeSlide > 1 ? backArrow : backArrowGrey;

  handleNextSlides = () => {
    if(this.slideRef.current && this.slideRef.current.slickNext && this.state.activeSlide !== this.state.testimonials.length){
      this.slideRef.current.slickNext();
      this.setState({ activeSlide: this.state.activeSlide + 1});
    }
  }

  handleBackSlides = () => {
    if(this.slideRef.current && this.slideRef.current.slickPrev && this.state.activeSlide > 1){
      this.slideRef.current.slickPrev();
      this.setState({ activeSlide: this.state.activeSlide - 1});
    }
  }

  getContactUs= () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType
    };

    const contactUsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactUsCallId = contactUsMessage.messageId;
    contactUsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsGetUrl
    );

    contactUsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    contactUsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(contactUsMessage.id, contactUsMessage);
  }

  getAboutUs = () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType
    };
    
    const aboutUsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAboutUsCallId = aboutUsMessage.messageId;
    aboutUsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aboutUsGetUrl
    );

    aboutUsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    aboutUsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(aboutUsMessage.id, aboutUsMessage);
  }

  handleSignUp = () => {
    const signUpNavigateMsg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    signUpNavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(signUpNavigateMsg);
  }

  handleContactUs = () => {
    const contactUsNavigateMsg: Message = new Message(
      getName(MessageEnum.NavigationContactUsMessage)
    );
    contactUsNavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(contactUsNavigateMsg);
  }

  getOurStoryFirstDescription = () => this.state.aboutUsData.about_us_records.length > 0 && this.state.aboutUsData.about_us_records[0].about_us_descriptions[0].description;

  getWhoWeAreFirstDescription = () => this.state.aboutUsData.about_us_records.length > 1 && this.state.aboutUsData.about_us_records[1].about_us_descriptions[0].description;

  // Customizable Area End
}
