import React, { RefObject ,createRef} from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { io, Socket } from "socket.io-client";
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';
import { EmojiClickData } from "emoji-picker-react";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
export interface Messages {
  id: number;
  sender: "user" | "admin";
  message: string;
  timestamp: string;
  file?: string;
  fileName?: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  isOpen: boolean;
  adminOnline: boolean;
  messages: Messages[];
  newMessage: string;
  isEmojiPickerVisible: boolean;
  chat_room_id: number | null;
  selectedFiles: File[];
  currentPage: number;
  totalPages: number;
  isMessageSentByUser: boolean;
  isLoadingMessages: boolean;
  isMessegeSend: boolean;
  windowWidth: number;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserChatApiCallId: string = '';
  getAdminStatusApiCallId: string = '';
  getChatListApiCallId: string = "";
  sendUserMessageApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  createWebChatRoomApiCallId: string = "";
  messagesEndRef: RefObject<HTMLDivElement> = React.createRef();
  chatContainerRef: RefObject<HTMLDivElement> = React.createRef();
  observer: IntersectionObserver | null = null;

  socket!: Socket;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.messagesEndRef = createRef();
    this.chatContainerRef = createRef()

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      isOpen: false,
      adminOnline: true,
      messages: [],
      newMessage: "",
      isEmojiPickerVisible: false,
      chat_room_id: null,
      selectedFiles: [],
      currentPage: 1,
      totalPages: 1,
      isMessageSentByUser: false,
      isLoadingMessages: false,
      isMessegeSend: false,
      windowWidth: window.innerWidth, 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this.handleResize);
    this.getToken();

    const userId = await getStorageData("userId");
    let chatRoomId = await getStorageData("chatRoomId");

    if (!chatRoomId) {
      this.createWebChatRoom(userId);
    } else {
      this.initializeChatProcess(chatRoomId);
    }

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    this.setState({ isMessageSentByUser: true })

    const options = {
      root: this.chatContainerRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    };
  
    this.observer = new IntersectionObserver(this.handleIntersection, options);
    const target = document.getElementById("load-more");
    if (target) {
      this.observer.observe(target);
    }
    // Customizable Area End
  }

  initializeChatProcess(chatRoomId: number) {
    this.getUserChatData(chatRoomId);
    this.getAdminStatus();
    this.setupSocketListeners();
    this.socket.emit("joinRoom", { chat_room_id: chatRoomId });
  }


  componentDidUpdate(prevProps: Props, prevState: S) {
    const { messages, isMessageSentByUser } = this.state;

    if (isMessageSentByUser && messages !== prevState.messages) {
      this.scrollToLatestMessage();
      this.setState({ isMessageSentByUser: false });
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getChatButtonStyles = () => {
    const { windowWidth } = this.state;
    if (windowWidth <= 767) {
      return {
        width: '50px',
        borderRadius: '25px',
      };
    }
    return {
      width: '145px',
      borderRadius: '12px',
    };
  };

  getChatButtonTextStyles = () => {
    const { windowWidth } = this.state;
    return {
      color: "#FFFFFF",
      fontSize: "15px",
      fontFamily: "'Inter', sans-serif",
      display: windowWidth <= 767 ? "none" : "block",
    };
  };

  getChatButtonIconStyles = () => {
    const { windowWidth } = this.state;
    return {
      paddingLeft: windowWidth <= 767 ? '0' : '8px',
      bottom: windowWidth <= 767 ? '20px' : '0',
    };
  };

  getDisplayStyle = (): "none" | "flex" => {
    return this.state.isOpen ? "none" : "flex";
  };  
  
  handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        console.log('Load more messages triggered');
      }
    });
  };

  handleScroll = async (event: React.SyntheticEvent) => {
    const chatContainer = event.target as HTMLDivElement;
    const { currentPage, totalPages } = this.state;
    const chatUserId = await getStorageData('chatRoomId')
    this.setState({isMessegeSend: false})
    if (chatContainer.scrollTop === 0) {
      if (currentPage < totalPages) {
        this.setState(
          { currentPage: currentPage + 1 },
          () => {
            this.getUserChatData(chatUserId, 10, this.state.currentPage);
          }
        );
      } 
    }
  };

  setupSocketListeners = () => {
    this.socket = io("https://localhost:3000");

    this.socket.on("connect", async () => {
      let chatRoomId = await getStorageData("chatRoomId");
      if (chatRoomId) {
        this.socket.emit("joinRoom", { chat_room_id: chatRoomId });
      }
    });

    this.socket.on("newMessage", (message: Messages) => {
      this.setState((prevState) => ({
        messages: [...prevState.messages, message],
      }));
    });

    this.socket.on("disconnect", () => {
    });
  }

  disconnectSocket = () => {
    if (this.socket) {
      this.socket.off();
      this.socket.disconnect();
    }
  };

  scrollToLatestMessage = () => {
    if (this.messagesEndRef?.current) {

      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleEmojiClick = (emojiObject: EmojiClickData) => {
    this.setState((prevState) => ({
      newMessage: (prevState.newMessage || "") + (emojiObject?.emoji || ""),
    }));
  };

  toggleEmojiPicker = () => {
    this.setState((prevState) => ({
      ...prevState,
      isEmojiPickerVisible: !prevState.isEmojiPickerVisible,
    }))
  };

  handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ newMessage: event.target.value || "" });
  };


  handleSend = async () => {
    const UserID = await getStorageData('userId');
    const chatUserId = await getStorageData('chatRoomId')
    this.setState({isMessegeSend : true})
    this.sendUserMessage(chatUserId, UserID);
    this.getUserChatData(chatUserId, 10, 1);

    if (this.state.isEmojiPickerVisible) {
      this.toggleEmojiPicker();
    }
  }

  getAdminStatus = () => {
    try {
      const header = {
        "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAdminStatusApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAdminStatusApiEndpoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);


    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }


  getUserChatData = (chatRoomId: any, perPage: number = 10, page: number = 1) => {
    const { isLoadingMessages } = this.state;
    if (isLoadingMessages) return;

    this.setState({ isLoadingMessages: true });
    try {

      const header = {
        "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getUserChatApiEndpoint}/${chatRoomId}/messages?per_page=${perPage}&page=${page}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);


    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const UserID = await getStorageData('userId');
    let chatRoomId = await getStorageData("chatRoomId");

    if (files) {
      const fileArray = Array.from(files);

      this.setState(
        (prevState) => ({
          selectedFiles: [...prevState.selectedFiles, ...fileArray],
          isMessegeSend: true
        }),
        () => {
          this.sendUserMessage(chatRoomId, UserID);
        }
      );

      event.target.value = "";
    }
  };

  sendUserMessage = async (chat_room_id: any, account_id: any) => {
    const { newMessage, selectedFiles, messages } = this.state;

    const formData = new FormData();
    formData.append("message[chat_room_id]", String(chat_room_id));
    formData.append("message[account_id]", String(account_id));
    formData.append("message[message]", newMessage);

    if (selectedFiles.length > 0) {
      selectedFiles.forEach((file, index) => {
        formData.append(`message[files][]`, file, file.name);
      });
    }

    const socketPayload: any = {
      chat_room_id: chat_room_id,
      account_id: account_id,
      message: newMessage,
      files: selectedFiles.map((file) => ({
        name: file.name,
        type: file.type,
        size: file.size,
        url: URL.createObjectURL(file),
      })),
    };

    const socketEvent = selectedFiles.length > 0 ? "sendFileMessage" : "sendMessage";

    this.socket.emit(socketEvent, socketPayload);

    this.setState((prevState) => ({
      newMessage: "",
      selectedFiles: [],
    }));

    try {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.sendUserMessageApiCallId = requestMessage.messageId;

      const header = {};
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.sendUserMessageApiEndpoint}/${chat_room_id}/messages`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch (error) {
      console.error("Error sending message via API:", error);
    }
  };


  formatTime = (dateString: string) => {
    const time = new Date(dateString).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).toLowerCase();
    return time;
  };


  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return "Today";
    if (date.toDateString() === yesterday.toDateString()) return "Yesterday";
    return date.toLocaleDateString();
  };

  getDateLabel = (timestamp: string): string => {
    const messageDate = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (
      messageDate.toDateString() === today.toDateString()
    ) {
      return "Today";
    } else if (
      messageDate.toDateString() === yesterday.toDateString()
    ) {
      return "Yesterday";
    } else {
      return this.formatDate(timestamp);
    }
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createWebChatRoom = (userId: number) => {
    if (userId) {
      const header = {
        "Content-Type": configJSON.apiContentType,
      };
      const bodyData = {
        "chat_room": {
          "sender_id": userId
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createWebChatRoomApiCallId = requestMessage.messageId

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createWebChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const accountId: number = messageData?.meta?.id;
      this.setState({ accountId });
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson?.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      apiRequestCallId === this.createChatRoomApiCallId
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
    if (
      apiRequestCallId === this.getUserChatApiCallId
    ) {
      this.setState({ isLoadingMessages: false });
      this.getUserChatApiCallId = "";
      const newMessages = responseJson?.messages.map((msg: any) => ({
        id: msg.id,
        sender: msg.account_id ? "user" : "admin",
        message: msg.message,
        timestamp: msg.Date,
        file: msg.files
      })).reverse();

      this.setState((prevState) => {
          const combinedMessages = [...newMessages, ...prevState.messages];  
          const uniqueMessages = combinedMessages.filter(
            (message, index, self) =>
              index === self.findIndex((msg) => msg.id === message.id)
          );

        const finalMessage = this.state.isMessegeSend ? newMessages : uniqueMessages

        return {
          messages: finalMessage,
          currentPage: responseJson?.meta.current_page,
          totalPages: responseJson?.meta.total_pages,
        };
      });
    }
    if (
      apiRequestCallId === this.getAdminStatusApiCallId
    ) {
      this.getAdminStatusApiCallId = "";
      this.setState({ adminOnline: responseJson?.data[0].is_online });
    }
    if (apiRequestCallId === this.sendUserMessageApiCallId) {
      const chatUserId = await getStorageData('chatRoomId')
      this.getUserChatData(chatUserId);
      this.setState({ isMessageSentByUser: true })
    }
    if (
      apiRequestCallId === this.createWebChatRoomApiCallId
    ) {
      this.createWebChatRoomApiCallId = "";
        const chatRoomId = responseJson.chat_room_id;
        await setStorageData("chatRoomId", JSON.stringify(chatRoomId));
        this.setState({ chat_room_id: chatRoomId }, () => {
          this.initializeChatProcess(chatRoomId);
        });
    }
  }
  // Customizable Area End
}


