// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const document = require("../assets/doc.png");
export const txtLableStarted = require("../assets/txtLableStarted.png");
export const imgGraph = require("../assets/image_graph.png");
export const imgCreditLift = require("../assets/image_credit_logo.png");
export const imgWatch = require("../assets/watch.png");
export const imgGroupProcess = require("../assets/group_progress.png");
export const imgDocUpload = require("../assets/docUpload.png");
export const imgDescription = require("../assets/group_icon.png");
export const imgCross = require("../assets/cross.png");
export const imgGreenCross = require("../assets/view_bg.png");
export const imgVerification = require("../assets/image_verification.png");

// Customizable Area End
