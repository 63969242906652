import React from "react";
// Customizable Area Start
import { useGauge } from "use-gauge";
import { styled, Box } from "@material-ui/core";
// Customizable Area End

interface ArcedProps {
  // Customizable Area Start
  value: number;
  diff: number;
  // Customizable Area End
}

// Customizable Area Start
const START_ANGLE = 90;
const END_ANGLE = 270;
// Customizable Area End

export function GaugeChart(props: ArcedProps) {
  // Customizable Area Start
  const { value, diff } = props;
  const gauge = useGauge({
    domain: [300, 850],
    startAngle: START_ANGLE,
    endAngle: END_ANGLE,
    numTicks: 180,
    diameter: 150,
  });

  const needle = gauge.getNeedleProps({
    value,
    baseRadius: 55,
    tipRadius: 1,
    offset: 3
  });

  function convertPathToCircle(pathData: string, desiredRadius: number, isFill?: boolean) {
    const getPath = pathData.split(' ');
    const cx = getPath[getPath.length - 2];
    const cy = getPath[getPath.length - 1];

    return <circle cx={cx} cy={cy} r={desiredRadius} strokeWidth={!isFill ? "7" : "4"} stroke={!isFill ? getGaugeCircleFill(value) : "#FFF"} fill={isFill ? "#97A065" : "none" } />;
  }

  const getCircle = (radius: number, isFill?: boolean) => {
    const angle = gauge.valueToAngle(value);
    const initPath = gauge.getArcProps({ offset: 20, startAngle: angle, endAngle: angle});
    return convertPathToCircle(initPath.d, radius, isFill)
  }

  const getPath = () => (
    <>
          {value > 300 && 
            <path
              {...gauge.getArcProps({
                offset: 20,
                startAngle: START_ANGLE,
                endAngle: gauge.valueToAngle(value < 580 ? value : 575)
              })}
              fill="none"
              stroke="#E06E6A"
              strokeWidth={10}
            />
          }
          {value >= 580 &&
            <>
              <path
                {...gauge.getArcProps({
                  offset: 20,
                  startAngle: gauge.valueToAngle(575),
                  endAngle: gauge.valueToAngle(585)
                })}
                fill="none"
                stroke={`#FFF`}
                strokeWidth={10}
              />
              <path
                {...gauge.getArcProps({
                  offset: 20,
                  startAngle: gauge.valueToAngle(585),
                  endAngle: gauge.valueToAngle(value < 650 ? value : 635)
                })}
                fill="none"
                stroke={`#E89967`}
                strokeWidth={10}
              />
            </>
          }
          {value >= 650 && 
            <>
              <path
                {...gauge.getArcProps({
                  offset: 20,
                  startAngle: gauge.valueToAngle(635),
                  endAngle: gauge.valueToAngle(645)
                })}
                fill="none"
                stroke={`#FFF`}
                strokeWidth={10}
              />
              <path
                {...gauge.getArcProps({
                  offset: 20,
                  startAngle: gauge.valueToAngle(645),
                  endAngle: gauge.valueToAngle(value < 700 ? value : 695)
                })}
                fill="none"
                stroke={`#FEAC4C`}
                strokeWidth={10}
              />
            </>
          }
          {value >= 700 &&
            <>
              <path
                {...gauge.getArcProps({
                  offset: 20,
                  startAngle: gauge.valueToAngle(695),
                  endAngle: gauge.valueToAngle(705)
                })}
                fill="none"
                stroke={`#FFF`}
                strokeWidth={10}
              />
              <path
                {...gauge.getArcProps({
                  offset: 20,
                  startAngle: gauge.valueToAngle(705),
                  endAngle: gauge.valueToAngle(value)
                })}
                fill="none"
                stroke={`#58B351`}
                strokeWidth={10}
              />
            </>
          }
    </>
  )

  const getFill = (scoreValue: number) => {
    switch(scoreValue){
      case 300:
        return "#E06E6A";
      case 581:
        return "#E2826B";
      case 639:
        return "#87A263";
      case 700:
        return "#2DAF59";
      case 850:
        return "#23B058";
      default:
        return "";
    }
  }

  const getGaugeCircleFill = (scoreValue: number) => {
    if (scoreValue >= 300 && scoreValue < 580) {
      return "#E06E6A";
    } else if (scoreValue >= 580 && scoreValue < 650) {
      return "#E89967";
    } else if (scoreValue >= 650 && scoreValue < 700) {
      return "#FEAC4C";
    } else {
      return "#58B351";
    }
  }
  // Customizable Area End

  return (
    // Customizable Area Start
    <GaugeWrapper key={value}>
      <svg className="gauge-chart-wrapper" {...gauge.getSVGProps()}>
        <g id="arcs">
          <path
            {...gauge.getArcProps({
              offset: 20,
              startAngle: 60,
              endAngle: 300
            })}
            fill="#F6F5FA"
            stroke="url(#arc-color)"
            strokeWidth={1.5}
          />
          <path
            {...gauge.getArcProps({
              offset: 20,
              startAngle: 0,
              endAngle: 60
            })}
            fill="#FFF"
            stroke="url(#arc-color)"
            strokeWidth={1}
          />
          <path
            {...gauge.getArcProps({
              offset: 20,
              startAngle: 300,
              endAngle: 360
            })}
            fill="#FFF"
            stroke="url(#arc-color)"
            strokeWidth={1}
          />
          <path
            {...gauge.getArcProps({
              offset: 20,
              startAngle: START_ANGLE,
              endAngle: START_ANGLE
            })}
            fill="none"
            stroke="#E06E6A"
            strokeLinecap="round"
            strokeWidth={10}
          />
          {getPath()}
        </g>
        <g id="ticks">
          {gauge.ticks.map((angle) => {
            const asValue = gauge.angleToValue(angle);
            const showText = (asValue === 300 || asValue === 581 || asValue === 639 || asValue === 700 || asValue === 850);
            return (
              <React.Fragment key={`tick-group-${angle}`}>
                {showText && (
                  <text
                    className="gauge-label"
                    {...gauge.getLabelProps({ angle, offset: -35 })}
                    style={{ fill: getFill(asValue)}}
                    transform={`rotate(${angle - 180}, ${gauge.getLabelProps({ angle, offset: -35}).x}, ${gauge.getLabelProps({ angle, offset: -35}).y})`}
                  >
                    {asValue === 581 ? 580 : asValue === 639 ? 640 : asValue}
                  </text>
                )}
              </React.Fragment>
            );
          })}
        </g>
        <defs>
          <linearGradient id="outer-circle" x1="0" x2="0" y1="0" y2="1">
            <stop stopColor="#f7fffb" offset="0%" />
            <stop stopColor="#edf4f0" offset="100%" />
          </linearGradient>
          <linearGradient id="middle-circle" x1="0" x2="0" y1="0" y2="1">
            <stop stopColor="#edf3f0" offset="0%" />
            <stop stopColor="#f5fef9" />
          </linearGradient>
          <linearGradient id="arc-color" x1="0" x2="0" y1="0" y2="1">
            <stop stopColor="#707070" offset="0" />
            <stop stopColor="#707070" offset="100%" />
          </linearGradient>
        </defs>

  
        <g id="needle">
          <polyline className="grey-arrow" fill="none" points={needle.points} />
          <polygon {...needle.base} fill="#FFF" points="-82,47, 0, 0, 82, 47" className="triangle" />
          <circle
            className="white-circle"
            {...needle.base}
            r={60}
          />
          <circle
            className="white-inner-circle"
            {...needle.base}
            r={50}
            fill="#FFF"
          />
        </g>
        <g>
          {getCircle(10, false)}
          {getCircle(6, true)}
        </g>
      </svg>
      <ScoreDetailSection>
        <Box className="diff-text"><img src={require("./ic_arrow_up.svg")} /><span>{Math.abs(diff)}</span></Box>
        <Box className="score-text">{value}</Box>
        <Box className="score-status-text">Excellent</Box>
      </ScoreDetailSection>
    </GaugeWrapper>
    // Customizable Area End
  );
}

// Customizable Area Start
const GaugeWrapper = styled(Box)({
  height: "100%",
  width: "100%",
  position: "relative",
  "& .gauge-chart-wrapper": {
    overflow: "visible",
    marginTop: "50px"
  },
  "& .gauge-label": {
    color: "#E06E6A",
    fontFamily: "Urbanist",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  "& .white-circle": {
    fill: "#FFF",
    filter: "drop-shadow(0px 11px 17px rgba(0, 0, 0, 0.14))",
  },
  "& .white-inner-circle": {
    fill: "#FFF",
    strokeWidth: "1px",
    stroke: "#ECEBF2",
  },
  "& .ticks>line": {
    strokeWidth: 1
  },
  "& .red-tick": {
    stroke: "#eb243d"
  },
  "& .orange-tick": {
    stroke: "#fe8334"
  },
  "& .yellow-tick": {
    stroke: "#ead35a"
  },
  "& .green-tick": {
    stroke: "#b6db44"
  },
  "& .grey-arrow": {
    fill: "#000"
  }
});

const ScoreDetailSection = styled(Box)({
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#333",
  fontStyle: "normal",
  fontFamily: "Urbanist",
  "& .diff-text": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#23B058",
    fontSize: "13px",
    fontWeight: "bold"
  },
  "& .score-text": {
    fontSize: "27px",
    fontWeight: 700,
    color: "#000",
  },
  "& .score-status-text": {
    fontSize: "13px",
    fontWeight: 500,
    color: "#23B058"
  }
});
// Customizable Area End
