// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.apiMethodTypeAddDetail = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DocusignIntegration";
exports.labelBodyText = "DocusignIntegration Body";
exports.btnExampleTitle = "CLICK ME";

exports.DocumentContetBackText="Back";
exports.DocumentContetGoSignText="Go for Signature";
exports.DocumentListListText="Lists";
exports.DocumentListdisplayMapOPENText="open";
exports.DocusignIntegrationBackText="Back";
exports.updateAccountAPIEndPoint = "account_block/accounts";
exports.uploadDocumentsHeading = "Upload Documents";
exports.questionsHeading = "Questions";
exports.getAllQuestionsEndpoint = "bx_block_question_bank/questions";
exports.QuestionUpdateTitleText = "Question lists are updated";
exports.QuestionTitleText = "Pending Verification";
exports.QuestionUpdateDescriptionText = "Please click on go back to re-anwsers the questions.";
exports.QuestionDescriptionText = "We have received your profile details after profile verification your account will be activated.";
exports.goBackText = "Go Back";
exports.okayText = "Okay";

// Customizable Area End
