export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgGraph = require("../assets/image_graph.png");
export const imgCreditLift = require("../assets/image_logo.png");
export const imgWatch = require("../assets/watch.png");
export const welcomeLogo = require("../assets/welcomeTxt.png");
export const imgUploadDoc = require("../assets/imgUploadDoc.png");
export const view_Vertical_line = require("../assets/view_Vertical_line.png");
export const imgVerification = require("../assets/image_verification.png");
export const activeIcon = require("../assets/active.svg");
export const notActiveIcon = require("../assets/not_active.svg"); 
export const mailImage = require("../assets/mail_image.png");