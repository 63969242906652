import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { getStorageData, logoutUser, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  screenName?: string;
  fullWidthNavigation?: boolean;
  screenTitle?: string;
  isScreenTitleCenter?: boolean;
  isBackButton?: boolean;
  isDrawer?: boolean;
  isLogo?: boolean;
  isNotification?: boolean;
  isDashboard?: boolean;
  userName?: string;
  role_type?: string;
  userProfilePic?: string;
  notifyCount?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  userName: string;
  role_type: string;
  profileUrl: string;
  notificationCount: number;
  anchorEl: null | HTMLImageElement;
  isModalOpen: boolean;
  fullWidthNavigationMenu: boolean;
  mobileDrawer: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  gettingUserDataCallId: string = "";
  getNotificationDataCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      userName: '',
      role_type: '',
      profileUrl: "",
      notificationCount: 0,
      anchorEl: null,
      isModalOpen: false,
      fullWidthNavigationMenu: this.props.fullWidthNavigation ?? true,
      mobileDrawer: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallingId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJsonData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(responseJsonData && !responseJsonData.errors){
      if(apiRequestCallingId === this.gettingUserDataCallId){
        if(responseJsonData.data){
          const { user_name, image_url, role_id} = responseJsonData.data.attributes;
          this.setState({ userName: user_name, profileUrl: image_url, role_type: role_id})
          setStorageData('profileData', JSON.stringify({ userName: user_name, profileUrl: image_url, role_type: role_id }));
        }
      }else if(apiRequestCallingId === this.getNotificationDataCallId){
        const data = responseJsonData.data.length > 0 ? responseJsonData.data.filter((item: { attributes: { is_read: boolean; }}) => !item.attributes.is_read).length : 0;
        this.setState({ notificationCount: data });
      }
    }else{
      if(!this.props.screenName?.toLowerCase().includes('us') && this.state.fullWidthNavigationMenu && apiRequestCallingId === this.gettingUserDataCallId){
        const tokenErrorMessage = responseJsonData && responseJsonData.errors && responseJsonData.errors[0]?.token;

        if(tokenErrorMessage && (tokenErrorMessage === "Token has Expired" || tokenErrorMessage === "Invalid token")){
          this.goFromNavigationToLoginPage();
        }
      }
    }

    
    if (getName(MessageEnum.SessionResponseMessage) === message.id && this.props.fullWidthNavigation) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== "" &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ drawerItems: responseJson });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getUserDataFromStorage = async () => {
    const data = await getStorageData('profileData');
    if(data){
      const userData = JSON.parse(data);
      this.setState({ userName: userData.userName, profileUrl: userData.profileUrl, role_type: userData.role_type });
    }else{
      this.getUserData();
    }
  }

  getNotifyClass = () => {
    const notification = String(this.state.notificationCount).length;
    if(notification > 2){
      return 'notify-3';
    }else if(notification === 2){
      return 'notify-2';
    }else{
      return ''
    }
  }

  handlePopoverClick = (event: React.MouseEvent<HTMLImageElement>) =>
    this.setState({ anchorEl: event.currentTarget });

  handleClosePopover = () => this.setState({ anchorEl: null });

  handleLogOut = () => {
    this.handleClosePopover();
    this.setState({ isModalOpen: true });
  };

  goToLogin = async () => {
    this.handleClosePopover();
    this.props.navigation.navigate("EmailAccountLoginBlock");
    await removeStorageData('userData');
    await removeStorageData('userId');
    await removeStorageData('chatRoomId');
    await logoutUser();
  };


  handleNavigation = async(messageEnum: MessageEnum) => {
    const navigateToPage: Message = new Message(
      getName(messageEnum)
    );
    navigateToPage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    if(messageEnum === MessageEnum.NavigationTermAndConditionWeb){
      navigateToPage.addData(getName(MessageEnum.UrlPathParamMessage), 'web');
    } else if(messageEnum === MessageEnum.NavigationTaskDetailMessage){
      let userId = await getStorageData('userId');
      navigateToPage.addData(getName(MessageEnum.UrlPathParamMessage), userId);
    }
    this.send(navigateToPage);
  }

  handleSignUp = () => this.props.navigation.navigate("EmailAccountRegistration")

  modalClose = () => this.setState({ isModalOpen: false })

  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  getMobileHeaderBackgroundColor = () => {
    if(this.props.isDashboard){
      return "#019F48"
    }else if(this.props.screenName === "Notifications"){
      return "#F2F9F6"
    }else{
      return "#FFF"
    }
  }

  handleBack = () => this.props.navigation.goBack();

  handleDrawerClick = () => this.setState({mobileDrawer: true});

  handleDrawerClose = () => this.setState({mobileDrawer: false});

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    if(this.props.fullWidthNavigation || this.props.screenName?.toLowerCase().includes('us')){
      await this.getUserDataFromStorage();
      await this.getNotificationData();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if((this.props.userName || this.props.userProfilePic) && (prevProps.userName !== this.props.userName || prevProps.userProfilePic !== this.props.userProfilePic)){
      this.getUserData();
    }else if(this.props.notifyCount !== undefined && prevProps.notifyCount !== this.props.notifyCount){
      this.getNotificationData();
    }
    if(prevProps.fullWidthNavigation !== this.props.fullWidthNavigation){
      this.setState({ fullWidthNavigationMenu: this.props.fullWidthNavigation ?? true})
      if(this.props.fullWidthNavigation){
        this.getUserData();
      }
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  goFromNavigationToLoginPage = () => {
    const navigationToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    navigationToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationToLoginMessage);
    logoutUser();
  }

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getNotificationData = async () => {
    const token = await getStorageData('authToken');

    const webHeader = {
      token,
    };
    const notificationRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationDataCallId = notificationRequestMessage.messageId;
    notificationRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationEndpoint
    );

    notificationRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    notificationRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(notificationRequestMessage.id, notificationRequestMessage);
  }

  getUserData = async () => {
    const token = await getStorageData('authToken')

    const webHeader = {
      token,
    };
    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.gettingUserDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDataGettingUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  // Customizable Area End
}
