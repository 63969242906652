export const bannerImage = require("../assets/image_banner.png");
export const underLineImg = require("../assets/underLine.png")
export const groupContentImg = require("../assets/group_content.jpg");
export const groupDownloadCreditImg = require("../assets/group_Download_credit_lift.png")
export const Young_woman_holdingImg = require("../assets/Young_woman_holding.png")
export const groupRightImg = require("../assets/group_right_image.png");
export const groupGroupImg = require("../assets/group_Group.png")
export const imageLaptopImg = require("../assets/image_laptop.png")
export const viewLineImg = require("../assets/view_Line.png")
export const questionMarkOneImg = require("../assets/group_quotationMarksOne.png");
export const questionMarkTwoImg = require("../assets/group_quotationMarksTwo.png")
export const shieldSecurityImg = require("../assets/shield-security.svg");
export const shieldSlashImg = require("../assets/shield-slash.svg");
export const bookImg = require("../assets/book.svg");
export const chartImg = require("../assets/chart.svg");
export const imagePattern = require("../assets/image_pattern.svg");
export const aboutUsBottomLine = require("../assets/about_us_bottom_line.svg");
export const startComma = require('../assets/start_comma.svg');
export const endComma = require('../assets/end_comma.svg');
export const mobileImg = require("../assets/mobile.png");
export const qrCodeImg = require("../assets/qr_code.png");
export const googlePlayApp = require("../assets/google_play_app.png");
export const whiteLine = require("../assets/white_line.svg");
export const nextArrow = require("../assets/next-arrow.svg");
export const backArrow = require("../assets/back-arrow.svg");
export const nextArrowGrey = require("../assets/next-arrow-grey.svg");
export const backArrowGrey = require("../assets/back-arrow-grey.svg");