import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from 'moment';
import { getStorageData, logoutUser } from "../../../framework/src/Utilities";

interface InvoicesData {
  id: string;
  title: string;
  generatedOn: string;
  amount: string;
  status: string;
  pdf_url: string;
}

interface InvoiceResData {
    id: string;
    attributes: {
        order_id: number;
        invoice_number: string;
        total_amount: number; 
        description: string;
        created_at: string;
        status: string;
        created_at_formatted: string;
        pdf_url: string;
  }
}

interface InvoicesResData {
  data: InvoiceResData[];
}

interface ModalData {
  isOpen: boolean;
  id: string;
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  invoicesData: InvoicesData[];
  modal: ModalData;
  yearSort: string;
  yearSelectData: string[] | number[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  invoiceDataCallId: any;
  pdfApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      invoice: "",
      invoiceError: "",
      invoicePdf: "",
      invoicesData: [],
      modal: {
        isOpen: false,
        id: "",
      },
      yearSort: "",
      yearSelectData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.invoiceDataCallId){
          this.handleInvoiceData(webResponseJson);
        }
      }else{
        if(webApiRequestCallId === this.invoiceDataCallId){
          const tokenErrorMessage = webResponseJson && webResponseJson.errors && webResponseJson.errors[0]?.token;
  
          if(tokenErrorMessage && (tokenErrorMessage === "Token has Expired" || tokenErrorMessage === "Invalid token")){
            this.goFromInvoiceToLoginPage();
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await super.componentDidMount();
    this.getYearList();
    const token = await getStorageData('authToken')
    this.setState({ token })
    this.getInvoicesData(token);
  }

  getYearList = () => {
    const currentYear = moment().year();
    const lastFiveYears = [];

    for(let i = 0; i < 5; i++) {
      lastFiveYears.push(currentYear - i);
    }

    this.setState({ yearSelectData: lastFiveYears })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.yearSort !== this.state.yearSort){
      this.getInvoicesData();
    }
  }

  getInvoicesData = (token?: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" : token ?? this.state.token
    };

    const endpoint = `${configJSON.invoiceDataEndPoint}${this.state.yearSort?'?year='+this.state.yearSort:''}`

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.invoiceDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInvoiceContentBackground = (status: string) => {
    if(status.toLowerCase() === "paid"){
      return "#F5FFFB";
    }else{
      return "#FFF8EF";
    }
  }

  getInvoiceContentBorderColor = (status: string) => {
    if(status.toLowerCase() === "paid"){
      return "#019F48";
    }else{
      return "#FD8D02";
    }
  }

  handleModalClose = () => {
    this.setState({ modal: { isOpen: false, id: ""}})
  }

  handleStatusClick = (id: string, status: string, pdf_url: string) => {
    if(status.toLowerCase() === "paid"){
      this.setState({ modal: { isOpen: true, id }, invoicePdf: pdf_url })
    }
  }

  handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => this.setState({ yearSort: event.target.value});

  handleInvoiceData = (response: InvoicesResData) => {
    let initialInvoiceData: InvoicesData[] = [];
    if(response.data.length > 0){
      initialInvoiceData = response.data.map((invoice) => ({
          id: invoice.attributes.invoice_number,
          title: invoice.attributes.description,
          generatedOn: invoice.attributes.created_at_formatted,
          amount: String(invoice.attributes.total_amount),
          status: invoice.attributes.status ?? "",
          pdf_url: invoice.attributes.pdf_url ?? ""
      }))
    }
    this.setState({ invoicesData: initialInvoiceData})
  }

  goFromInvoiceToLoginPage = () => {
    const invoiceToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    invoiceToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(invoiceToLoginMessage);
    logoutUser();
  }
  // Customizable Area End
}
