import React from "react";

// Customizable Area Start

import { Box, Button, MenuItem, Modal, Select, TextField, styled, Typography } from "@material-ui/core";

import SchedulingController, {
  Props,
  configJSON,
} from "./SchedulingController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../chat/src/LiveChat.web";
import { calendarIcon, closeIcon, dropdownImg } from "./assets";

// Customizable Area End

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTimeValue = () => this.state.scheduleForm.time_range ? <Box>{this.state.scheduleForm.time_range}</Box> : <Box className="time-placeholder">{configJSON.timePlaceholder}</Box>
  
  getModalContent = () => (
    <React.Fragment>
      <Box className="modal-title-container">
        <Box className="schedule-title-text">
          {configJSON.scheduleYourMeetingText}
        </Box>
        <img data-test-id="schedule-close-button" className="pointer close-icon" src={closeIcon} alt="close" onClick={this.handleCloseModal} />
      </Box>
      <Box className="schedule-description-text">
        {configJSON.scheduleDescriptionText}
      </Box>
      <Box className="form-container">
        <Box>
            <label>{configJSON.dateText}</label>
            <TextField className={`${!this.state.scheduleForm.date ? "date-placeholder" : ""}`} data-test-id="date-input-field" placeholder={configJSON.datePlaceholder} type="date" name="date" variant="outlined" value={this.state.scheduleForm.date} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleScheduleInputChange(event)} inputProps={{ min: new Date().toISOString().split("T")[0] }}/>
        </Box>
        {this.state.fieldErrorCheck.date && (
            <TypoErrorWrapper>
              <Typography className="error">
                Please enter date
              </Typography>
            </TypoErrorWrapper>
        )}
        <Box>
            <label>{configJSON.timeText}</label>
            <Select 
              IconComponent={(props) => <img {...props} src={dropdownImg} style={{ marginRight: "20px" }} alt="dropdown" />}
              MenuProps={{
                PaperProps: {
                    style: {
                        marginTop: "42px",
                        height: "123px",
                        borderRadius: "12px",
                        border: "1px solid rgba(1, 159, 72, 0.18)",
                        background: "#FFF",
                        boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)"
                    }
                }
            }} data-test-id="time-input-field" native={false} displayEmpty renderValue={this.renderTimeValue}  type="date" name="date" variant="outlined" value={this.state.scheduleForm.time_range} onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleSelect(event)}>
                {this.state.timeSlots.length > 0 && this.state.timeSlots.map((timeSlot) => (
                    <MenuItem key={timeSlot} style={{
                        background: "#FFF",
                        color: this.state.scheduleForm.time_range === timeSlot ? "#019F48" :"#333",
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal"
                    }} value={timeSlot}>{timeSlot}</MenuItem>
                ))
                }
            </Select>
        </Box>
        {this.state.fieldErrorCheck.time && (
            <TypoErrorWrapper>
              <Typography className="error">
                {this.state.timeSlotErrorTxt}
              </Typography>
            </TypoErrorWrapper>
        )}
        <Box>
            <label>{configJSON.agendaText}</label>
            <TextField data-test-id="agenda-input-field" placeholder={configJSON.agendaPlaceholder} type="text" name="agenda" variant="outlined" value={this.state.scheduleForm.agenda} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleScheduleInputChange(event)} autoComplete="off"/>
        </Box>
        {this.state.fieldErrorCheck.agenda && (
            <TypoErrorWrapper>
              <Typography className="error">
                Please enter agenda
              </Typography>
            </TypoErrorWrapper>
        )}
      </Box>

      <Button
        data-test-id="schedule-button"
        className="schedule-button"
        onClick={this.handleSchedule}
      >
        {configJSON.scheduleText}
      </Button>
    </React.Fragment>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          isDrawer={true}
          isLogo={true}
          isNotification={true}
          fullWidthNavigation={true}
        />
        <ModalContainer
          disableEscapeKeyDown
          open={this.state.isModalOpen}
          onClose={this.handleClose}
        >
          <Box className="modal-container">{this.getModalContent()}</Box>
        </ModalContainer>
        <SchedulingWrapper>
          <SchedulingSection>
            <Box className="meeting-header-section">
              <Box>{configJSON.myMeetingsText}</Box>
              <Button data-test-id="meeting-schedule-button" onClick={this.handleScheduleMeeting}>
                {configJSON.schedulingMeetingText}
              </Button>
            </Box>
            <Box className="schedule-detail-section">
              {this.state.meetingDetails.length > 0 &&
                this.state.meetingDetails.map((meetingDetail) => (
                  <Box
                    key={meetingDetail.attributes.id}
                    className={`meeting-detail-container ${this.getDetailClassName(
                      meetingDetail.attributes.status
                    )}`}
                  >
                    <Box>
                      <Box className="meeting-time-text">
                        {this.formatTime(meetingDetail.attributes.time_range)}{" "}
                        <span>
                          {configJSON.scheduledOnText}{" "}
                          {this.formatDate(meetingDetail.attributes.date)}
                        </span>
                      </Box>
                      <Box className="meeting-title-text">
                        {meetingDetail.attributes.agenda}
                      </Box>
                    </Box>
                    <Box className="status-container">
                      {this.getStatusText(meetingDetail.attributes.status)}
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box className="button-wrapper">
              <Button onClick={this.handleScheduleMeeting}>
                {configJSON.schedulingMeetingText}
              </Button>
            </Box>
          </SchedulingSection>
        </SchedulingWrapper>
        <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
         <LiveChat />
        </Box>
      </React.Fragment>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SchedulingWrapper = styled(Box)({
  background: "#F3FAF7",
  minHeight: "100vh",
  padding: "30px",
  color: "#333",
  fontFamily: "Urbanist",
  fontWeight: 400,
  fontStyle: "normal",
  "@media only screen and (max-width: 640px)": {
    padding: "24px"
  }
});

const TypoErrorWrapper = styled(Typography)({
  "& .error": {
    color: "#F00",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "-19px"
  }
})

const SchedulingSection = styled(Box)({
  borderRadius: "20px",
  border: "1px solid rgba(1, 159, 72, 0.18)",
  background: "#FFF",
  boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
  padding: "30px",
  "@media only screen and (max-width: 640px)": {
    padding: "20px",
  },
  "& .meeting-header-section": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&>div": {
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: "30px",
      "@media only screen and (max-width: 640px)": {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px",
      }
    },
    "& button": {
      height: "40px",
      borderRadius: "8px",
      border: "1px solid #019F48",
      color: "#019F48",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      padding: "0 30px",
      fontFamily: "Urbanist",
      "@media only screen and (max-width: 640px)": {
        display: "none"
      }
    },
  },
  "& .schedule-detail-section": {
    margin: "30px 0 0",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "@media only screen and (max-width: 640px)": {
        margin: "20px 0 0",
        gap: "20px"
      },
  },
  "& .meeting-detail-container": {
    padding: "25px 30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    "@media only screen and (max-width: 640px)": {
        padding: "9px 12px",
        color: "#333"
    },
    "& .status-container": {
      color: "#FFF",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "30px",
      textTransform: "capitalize",
      height: "30px",
      padding: "0 22px",
      borderRadius: "70px",
      "@media only screen and (max-width: 640px)": {
        fontSize: "12px",
        fontWeight: 700,
      }
    },
  },
  "& .meeting-pending": {
    borderLeft: "3px solid #FD8D02",
    background: "#FFF8EF",
    "& .status-container": {
      background: "#FD8D02",
    },
  },
  "& .meeting-completed": {
    borderLeft: "3px solid #019F48",
    background: "#F5FFFB",
    "& .status-container": {
      background: "#019F48",
    },
  },
  "& .meeting-not-scheduled": {
    borderLeft: "3px solid #9E9E9E",
    background: "#F5F5F5",
    "& .status-container": {
      background: "#9E9E9E",
    },
  },
  "& .meeting-rejected": {
    borderLeft: "3px solid #FF1F1F",
    background: "#FFF3F3",
    "& .status-container": {
      background: "#FF1F1F",
    },
  },
  "& .meeting-time-text": {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    textTransform: "uppercase",
    display:'flex',
    gap: "10px",
    "@media only screen and (max-width: 640px)": {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "12px",
        display: "flex",
        gap: "8px",
        flexDirection: "column"
    },
    "&>span": {
      color: "#9E9E9E",
      fontSize: "16px",
      fontWeight: 500,
      textTransform: "none",
      "@media only screen and (max-width: 640px)": {
        fontSize: "12px",
        fontWeight: 400,
        opacity: 0.5,
      }
    },
  },
  "& .meeting-title-text": {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "35px",
    "@media only screen and (max-width: 640px)": {
        fontSize: "12px",
        fontWeight: 500,
        marginTop: "8px",
        lineHeight: "12px",
    }
  },
  "&>.button-wrapper": {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
    "&>button": {
        display: "none",
        "@media only screen and (max-width: 640px)": {
            display: "block",
            height: "40px",
            borderRadius: "8px",
            border: "1px solid #019F48",
            color: "#019F48",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 600,
            padding: "0 30px",
            fontFamily: "Urbanist",
        }
      },
    }
});

const ModalContainer = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#333",
  fontFamily: "Urbanist",
  fontStyle: "normal",
  fontWeight: 400,
  "@media only screen and (max-width: 640px)": {
    color: "#292D32",
  },
  "& .pointer": {
    cursor: "pointer",
  },
  "& .modal-container": {
    margin: "0 25px",
    background: "#FFF",
    borderRadius: "20px",
    boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.10)",
    padding: "50px",
    width: "100%",
    maxWidth: "610px",
    maxHeight:'645px',
    "@media only screen and (max-width: 640px)": {
      padding: "24px",
    },
  },
  "& .modal-title-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    "@media only screen and (max-width: 640px)": {
      marginBottom: "8px",
    },
  },
  "& .schedule-title-text": {
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "35px",
    "@media only screen and (max-width: 640px)": {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "18px",
    },
  },
  "& .close-icon": {
    height: "16px",
    width: "16px",
    "@media only screen and (max-width: 640px)": {
      height: "12px",
      width: "12px",
    },
  },
  "& .schedule-description-text": {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "34px",
    "@media only screen and (max-width: 640px)": {
      color: "#333",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "19px",
      opacity: 0.8,
    },
  },
  "& .schedule-button": {
    fontFamily: "Urbanist",
    borderRadius: "12px",
    marginTop: "30px",
    height: "56px",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#FFF",
    background: "#019F48",
    fontWeight: 600,
    width: "100%",
    textTransform: "none",
    "@media only screen and (max-width: 640px)": {
      marginTop: "24px",
    },
  },
  "& .password-error": {
    "& .Mui-focused, .MuiOutlinedInput-root": {
      borderColor: "#FF0000 !important",
    },
  },
  "& .change-password-desktop": {
    display: "none",
    "@media only screen and (min-width: 641px)": {
      display: "inline",
    },
  },
  "& .change-password-mobile": {
    display: "none",
    "@media only screen and (max-width: 640px)": {
      display: "inline",
    },
  },
  "& .form-container": {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    "&>div": {
        width: "100%",
        marginBottom: "20px",
        "& label": {
            lineHeight: "35px",
            display: "block",
            fontWeight: 500,
            fontSize: "18px",
            "@media only screen and (max-width: 640px)": {
              fontSize: "16px",
            }
        },
        "& .MuiOutlinedInput-input": {
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            padding: "18.5px 20px",
            height: "56px",
            color: "#333",
            fontFamily: "Urbanist",
            lineHeight: "35px",
            "@media only screen and (max-width: 640px)": {
              fontSize: "16px",
              color: "#363A3F",
              fontWeight: 500,
              padding: "16px"
            }
          },
          "& .Mui-focused": {
            borderColor: "#019F48 !important"
          },
          "& .MuiTextField-root": {
            width: "100%",
          },
          "& .MuiSelect-root": {
            display: "flex",
            alignItems: "center",
            background: "transparent",
            paddingLeft: "20px"
          },
          "& .time-placeholder": {
            color: "#9E9E9E",
            fontFamily: "Urbanist",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "35px",
            "@media only screen and (max-width: 640px)": {
              fontSize: "16px",
              fontWeight: 500
            }
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "12px",
            border: "1px solid #CEE1D6",
            background: "#F4FAF7",
            height: "56px",
            width: "100%",
            "@media only screen and (max-width: 640px)": {
              height: "50px",
              borderRadius: "8px"
            }
          },
          "& .input-error": {
            "& .Mui-focused, .MuiOutlinedInput-root": {
              borderColor: "#FF0000 !important"
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0) !important"
          },
    }
  },
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    background: `url(${calendarIcon}) no-repeat`,
    height: "24px",
    width: "24px"
  },
});
// Customizable Area End
