import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";

interface ScheduleFormData {
  date: string;
  time_range: string;
  agenda: string;
}

interface MeetingDetailsData {
  id: string;
  type: string;
  attributes: {
    id: number;
    time_range: string;
    date: string;
    agenda: string;
    status: string;
  }
}

export interface FieldErrors {
  date: boolean;
  time: boolean;
  agenda: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: string[];
  details: any;
  token: any;
  isModalOpen: boolean;
  scheduleForm: ScheduleFormData;
  meetingDetails: MeetingDetailsData[];
  fieldErrorCheck: FieldErrors;
  timeSlotErrorTxt: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  getMeetingDetailsApiId: any;
  getSlotDetailsApiId: any;
  scheduleMeetingApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      fieldErrorCheck: {
        date: false,
        time: false,
        agenda: false
      },
      timeSlotErrorTxt:'',
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      details: {},
      token: null,
      isModalOpen: false,
      scheduleForm: {
        date: "",
        time_range: "",
        agenda: ""
      },
      meetingDetails: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    let token = await getStorageData('authToken');
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.setState({ token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.getSlotDetailsApiId){
          let timeSlots = responseJson.data.map((item: { attributes: { time_range: string[]; }; }) => item.attributes.time_range);
          this.setState({timeSlots})
        }
        if (apiRequestCallId === this.getMeetingDetailsApiId) {
          this.setState({ meetingDetails: responseJson.data.sort((a:any, b:any) =>  b.id - a.id)})
        }
        if (apiRequestCallId === this.scheduleMeetingApiId) {
          this.handleCloseModal();
          this.getMeetingDetails();
        }
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTimeSlots();
    this.getMeetingDetails();
  }

  getTimeSlots=async()=>{
    const token = await getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSlotDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSlotDetailsApiId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMeetingDetails = async() => {
    const token = await getStorageData('authToken');

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMeetingDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMettingDetailAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDetailClassName = (status: string) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "meeting-pending";
      case "completed":
        return "meeting-completed";
      case "not_scheduled":
        return "meeting-not-scheduled";
      case "reschedule":
        return "meeting-rejected";
    }
  }

  getStatusText = (status: string) => status === "not_scheduled" ? "Not Scheduled" : status;

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, scheduleForm: {date:'', time_range:'', agenda:''},fieldErrorCheck:{time:false, date: false, agenda:false}, timeSlotErrorTxt:'' })
  }

  handleScheduleMeeting = () => this.setState({ isModalOpen: true })

  handleClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick") {
      this.handleCloseModal();
    }
  };

  handleScheduleInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const selectedDate = name==="date" ? event.target.value : this.state.scheduleForm.date;
    if(this.isTimeslotInPast(this.state.scheduleForm.time_range, selectedDate)){
      this.setState({
      timeSlotErrorTxt: "Please select valid time",
      fieldErrorCheck: { ...this.state.fieldErrorCheck, time: true },
      scheduleForm: { ...this.state.scheduleForm, [name]: value },
      });
    } else {
    this.setState({
      scheduleForm: { ...this.state.scheduleForm, [name]: value },
      fieldErrorCheck: { ...this.state.fieldErrorCheck, [name]: false },
    });
  }
  };

  isTimeslotInPast(timeslot: any, selectedDate: string): boolean {
    const [startTime] = timeslot.split("-");
    const now = new Date();
    const selectedDateObj = new Date(selectedDate);
    const isToday = now.toDateString() === selectedDateObj.toDateString();
    if (!isToday) {
      return false;
    }
    const period = startTime.slice(-2);
    let [hours, minutes] = startTime.slice(0, -2).trim().split(':').map(Number);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    const timeslotDate = new Date(selectedDateObj);
    timeslotDate.setHours(hours);
    timeslotDate.setMinutes(minutes);
    timeslotDate.setSeconds(0);
    timeslotDate.setMilliseconds(0);
    return now > timeslotDate;
  }
  

  handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    if(this.isTimeslotInPast(event.target.value, this.state.scheduleForm.date)){
      this.setState({
        fieldErrorCheck: { ...this.state.fieldErrorCheck, time: true},
        timeSlotErrorTxt: "Please select valid time",
        scheduleForm: { ...this.state.scheduleForm, time_range: '' }
      })
    }else{
    this.setState({
      scheduleForm: { ...this.state.scheduleForm, time_range: event.target.value as string },
      fieldErrorCheck: { ...this.state.fieldErrorCheck, time: false },
      timeSlotErrorTxt: ""
    });}
  }

  formatDate = (inputDate: string) => {
    return moment(inputDate, 'YYYY-MM-DD').format('D MMM YYYY').toLowerCase()
  }

  formatTime = (inputTime: string) => {
    return inputTime
      .replace(/([APap]M)/gi, ' $1')
      .replace(/-/g, ' - ');
  }

  isInvalidValue(str: string) {
    return str === null || str.length === 0;
  }
  
  checkErrorState(obj: FieldErrors) {
    return Object.values(obj).every(item => item === false);
  }
  
  handleValidationFun = () => {
    const { date, time_range, agenda } = this.state.scheduleForm;
    const newFieldErrorCheck = { ...this.state.fieldErrorCheck };
    let timeSlotErrorTxt = "";
    newFieldErrorCheck.date = this.isInvalidValue(date);
    if (this.isInvalidValue(time_range)) {
        newFieldErrorCheck.time = true;
        timeSlotErrorTxt = "Please select time";
    } else if (this.isTimeslotInPast(time_range, date)) {
        newFieldErrorCheck.time = true;
        timeSlotErrorTxt = "Please select valid time";
    } else {
        newFieldErrorCheck.time = false;
    }
    newFieldErrorCheck.agenda = this.isInvalidValue(agenda);
    this.setState({ 
        fieldErrorCheck: newFieldErrorCheck,
        timeSlotErrorTxt
    });
    const hasErrors = !this.checkErrorState(newFieldErrorCheck);
    return !hasErrors;
  }
  

  handleSchedule = () => {
  if(this.handleValidationFun()){
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token: this.state.token,
    };

    const { date, time_range, agenda } = this.state.scheduleForm;

    const httpBody = {
      date,
      time_range,
      agenda
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scheduleMeetingApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleMeetingAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }else {
    return;
   }
} 
  // Customizable Area End
}