export const imgEmailIcon = require('../assets/mailImg.png');
export const imgPhoneIcon = require('../assets/phoneIcon.png');
export const imgmapIcon = require('../assets/mapIcon.png');
export const viewLineImg = require('../assets/viewLineImg.png');
export const underLineImg = require('../assets/underLine.png');
export const groupImg = require("../assets/image_Group.png");
export const arrowDownImg = require("../assets/arrow-down.svg");
export const youngWomalImg = require("../assets/Young_woman_holding.png");
export const successImg = require("../assets/success.svg");
export const quotationStartImg = require("../assets/quotation_start.svg");
export const quotationEndImg = require("../assets/quotation_end.svg");
export const whiteCheckBox = require("../assets/white_checked.svg");
export const greenCheckBox = require("../assets/green_checked.svg");
export const rightManImg = require("../assets/right_laptop_man.svg");
export const aboutUsImg = require("../assets/about_us.svg");
export const rightLaptopImg = require("../assets/right_laptop_man.svg");
export const whoWeAreImg = require("../assets/who_we_are.svg");
export const aboutUsBackgroundImg = require("../assets/about_us_background.svg");
export const whiteUnderlineImg = require("../assets/white_underline.svg");
export const nextArrowGrey = require("../assets/next-arrow-grey.svg");
export const backArrowGrey = require("../assets/back-arrow-grey.svg");
export const nextArrow = require("../assets/next-arrow.svg");
export const backArrow = require("../assets/back-arrow.svg");
export const fullUnderLine = require("../assets/full_underline.svg");
export const backgroundPattern = require("../assets/image_pattern.svg");