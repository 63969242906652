import React from "react";

//Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel, TextField, styled
} from "@material-ui/core";
import NewPasswordController, { Props, configJSON } from "./NewPasswordController";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { activeIcon, notActiveIcon } from "./assets";
//Customizable Area End
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    //Customizable Area End
  }

  //Customizable Area Start
  getErrorContainer = (isError: boolean, text: string) => (
    <Box className="error-text-section">
      <img src={isError ? notActiveIcon : activeIcon} alt="" />
      <Box style={{ color: isError ? '#F00' : '#333' }}>{text}</Box>
    </Box>
  )
  //Customizable Area End

  render() {
    //Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container style={{padding:'0px',maxWidth:'100%'}}>
          <ResetPasswordWrapper>
          <Box
             className="resetMainView" width={'100%'}
          >
            <Box
               className="resetLeftView"
            >
            <TypograyView>
               <Typography variant="subtitle1" component="div" style={{ paddingTop: 20 }} className="resetLabelTitleStyle">
                {'Create New Password'} {/*UI Engine::From Sketch*/}
              </Typography>
              <Typography component="div" style={{ paddingTop: 10 }} className="resetLabelSubTitleStyle">
                Your new password must be different from  <br />previously used password. {/*UI Engine::From Sketch*/}
              </Typography>
             <Typography className="labelTitleStyle">&#128272; Reset Password</Typography>
              <Typography style={{ paddingTop: 30 }} className="labelSubTitleStyle">Create your new password</Typography>
              </TypograyView>
              <img {...this.imgVerticalLineProps} className="regVerticaliLine"/>  
              <Box
               className="resetInfoView"
                bgcolor={'#EEFFF8'}
              >
                <img  {...this.imgGraphProps} className="graphLoginImage"/>
                <img  {...this.imgWatcProps} className="watchImage" />
                <img  {...this.imgUploadDocProps} className="imageLoginUpload"/>
              </Box>
            </Box>
            <Box
               className="resetRightView"
            >
              <img  {...this.imgCreditLiftProps} className="creditLiftLogo" />
              <TypograyView>
              <Typography variant="subtitle1" component="div" style={{ paddingTop: 20 }} className="resetTitleStyle">
                {'Create New Password'} {/*UI Engine::From Sketch*/}
              </Typography>
              <Typography component="div" style={{ paddingTop: 10 }} className="resetSubTitleStyle">
                Your new password must be different from  <br />previously used password. {/*UI Engine::From Sketch*/}
              </Typography>
              </TypograyView>
              <Box sx={{ padding: "20px 0px", position: "relative" }} data-test-id="changePasswordForm">
                <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabel">{'New Password'}</InputLabel>
                <TextField
                  variant="outlined"
                  error={this.state.passwordError}
                  data-test-id="txtInputPassword"
                  type={this.state.enablePasswordField ? "password" : "text"}
                  placeholder={configJSON.newPasswordLabel}
                  fullWidth={true}
                  value={this.state.password}
                  onBlur={this.popUpClose}
                  onFocus={this.popUpOpen}
                  onChange={this.setPassword}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        dast-test-id="passwordShowHideIcon"
                        edge="end"
                      >
                        {this.state.enablePasswordField ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>,
                    style: {
                      marginTop: 8,
                      backgroundColor: '#F4FAF7'
                    }
                  }} />
                {this.state.samePasswordError && <Box className="error-text"><Typography>{configJSON.newPasswordErrorText}</Typography></Box>}
                {(this.state.passwordError && !this.state.errorPopUpShow) && <Box className="error-text"><Typography>{this.getPasswordText()}</Typography></Box>}
                {this.state.passwordError && this.state.errorPopUpShow && (<PasswordTextErrorWrap>
                  <PasswordTextErrorWrapper>
                    <Box>{configJSON.passwordTextErrorHeading}</Box>
                    {this.getErrorContainer(this.state.passwordErrorData.characterError, configJSON.passwordTextCharacterError)}
                    {this.getErrorContainer(this.state.passwordErrorData.smallCharacterError, configJSON.passwordTextCharacterSmallError)}
                    {this.getErrorContainer(this.state.passwordErrorData.capitalCharacterError, configJSON.passwordTextCapitalError)}
                    {this.getErrorContainer(this.state.passwordErrorData.numberSymbolError, configJSON.passwordTextNumberSymbolError)}
                  </PasswordTextErrorWrapper>
                </PasswordTextErrorWrap>)}
              </Box>
              <Box sx={{ padding: "20px 0px" }}>
                <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabel">{'Confirm New Password'}</InputLabel>
                <TextField
                  variant="outlined"
                  error={this.state.confirmPasswordError}
                  data-test-id="txtInputConfirmPassword"
                  type={this.state.enableConfirmPasswordField ? "password" : "text"}
                  placeholder={'Confirm New Password'}
                  fullWidth={true}
                  value={this.state.confirmPassword}
                  onChange={(e:any) => this.setConfirmPassword(e)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                          onClick={this.handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {this.state.enableConfirmPasswordField ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>,
                    style: {
                      marginTop: 8,
                      backgroundColor: '#F4FAF7'
                    }
                  }} />
             {(this.state.confirmPasswordError) && <Box className="error-text"><Typography>{configJSON.passwordNotMatchError}</Typography></Box>}

              </Box>
                <Button
                  data-test-id={"btnResetPassword"}
                  variant="contained"
                  color="secondary"
                  className="submitButton"
                  fullWidth
                  onClick={this.btnSubmit}
                  style={{ background: '#019F48' }}
                >
                  Reset Password {/*UI Engine::From Sketch*/}
                </Button>
              {/* </Box> */}
              <Box sx={{ fontSize: 14 }} pt={6} pb={6} color={'#333333'}>
                <TypograyView>
                <Typography className="reserved">© Credit Lift. All Rights Reserved</Typography>
                </TypograyView>
              </Box>
            </Box>
          </Box>
          </ResetPasswordWrapper>
        </Container>
      </ThemeProvider>
    );
    //Customizable Area End
  }
}

//Customizable Area Start

const PasswordTextErrorWrap = styled(Box)({
  position: "absolute", 
  right: 0,
  top: "110px", 
  zIndex: 111,
})

const PasswordTextErrorWrapper = styled(Box)({
  borderRadius: "22px",
  padding: "20px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "25px",
  border: "1px solid rgba(1, 159, 72, 0.18)",
  boxShadow: "0px 8px 30px rgba(11, 109, 55, 0.10)",
  background: "#FFF",
  fontFamily: "Urbanist",
  '& .error-text-section': {
    alignItems: "center",
    display: "flex",
    gap: "10px",
  }
});

const ResetPasswordWrapper = styled(Box)({
  '& .resetMainView': {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    "@media only screen and (max-width: 767px)" : {
      flexDirection: "column",
      display: "flex",
    }

  },
  '& .error-text': {
    color: "#F00",
    marginTop: "4px"
  },
  '& .resetLeftView': {
    paddingBottom: '0px',
    paddingLeft: '30px', 
    flexDirection: "column",
    display: "flex",
    width:'40%',
    alignItems: "flex-start",
    top: "100px",
    paddingTop: '56px',
    paddingRight: '30px',
    backgroundColor: '#EEFFF8',
"@media only screen and (max-width: 767px)" : {
  paddingTop: '20px',
    paddingRight: '30px',
    paddingBottom: '0px',
    paddingLeft: '30px',
    alignSelf:'center',
    backgroundColor: '#FFFF !important',
   
}

  },
  '& .resetInfoView':{
    flexDirection: "column",
    alignItems: "flex-start",
    position:'relative',
    display: "flex",
    marginTop:'35px',
    "@media only screen and (max-width: 767px)" : {
      backgroundColor: '#FFFF !important',

    }
  },
  '& .resetRightView':{
     paddingTop: '56px',
      display: "flex",
      flexDirection: "column",
      paddingRight: '112px',
      paddingBottom: '0px',
      paddingLeft: '72px',
      fontFamily:'Urbanist',
      width:'60%',
      "@media only screen and (max-width: 767px)" : {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: '#FFFF',
        width:'85%'
      }
      
  },
  '& .reserved':{
    fontSize:'12px',
    fontFamily:'Urbanist',
    textAlign:'center',
},
'& .inputeLabel':{
    color:'#333',
    fontFamily:'Urbanist',
    fontSize:'18px',
    fontWeight:'500',
    marginBottom: '8px',
},
'& .submitButton':{
  marginTop: "10px",
  textTransform:'none',
  display: "flex",
  height: "56px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px",
  background: "#019F48",
  color: "#FFF",
  fontFamily: "Urbanist",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px",
  "@media only screen and (max-width: 767px)" : {

  }
}
})
const TypograyView = styled(Typography)({
  '& .labelTextStyle':{
    display:'none !important',
    "@media only screen and (max-width: 768px)" : {
      fontSize: '20px',
      fontFamily:'Urbanist',
      display:'block',
      color:'#333',
      alignSelf:'center',
    }
  
  },
  '& .labelSubTitleStyle':{
    color:'#333',
    fontFamily:'Urbanist',
    fontSize:'26px',
    "@media only screen and (max-width: 767px)" : {
      display:'none !important',
    }
  },
  '& .resetLabelTitleStyle':{
    display: 'none',
    "@media only screen and (max-width: 767px)" : {
      alignSelf:'center',
      display:'block',
      fontSize: '20px',
      fontFamily:'Urbanist',
      color:'#292D32',
      fontWeight:'500'
    },
  },
  '& .resetTitleStyle':{
    fontSize: '38px',
    fontFamily:'Urbanist',
    color:'#292D32',
    fontWeight: 700,
    "@media only screen and (max-width: 767px)" : {
        display:'none !important',
    }
  },
  '& .resetSubTitleStyle':{
    fontFamily:'Urbanist',
    fontSize: '26px',
    color:'#292D32',
    marginBottom: "20px",
    "@media only screen and (max-width: 767px)" : {
        display:'none !important',
    }
  },
  '& .resetLabelSubTitleStyle':{
    display:'none !important',
    "@media only screen and (max-width: 767px)" : {
        display: 'block !important',
        fontSize: '12px',
        alignSelf:'center',
        textAlign:'center',
        fontFamily:'Urbanist',
        color:'#292D32'
    }
  },
  '& .labelTitleStyle':{
    fontSize:'38px',
    fontFamily:'Urbanist',
    color:'#333',
    paddingTop:'15px',
    "@media only screen and (max-width: 767px)" : {
      display:'none !important',
    }
  },
  '& .labelSubTextStyle':{
    display:'none !important',
    "@media only screen and (max-width: 767px)" : {
      fontFamily:'Urbanist',
      display:'block',
      fontSize: '12px',
      color:'#292D32',
      alignSelf:'center',
    }
  },
  '& .reserved':{
    fontFamily:'Urbanist',
    fontSize:'12px',
    textAlign:'center',
}
})

//Customizable Area End
