import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  InputLabel,
  RadioGroup,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { activeIcon, notActive } from "./assets";
import Loader from "../../../components/src/Loader.web";

const configJSON = require("./config");
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationWebController";
import { error } from "console";

const theme = createTheme({
  palette: {
    primary: {
      main: "#019f48",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getErrorData = (isError: boolean, text: string) => (
    <Box className="error-text-section">
      <img src={isError ? notActive : activeIcon} alt="" />
      <Box style={{ color: isError ? "#F00" : "#333" }}>{text}</Box>
    </Box>
  );

  getEmailError = () => {
    return this.state.fieldError.emailError ||
      this.state.emailAlreadyExistError ? (
      <TypograyView>
        <Typography className="error">
          {this.state.emailAlreadyExistError
            ? configJSON.emailExistErrorText
            : configJSON.emailErrorPlaceholder}
        </Typography>
      </TypograyView>
    ) : null;
  };
  getBorderColor = (param: string) => {
    if (this.state.fieldError.userTypeError) {
        return "1px solid red";
    } else {
        return `1px solid ${this.state.userType === param ? '#019F48' : '#CEE1D6'}`;
    }
  }
  getCheckBoxTextColor = (param: string) => {
    if (this.state.userType) {
      return  `${this.state.userType === param ? '#019F48' : '#CEE1D6'}`;
    }else{
      return '#CEE1D6';
    }
  }
  getClassName = (errorState: boolean) => {
    return errorState ? "input-error" : "";
  }
  getEmailClassName = () => {
    if (this.state.fieldError.emailError || this.state.emailAlreadyExistError) {
      return "input-error";
    }
    return "";
  }
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container style={{ padding: "0px", maxWidth: "100%" }} ref={this.topRef}>
          <Loader loading={this.state.loadingSignup} />
          <RegistrationWrapper>
            <Box className="mainView" width={"100%"}>
              <Box className="leftView">
                <TypograyView>
                  <Typography
                    variant="h4"
                    component="h2"
                    className="labelTextStyle"
                  >
                    {this.labelTitle}
                  </Typography>
                  <Typography className="labelSubTextStyle">
                    {this.labelHeader} {/*UI Engine::From Sketch*/}
                  </Typography>
                  <Box style={{ flexDirection: "row", display: "flex" }}>
                    <span className="handEmoji">&#128075;</span>{" "}
                    <Typography className="welcomeText"> Welcome !</Typography>
                  </Box>
                </TypograyView>
                <img
                  {...this.imgVerticalLineProps}
                  alt="greenline"
                  className="regVerticaliLine"
                />
                <TypograyView>
                  <Typography className="textStyle">
                    To keep connected with us please enter <br/>your personal information.
                  </Typography>
                </TypograyView>

                <Box className="infoView" width={"100%"}>
                  <img
                    {...this.imagGraphProps}
                    alt="graphimage"
                    className="graphLoginImage"
                    style={{ width: "309px", height: "309px" }}
                  />
                  <img
                    {...this.imgWatcProps}
                    alt="watchimage"
                    className="regwatchImage"
                    style={{
                      width: "309px !important",
                      height: "309px !important",
                    }}
                  />
                  <img
                    {...this.imgUploadProps}
                    alt="uploadimage"
                    className="imageLoginUpload"
                    style={{ width: "384px", height: "309px" }}
                  />
                </Box>
              </Box>
              <Box className="rightView">
                <img
                  {...this.imageCreditLiftProps}
                  alt="creditliftlogo"
                  className="creditLiftLogo"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0px",
                  }}
                >
                  <TypograyView>
                    <Typography
                      variant="h4"
                      component="h2"
                      className="labelTitleStyle"
                    >
                      {this.labelTitle}
                    </Typography>
                    <Typography className="labelSubTitleStyle">
                      {this.labelForSignup} {/*UI Engine::From Sketch*/}
                    </Typography>
                  </TypograyView>
                  <Box
                    sx={{ padding: "12px 0px", marginTop: "10px" }}
                    width={"100%"}
                  >
                    <Box className="inputeSubView">
                      <InputLabelWrapper>
                        <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle">{'Sign Up Type'}</InputLabel>
                      </InputLabelWrapper>
                      <RadioGroup
                        data-testid ="userTypeRadioGroup"
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        value={this.state.userType}
                        style={{ alignItems: 'center', justifyContent: 'space-between',gap:'20px',marginBottom:'25px' }}
                        onChange={(e) => this.setUserType(e.target.value)}
                      >
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '0px',
                          border: this.getBorderColor('User'),
                          borderRadius: 12,
                          padding: '5px 10px',
                          bgcolor: '#F4FAF7',
                          color: this.getCheckBoxTextColor('User'),
                          flexGrow:1,
                        }}
                          mt={2.5}

                          borderColor={'#CEE1D6'}>
                          <FormControlLabel
                          data-testid="formControlLabel"
                            value="User"
                            control={<Checkbox icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />} color="primary" checked={this.state.userType === 'User'}
                              onChange={() => this.setUserType('User')} />}
                            label="User"
                            labelPlacement="start"
                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%"}}

                          />
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '0px',
                          border: this.getBorderColor('Agent'),
                          borderRadius: 12,
                          padding: '5px 10px',
                          bgcolor: '#F4FAF7',
                          color: this.getCheckBoxTextColor('Agent'),
                          flexGrow:1,


                        }}
                          mt={2.5}
                          borderColor={'#CEE1D6'} >

                          <FormControlLabel
                            value="Agent"
                            control={<Checkbox icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />} color="primary" checked={this.state.userType === 'Agent'}
                              onChange={() => this.setUserType('Agent')} />}
                            label="Agent"
                            labelPlacement="start"
                            style={{display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%"}}


                          />
                        </Box>
                      </RadioGroup>
                      {this.state.fieldError.userTypeError && (
                      <Typography variant="body2" color="error" style={{ fontSize:'18px',fontWeight:500, marginTop:-18, marginBottom:10 , fontFamily:'Urbanist'}}>
                        Please select any role
                      </Typography>
                      )}

                    </Box>
                  

                    <InputLabel
                      htmlFor="outlined-adornment-amount"
                      style={{
                        fontFamily: "Urbanist",
                        fontSize: "18px",
                        color: "black",
                      }}
                      className="inputLabel"
                    >
                      {"Name"}
                    </InputLabel>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      data-test-id="txtInputName"
                      value={this.state.name}
                      id={"name"}
                      onChange={this.handleInputeChange}
                      placeholder={"Name"}
                      className={this.getClassName(this.state.fieldError.nameError)}
                      error={this.state.fieldError.nameError}
                      InputProps={{
                        style: {
                          marginTop: 8,
                          backgroundColor: "#F4FAF7",
                          borderRadius: 12,
                          fontFamily: "Urbanist",
                          fontSize: "18px",
                        },
                      }}
                      type="text"
                    />
                    {this.state.fieldError.nameError && (
                      <TypograyView>
                        <Typography data-test-id="name-error" className="error">
                          {this.getErrorName()}
                        </Typography>
                      </TypograyView>
                    )}
                  </Box>
                  <Box sx={{ padding: "12px 0px" }} width={"100%"}>
                    <InputLabel
                      htmlFor="outlined-adornment-amount"
                      style={{
                        fontFamily: "Urbanist",
                        fontSize: "18px",
                        color: "black",
                      }}
                      className="inputLabel"
                    >
                      {"Email"}
                    </InputLabel>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      data-test-id="txtInputEmail"
                      value={this.state.email}
                      className={this.getEmailClassName() }
                      error={this.isEmailError()}
                      id="email"
                      onChange={this.handleInputeChange}
                      placeholder={"Email"}
                      InputProps={{
                        style: {
                          marginTop: 8,
                          backgroundColor: "#F4FAF7",
                          borderRadius: 12,
                          fontFamily: "Urbanist",
                          fontSize: "18px",
                        },
                      }}
                    />
                    {this.getEmailError()}
                    {this.state.fieldError.processError && <TypograyView><Typography className="error">Email already Exist</Typography></TypograyView>}
                  </Box>
                  <Box sx={{ padding: "12px 0px" }} width={"100%"}>
                    <InputLabel
                      htmlFor="outlined-adornment-amount"
                      style={{
                        fontFamily: "Urbanist",
                        fontSize: "18px",
                        color: "black",
                      }}
                      className="inputLabel"
                    >
                      {"Phone Number"}
                    </InputLabel>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      data-test-id="txtPhoneNumber"
                      value={this.state.phone}
                      className={this.getClassName(this.state.fieldError.phoneError)}
                      error={
                        this.state.fieldError.phoneError &&
                        this.state.phone.length !== 10
                      }
                      id="phoneNumber"
                      onChange={this.handleInputeChange}
                      placeholder={"Phone Number"}
                      InputProps={{
                        style: {
                          marginTop: 8,
                          backgroundColor: "#F4FAF7",
                          borderRadius: 12,
                          fontFamily: "Urbanist",
                          fontSize: "18px",
                        },
                      }}
                    />
                    {this.state.fieldError.phoneError &&
                      this.state.phone.length !== 10 && (
                        <TypograyView>
                          {" "}
                          <Typography className="error">
                            {this.state.phone.length < 10
                              ? configJSON.phoneErrorMaximumText
                              : configJSON.phoneErrorNotExceedText}
                          </Typography>
                        </TypograyView>
                      )}
                  </Box>
                  <Box sx={{ padding: "12px 0px", position: "relative" }}>
                    <InputLabel
                      htmlFor="outlined-adornment-amount"
                      style={{
                        fontFamily: "Urbanist",
                        fontSize: "18px",
                        color: "black",
                      }}
                      className="inputLabel"
                    >
                      {"Password"}
                    </InputLabel>
                    <TextField
                      autoComplete="off"
                      onBlur={() => this.setState({ passwordErrorShow: false })}
                      onFocus={() => this.setState({ passwordErrorShow: true })}
                      variant="outlined"
                      data-test-id="txtInputPassword"
                      type={
                        this.state.enablePasswordField ? "password" : "text"
                      }
                      placeholder={"Password"}
                      className={this.getClassName(this.state.fieldError.passwordError.character)}
                      error={Object.values(
                        this.state.fieldError.passwordError
                      ).includes(true)}
                      fullWidth={true}
                      value={this.state.password}
                      id="password"
                      onChange={this.handleInputeChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="pwd"
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          marginTop: 8,
                          backgroundColor: "#F4FAF7",
                          borderRadius: 12,
                          fontFamily: "Urbanist",
                          fontSize: "18px",
                        },
                      }}
                    />
                    {this.state.passwordError &&
                      !this.state.passwordErrorShow && (
                        <TypograyView>
                          <Typography className="error">
                            {this.state.password.length > 0
                              ? configJSON.validPasswordErrorText
                              : configJSON.passwordErrorText}
                          </Typography>
                        </TypograyView>
                      )}
                    {this.isPasswordError() && (
                      <PasswordErrorTextContainer>
                        <PasswordErrorTextSection>
                          <Box>{configJSON.passwordErrorHeading}</Box>
                          {this.getErrorData(
                            this.state.fieldError.passwordError.character,
                            configJSON.passwordCharacterText
                          )}
                          {this.getErrorData(
                            this.state.fieldError.passwordError.smallCharacter,
                            configJSON.passwordCharacterSmall
                          )}
                          {this.getErrorData(
                            this.state.fieldError.passwordError
                              .capitalCharacter,
                            configJSON.passwordCapitalText
                          )}
                          {this.getErrorData(
                            this.state.fieldError.passwordError.numberSymbol,
                            configJSON.passwordNumberSymbolText
                          )}
                        </PasswordErrorTextSection>
                      </PasswordErrorTextContainer>
                    )}
                  </Box>
                  <Box sx={{ padding: "12px 0px" }}>
                    <InputLabel
                      htmlFor="outlined-adornment-amount"
                      style={{
                        fontFamily: "Urbanist",
                        fontSize: "18px",
                        color: "black",
                      }}
                      className="inputLabel"
                    >
                      {this.labelRePassword}
                    </InputLabel>
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      data-test-id="txtInputConfirmPassword"
                      type={
                        this.state.enableRePasswordField ? "password" : "text"
                      }
                      placeholder={this.labelRePassword}
                      fullWidth={true}
                      value={this.state.reTypePassword}
                      className={this.getClassName(this.state.fieldError.confirmPasswordError)}
                      error={this.state.fieldError.confirmPasswordError}
                      id="confirmPassword"
                      onChange={this.handleInputeChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="confirmPwd"
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowRePassword}
                              edge="end"
                            >
                              {this.state.enableRePasswordField ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          marginTop: 8,
                          backgroundColor: "#F4FAF7",
                          borderRadius: 12,
                          fontFamily: "Urbanist",
                          fontSize: "18px",
                        },
                      }}
                    />
                    {this.state.fieldError.confirmPasswordError && (
                      <TypograyView>
                        <Typography className="error">
                          {configJSON.passwordNotMatch}
                        </Typography>
                      </TypograyView>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        marginTop: "5px",
                        flexDirection: "row",
                        display: "flex",
                        marginBottom: "5px",
                      }}
                    >
                      <Checkbox
                        data-test-id={"btnOnAccept"}
                        onClick={this.onAccept}
                        checked={this.state.checked}
                        className={"checkBox accept-check"}
                        style={{ padding: "9px 9px 9px 0px" }}
                      />{" "}
                      <Box
                        pt={1}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "400",
                          marginTop: "-8px",
                          flexDirection: "row",
                          display: "flex",
                          justifyContent:'center',
                          alignItems:'center',
                          border: "0px !important",
                        }}
                      >
                        {" "}
                        I Accept all{" "}
                        <Box
                          color={"#333"}
                          sx={{
                            marginLeft: "8px",
                            fontFamily: "Urbanist",
                            fontSize: "18px",
                          }}
                          className="pointer"
                          data-test-id="terms-and-conditions-button"
                          onClick={this.handleTermsAndConditions}
                        >
                          {" "}
                          terms and conditions
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {this.state.fieldError.acceptTermsAndCondition && (
                    <TypograyView>
                      <Typography
                        className="error"
                        style={{ padding: "0 0 20px" }}
                      >
                        {configJSON.termsConditionErrorText}
                      </Typography>
                    </TypograyView>
                  )}
                  <Button
                    data-test-id={"btnSignUp"}
                    variant="contained"
                    fullWidth
                    onClick={() => this.createAccount()}
                    style={{
                      backgroundColor: "#019F48",
                      borderRadius: "12px",
                      paddingTop: "9px",
                      marginTop: "10px",
                      textTransform: "none",
                    }}
                  >
                    {this.btnTextSignUp} {/*UI Engine::From Sketch*/}
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "25px 10px 0px",
                      fontSize: 18,
                      border: "0px solid white !important",
                    }}
                  >
                    {"Already have an account? "}
                    <Box
                      sx={{
                        color: "#019F48",
                      }}
                      data-test-id={"btnLogIn"}
                      onClick={this.navigateToLogin}
                      className="pointer"
                    >
                      Login
                    </Box>
                  </Box>
                  <Box sx={{ fontSize: 14 }} pt={5} color={"#333333"}>
                    <TypograyView>
                      <Typography className="reserved">
                        © Credit Lift. All Rights Reserved
                      </Typography>
                    </TypograyView>
                  </Box>
                </Box>
              </Box>
            </Box>
          </RegistrationWrapper>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PasswordErrorTextSection = styled(Box)({
  borderRadius: "22px",
  padding: "20px",
  border: "1px solid rgba(1, 159, 72, 0.18)",
  boxShadow: "0px 8px 30px rgba(11, 109, 55, 0.10)",
  background: "#FFF",
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "25px",
  "& .error-text-section": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
});

const PasswordErrorTextContainer = styled(Box)({
  position: "absolute",
  right: 0,
  top: "94px",
  zIndex: 111,
});

const RegistrationWrapper = styled(Box)({
  "& .pointer": {
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingLeft:"5px"
  },
  "& .mainView": {
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 900px)": {
      flexDirection: "column",
      display: "flex",
    },
  },
  "& .leftView": {
    width :"700px",
    paddingTop: "56px",
    paddingRight: "72px",
    paddingBottom: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    top: "100px",
    backgroundColor: "#EEFFF8",
    paddingLeft: "40px",
    "@media only screen and (max-width: 900px)": {
      paddingLeft: "30px",
      backgroundColor: "#FFFF !important",
      alignSelf: "center",
      paddingTop: "20px",
      paddingRight: "30px",
      paddingBottom: "0px",
    },
  },
  "& .infoView": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "35px",
    "@media only screen and (max-width: 900px)": {
      backgroundColor: "#FFFF !important",
    },
  },
  "& .rightView": {
    display: "flex",
    paddingBottom: "0px",
    paddingLeft: "72px",
    fontFamily: "Urbanist",
    flexDirection: "column",
    paddingTop: "56px",
    paddingRight: "112px",
    width: "60%",
    "@media only screen and (max-width: 900px)": {
      paddingLeft: "30px",
      backgroundColor: "#FFFF",
      paddingTop: "0px",
      paddingRight: "30px",
      paddingBottom: "0px",
      width: "85%",
    },
  },
  "& .loginBtnView": {
    justifyContent: "center",
    alignSelf: "center",
    padding: "10px 0px",
    fontFamily: "Urbanist",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media only screen and (max-width: 900px)": {
      fontSize: "12px",
    },
  },
  "& .reserved": {
    fontSize: "12px",
    fontFamily: "Urbanist",
    textAlign: "center",
  },
  "& .regVerticaliLine": {
    position: "absolute",
    top: "-20px",
    left: "0px",
    transform: "rotate(0deg)",
    width: "700",
    height: "1180px",
  },
  "& .handEmoji": {
    fontSize: "40px",
    fontFamily: "Urbanist",
    color: "#333",
    fontWeight: "bold",
    paddingLeft: "35px",
    "@media only screen and (max-width: 900px)": {
      display: "none !important",
    },
  },
  "& .PrivateSwitchBase-root-114": {
    paddingLeft: "0px important",
  },
  "& .Mui-checked": {
    color: "#019f48",
  },
  "& .MuiButton-contained": {
    fontSize: "22px",
    fontFamily: "Urbanist",
    color: "#fff",
  },
});
const TypograyView = styled(Typography)({
  "& .welcomeText": {
    fontSize: "40px",
    fontFamily: "Urbanist",
    color: "#333",
    fontWeight: "bold",
    paddingLeft: "20px",
    paddingRight: "30px",
    "@media only screen and (max-width: 900px)": {
      display: "none !important",
    },
  },
  "& .textStyle": {
    paddingTop: "20px",
    paddingBottom:'30px',
    fontSize: "24px",
    paddingLeft:'30px',
    fontFamily: "Urbanist",
    "@media only screen and (max-width: 900px)": {
      display: "none !important",
    },
  },
  "& .labelTitleStyle": {
    fontSize: "38px",
    fontWeight: 700,
    fontFamily: "Urbanist",
    color: "#333",
    paddingTop: "15px",
    "@media only screen and (max-width: 900px)": {
      display: "none !important",
    },
  },
  "& .labelTextStyle": {
    display: "none !important",
    "@media only screen and (max-width: 900px)": {
      display: "block !important",
      fontSize: "20px",
      fontFamily: "Urbanist",
      color: "#333",
      alignSelf: "center",
      textAlign: "center",
    },
  },

  "& .labelSubTitleStyle": {
    fontSize: "26px",
    fontFamily: "Urbanist",
    color: "#333",
    "@media only screen and (max-width: 900px)": {
      display: "none !important",
    },
  },
  "& .reserved": {
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Urbanist",
  },
  "& .labelSubTextStyle": {
    display: "none !important",
    "@media only screen and (max-width: 900px)": {
      display: "block !important",
      fontSize: "12px",
      fontFamily: "Urbanist",
      color: "#292D32",
      alignSelf: "center !important",
    },
  },
  "& .error": {
    color: "#F00",
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: 500,
    paddingTop: "10px",
  },
  "& .watchImage": {
    width: "309px",
    height: "309px",
  },
});

const InputLabelWrapper = styled(InputLabel)({
  '& .inputeLabelStyle': {
    fontSize: '18px',
    color: 'black',
    fontFamily: 'Urbanist',
    fontWeight: 500

  },
  '& .MuiButton-root': {
    textTransform: 'none',
    fontSize: '22px',
    color: '#959A9B',
    fontFamily: 'Urbanist',
  },
})

// Customizable Area End
