export const transUnionImg = require("../assets/ic_transUnion.png");
export const equifaxImg = require("../assets/ic_equifax.png");
export const experianImg = require("../assets/ic_experian.png");
export const dropdownImg = require("../assets/ic_drop_down.svg");
export const closeImg = require("../assets/ic_close.svg");
export const accountVerifiedImg = require("../assets/ic_account_verified.png");
export const backImg = require("../assets/back_img.svg");
export const calendarImg = require("../assets/calendar.svg");
export const checkedImg = require("../assets/checked_img.svg");
export const downArrowImg = require("../assets/down_arrow_img.svg");
export const editImg = require("../assets/edit_img.svg");
export const uncheckedImg = require("../assets/unchecked_img.svg");
export const lockImg = require("../assets/lock_img.svg");
export const deleteImg = require("../assets/disable_account_img.svg");
export const changePasswordMobileImg = require("../assets/change_password_mobile.svg");
export const activeIcon = require("../assets/active_icon.svg");
export const notActiveIcon = require("../assets/not_active_icon.svg");
export const emptyTasks = require("../assets/empty_tasks.png");

