Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.btnExampleTitle = "Submit";
exports.invoiceValidation ="Please enter 6 Digit Invoice Number"
exports.enterInvoice="Invoice Number"
exports.invoiceNumber="Enter Invoice Number (for example 299206)"
exports.keyboardType="phone-pad"
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_invoice/invoice/generate_invoice_pdf?invoice_number=";
exports.exampleAPiMethod = "GET";
exports.textInvoices = "Invoices";
exports.textGeneratedOn = "Generated on:";
exports.textAmount = "Amount";
exports.textDollar = "$";
exports.textForNoInvoice = "You don’t have any new Invoices";
exports.textDescriptionForNoInvoice = "You’ll see the invoices here if any added";
exports.textDownloadReport  = "Download Report";
exports.textHash = "#";
exports.textYear = "Year";
exports.textDash = "-";
exports.invoiceDataEndPoint = "bx_block_invoice/invoice";
// Customizable Area End