import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

export interface SubTermsAndConditions {
  id: number;
  term: string;
  condition: string;
}

type Point = {
  condition_points: string;
};

export interface SubTermsAndConditionsData {
  id: number;
  term: string;
  condition: string;
}

export interface TermsData {
  id: number;
  title: string;
  note: string;
  sub_title: string;
  description1: string;
  description2: string;
  actual_terms_and_conditions: SubTermsAndConditionsData[];
}

export interface SocialMediaLinkData {
  linkedin_link: string,
  fb_link: string,
  twitter_link: string,
  instagram_link: string
} 

export interface TermsAndConditionsData {
  id: number;
  title: string;
  sub_title: string;
  description1: string;
  description2: string;
  note: string;
  terms_and_conditions: SubTermsAndConditions[];
}

export interface TermsAndConditionsRes {
  data: {
    attributes: TermsData;
  }
}
interface ApiData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Object;
  type?: string;
}
export interface FooterDataRes {
  social_media_links: {
    "id": number,
    "linkedin_link": string,
    "fb_link": string,
    "twitter_link": string,
    "instagram_link": string
  }
}

export interface ContactUsData {
  location: string;
  phone: string;
  email: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  termsAndConditionsData: TermsAndConditionsData;
  socialMediaLinks: SocialMediaLinkData;
  contactUsData: ContactUsData;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  setAcceptanceOfTermsCondsId: string = "";
  getTermsAndConditionsCallId: string = "";
  getPrivacyPolicyCallId : string = "";
  getFooterDataCallId: string = "";
  getContactUsDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      termsAndConditionsData: {
        "id": 1,
        "title": "",
        "sub_title": "",
        "description1": "",
        "description2": "",
        "note": "",
        "terms_and_conditions": []
      },
      socialMediaLinks: {
        linkedin_link: "",
        fb_link: "",
        twitter_link: "",
        instagram_link: ""
      },
      contactUsData: {
        location: '',
        phone: '',
        email: '',
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body ,type} = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type != 'formData' ?
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    : requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  };
  async componentDidMount() {
    super.componentDidMount();
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
    if(window.location.pathname== '/PrivacyPage/web')
    {
      this.getPrivacyPolicy()
      this.getContactUsData();
      
    }else{
      this.getTermsAndConditions();
      this.getFooterData();
      this.getContactUsData();
    }
    
    if (this.props.navigation.getParam("navigationBarTitleText") !== "web") {
      this.getToken();
      if (!this.isPlatformWeb()) {
        this.props.navigation.addListener("willFocus", () => {
          this.getToken();
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(window.location.pathname== '/PrivacyPage/web' || 'TermsConditions/web'){
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
  }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  checkTermsAndConditionsCall = (responseJson: TermsAndConditionsRes, message: Message, apiRequestCallId: string) => {
    if(
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsAndConditionsCallId.length > 0 &&
      apiRequestCallId === this.getTermsAndConditionsCallId
    ) {
      this.getTermsAndConditionsCallId = "";
      const { id, title, sub_title, description1, description2, note, actual_terms_and_conditions } = responseJson.data.attributes;
      const termsConditionData = {
        id,
        title,
        sub_title,
        description1,
        description2,
        note,
        terms_and_conditions: actual_terms_and_conditions
      }

      this.setState({
        termsAndConditionsData: termsConditionData
      })
    }
  }

  checkPrivacyPolicyCall = (responseJson: TermsAndConditionsRes, message: Message, apiRequestCallId: string) => {
    if(
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPrivacyPolicyCallId.length > 0 &&
      apiRequestCallId === this.getPrivacyPolicyCallId
    ) {
      this.getPrivacyPolicyCallId = "";
      const { id, title, sub_title, description1, description2, note, actual_terms_and_conditions } = responseJson.data.attributes;

      this.setState({
        termsAndConditionsData: {
          id,
          title,
          sub_title,
          description1,
          description2,
          note,
          terms_and_conditions: actual_terms_and_conditions
        }
      })
    }
  }

  getFooterDataCall = (responseJson: FooterDataRes, message: Message, apiRequestCallId: string) => {
    if( 
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFooterDataCallId.length > 0 &&
      apiRequestCallId === this.getFooterDataCallId
    ) {
      this.getFooterDataCallId = "";
      const { linkedin_link, fb_link, twitter_link, instagram_link } = responseJson.social_media_links;

      this.setState({
        socialMediaLinks: {
          linkedin_link,
          fb_link,
          twitter_link,
          instagram_link
        }
      })
    }
  }

  getTermsAndConditions = async () => {
    this.getTermsAndConditionsCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getTermsAndConditionsEndpoint,
    });
  };
  getPrivacyPolicy = async () => {
    this.getPrivacyPolicyCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPrivacyPolApiEndPoint,
    });
  };

  getContactUsData= () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const contactUsDataRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactUsDataCallId = contactUsDataRequestMessage.messageId;
    contactUsDataRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsGetUrl
    );
    contactUsDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    contactUsDataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(contactUsDataRequestMessage.id, contactUsDataRequestMessage);
  }

  getFooterData = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFooterDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFooterDataEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  navigateToTermsCondsDetail = (termsCondsId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsDetail"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsId: termsCondsId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), null);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getAccountGroups = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getTermsCondsList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsConds = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckBoxChange = (value: boolean) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const body = {
      id: this.state.termsConds?.id,
      is_accepted: value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setAcceptanceOfTermsCondsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setTermsCondsAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    
    if (this.props.navigation.getParam("navigationBarTitleText") !== "web"){
      if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        const token: string = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        if (token) {
          runEngine.debugLog("TOKEN", token);
          const messageData = JSON.parse(
            message.getData(getName(MessageEnum.SessionResponseData))
          );
          const accountId: number = messageData?.meta?.id;
          this.setState({ accountId });

          this.setState({ token, accountId }, () => this.getAccountGroups(token));
        } else {
          this.props.navigation.goBack();
          return alert("Please Login");
        }
      }
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsCondsListCallId.length > 0 &&
      apiRequestCallId === this.getTermsCondsListCallId
    ) {
      this.getTermsCondsListCallId = "";
      this.setState({
        termsCondsList: responseJson.data,
        isLoading: false,
      });
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsCondsCallId.length > 0 &&
      apiRequestCallId === this.getTermsCondsCallId
    ) {
      this.getTermsCondsCallId = "";
      this.setState({
        termsConds: responseJson,
        isTermsCondsAccepted: responseJson.is_accepted,
        isLoading: false,
      });
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.setAcceptanceOfTermsCondsId.length > 0 &&
      apiRequestCallId === this.setAcceptanceOfTermsCondsId
    ) {
      this.setAcceptanceOfTermsCondsId = "";
      this.setState({
        isTermsCondsAccepted: !this.state.isTermsCondsAccepted,
      });
    }

    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAccountGroupsCallId.length > 0 &&
      apiRequestCallId === this.getAccountGroupsCallId
    ) {
      this.getAccountGroupsCallId = "";
      const isAdminUser = responseJson.data.some(
        (group: { attributes: { accounts: [] } }) =>
          group.attributes.accounts.some(
            (account: { id: number; role_id: number | null }) =>
              account.id === this.state.accountId && account.role_id === 1
          )
      );

      this.setState({ isAdminUser: isAdminUser });
      if (isAdminUser) {
        this.getTermsCondsList(this.state.token);
      } else {
        this.getTermsConds(this.state.token);
      }
    }

    this.checkTermsAndConditionsCall(responseJson, message, apiRequestCallId);
    this.checkPrivacyPolicyCall(responseJson, message, apiRequestCallId);

    this.getFooterDataCall(responseJson, message, apiRequestCallId);
    
    if(responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getContactUsDataCallId.length > 0 &&
      apiRequestCallId === this.getContactUsDataCallId &&
      responseJson.contact_us
    ) {
        const { address, phone_number, email} = responseJson.contact_us;
        this.setState({ contactUsData: { email, phone: phone_number, location: address } });
    }
    // Customizable Area End
  }
}
