export const userProfile = require("../assets/user-profile.png");
export const logoImg = require("../assets/credit_lift_logo.png");
export const notificationImg = require("../assets/notification.svg");
export const downArrowImg = require("../assets/down_arrow.svg");
export const logoutImg = require("../assets/logout.svg");
export const meetingsImg = require("../assets/meetings.svg");
export const profileImg = require("../assets/profile.svg");
export const logoutLargeImg = require("../assets/logout_large.svg");
export const drawerImg = require("../assets/drawer.svg");
export const backArrowImg = require("../assets/back_arrow.svg");
export const logoMobileImg = require("../assets/credit_score_logo_mobile.svg");
export const closeImg = require("../assets/close.svg");
export const healthImg = require("../assets/health.svg");
export const termsConditionsImg = require("../assets/terms_conditions_img.svg");
export const contactUsImg = require("../assets/contact_us_img.svg");
export const aboutUsImg = require("../assets/about_us_img.svg");
export const invoicesImg = require("../assets/invoices_img.svg");
export const logoutMobileImg = require("../assets/logout_mobile.svg");
export const profileMobileImg = require("../assets/profile_mobile.svg");
export const whiteDrawerImg = require("../assets/white_drawer.svg");
export const whiteNotificationImg = require("../assets/white_notification.svg");