Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Confirm Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.labelForSignup = "Enter your profile information to register";
exports.btnTextSignUp = "Sign Up";
exports.labelTitle = "Sign Up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.emailErrorPlaceholder = "Please enter valid email";
exports.smallCharacterRegex = /[a-z]/;
exports.capitalCharacterRegex= /[A-Z]/;
exports.numberSymbolRegex = /^(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])/;
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.phoneRegex = /^\d{10}$/;
exports.nameChangeRegex = /^[a-zA-Z\s\b]+$/;
exports.nameRegex = /^[a-zA-Z\s]{3,50}$/;
exports.termsConditionErrorText = "You must accept the terms and conditions to signup";
exports.passwordErrorHeading = "Password Must Include:";
exports.passwordCharacterText = "At least 8 characters";
exports.passwordCharacterSmall = "At least one small letter";
exports.passwordCapitalText = "At least one capital letter";
exports.passwordNumberSymbolText = "At least one number and symbol";
exports.phoneErrorMaximumText = "Phone number should have a minimum of [10] digits";
exports.phoneErrorNotExceedText = "Phone number should not exceed [10] digits";
exports.passwordNotMatch = "Password does not match";
exports.passwordErrorText = "Password is required";
exports.validPasswordErrorText = "Please enter valid password";
exports.minCharacterNameErrorText = "Name should have atleast 3 characters";
exports.maxCharacterNameErrorText = "Name should not exceed 50 characters";
exports.emailExistErrorText = "Email already exist"

// Customizable Area End
